import { IconButton, Stack } from "@mui/material";
import { Handle, Position, useReactFlow } from "reactflow";
import { actions } from "../../library/actionEditor";
import { Delete } from "@mui/icons-material";
import NGPropertiesEditor from "../../generators/NGPropertiesEditor";
import { useSignal } from "@preact/signals-react";
import ActionDialog from "./ActionDialog";
import NGMultiSelect from "../NGMultiSelect/NGMultiSelect";

const ActionNode = ({ id, data }: any) => {
  const confirmDeleteDialogOpen = useSignal(false);
  const { deleteElements, getNodes } = useReactFlow();
  const { context, onDeleteActionNode, currentTriggerIndex, onSave } = data;

  const nodes = getNodes();
  const index = nodes.findIndex((node) => node.id === id) - 1;

  if (id === "startNode") {
    return (
      <div>
        Start
        <Handle
          type="source"
          position={Position.Bottom}
          id="startHandle"
          style={{ left: 30, background: "#6495ed", width: "8px", height: "8px" }}
        />
      </div>
    );
  }

  const handleDelete = () => {
    confirmDeleteDialogOpen.value = true;
  };

  const onConfirmDelete = () => {
    onDeleteActionNode(id, nodes);

    confirmDeleteDialogOpen.value = false;
  };

  const actionOptions = actions?.map((action) => ({ label: action })) ?? [];
  const actionSelected = actionOptions.find((action) => action.label === data?.action?.Name) ?? actionOptions[0];
  const propEditorActions = [
    {
      Trigger: "onSave",
      postHandler: onSave,
      ListenTo: ["onChange", "onRowUpdate"],
      CommandSet: {
        FirstCommandId: "1",
        ExecuteCommandsInParallel: false,
        Commands: [
          {
            Id: "1",
            SkipCommand: "!Form",
            Instruction: {
              Name: "SetState",
            },
            NextCommandIdOnSuccess: "2",
            Parameters: [
              {
                Name: "Bindings",
                Value: {
                  [`State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Parameters`]:
                    "getFieldsWithBindingsFromForm(Form)",
                },
              },
              {
                Name: "Merge",
                Value: false,
              },
            ],
          },
        ],
      },
    },
  ];

  return (
    <div>
      <Handle type="target" position={Position.Left} style={{ left: -7, width: "12px", height: "12px" }} />
      <Stack direction="column" gap="0.5rem">
        <Stack direction="row">
          <NGMultiSelect
            context={context}
            config={{
              Bindings: {
                DefaultValue: `State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Instruction.Name`,
              },
              DefaultValue: actionSelected.label,
              Id: `${id}-action-node-selector`,
              MultiSelectPossibleValues: actionOptions.map((action) => action.label),
              Style: {
                flex: "1",
                "& .MuiFormControl-root": {
                  margin: 0,
                },
                "& .MuiInputBase-root": {
                  padding: "1px 0px 1px 10px",
                },
              },
              DisableClearable: true,
              Actions: [
                {
                  Trigger: "onChange",
                  postHandler: onSave,
                  CommandSet: {
                    FirstCommandId: "0",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "0",
                        Instruction: {
                          Name: "SetState",
                        },
                        Parameters: [
                          {
                            Name: "Bindings",
                            Value: {
                              [`State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Instruction`]:
                                "{'Name': Event}",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            }}
          />
          <IconButton
            color="inherit"
            size="small"
            onClick={handleDelete}
            sx={{ width: "40px", maxWidth: "40px", height: "40px" }}
          >
            <Delete />
          </IconButton>
        </Stack>
        <NGPropertiesEditor
          context={context}
          config={{
            __typename: data?.action.Name,
            Id: `${id}-actionPropEditor`,
            UniqueName: `${id}-actionPropEditor`,
            FormId: `${id}-actionPropEditor`,
            Bindings: {
              Data: `merge(fieldsToObject(State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Parameters), {__typename: State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Instruction.Name})`,
              __typename: `State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Instruction.Name`,
            },
            ShowBindingField: true,
            Actions: propEditorActions,
          }}
        />
      </Stack>
      <Handle
        type="source"
        position={Position.Right}
        id="successHandle"
        style={{ right: -7, background: "lightseagreen", width: "12px", height: "12px" }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="failureHandle"
        style={{ left: 150, bottom: -7, background: "#ee2727", width: "12px", height: "12px" }}
      />
      <ActionDialog
        open={confirmDeleteDialogOpen}
        onCancel={() => {
          confirmDeleteDialogOpen.value = false;
        }}
        onConfirm={onConfirmDelete}
        title="Confirm delete action"
      />
    </div>
  );
};

export default ActionNode;
