import { List as MUIList, ListItem as MUIListItem, ListItemText as MUIListItemText } from "@mui/material";
import { signal, useSignal } from "@preact/signals-react";
import { useEffect, useRef } from "react";
import { INGRepeaterProps } from "../../library/NGFieldExtensions";
import { setupLocalState } from "../../library/dataService";
import NGIcon from "../NGIcon/NGIcon";
import { getIconNameFromCategory } from "../../library/utils";

export default function NGSimpleRepeater({ config, context }: INGRepeaterProps) {
  const listEndRef = useRef(null);

  const local = setupLocalState(
    config,
    {
      Rows: useSignal(config.Rows ?? []),
    },
    context
  );

  useEffect(() => {
    if (listEndRef.current) {
      (listEndRef.current as any).scrollIntoView({ behavior: "smooth" });
    }
  }, [local.Rows.value]);

  return (
    <MUIList dense={true} sx={{ maxWidth: "95%" }}>
      {local.Rows.value?.map((r, i) => (
        <MUIListItem
          key={i}
          sx={{
            backgroundColor: "#404754",
            borderRadius: "5px",
            padding: "3px",
            margin: "5px",
          }}
        >
          <NGIcon config={{ IconName: getIconNameFromCategory(r.Category) }} context={context} />
          <MUIListItemText primary={r.Message} sx={{ marginLeft: "5px" }}></MUIListItemText>
          <div ref={listEndRef}></div>
        </MUIListItem>
      ))}
    </MUIList>
  );
}
