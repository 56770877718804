import * as graphql from "../../../resolvers-types";

export const formInGridStack: graphql.Form = {
  Typename: "Form",
  __typename: "Form",
  UniqueName: "formInGridStack",
  Id: "0xa301",
  Items: [
    {
      __typename: "Label",
      Id: "0xa3011",
      Sequence: 1,
      DefaultValue: "Number of Clients",
    },
    {
      __typename: "Label",
      Id: "0xa3012",
      Sequence: 1,
      DefaultValue: "48",
    },
    {
      __typename: "Label",
      Id: "0xa3013",
      Sequence: 1,
      DefaultValue: "Prospect Value",
    },
    {
      __typename: "Label",
      Id: "0xa3014",
      Sequence: 1,
      DefaultValue: "$480,254",
    },
  ],
  Layout: {
    __typename: "GridStackLayout",
    Id: "0xc0001",
    LayoutItem: {
      __typename: "Form",
      Id: "0xa301",
    } as graphql.Form,
    InnerLayouts:
      //[{"X":0,"Y":0,"W":3,"H":1,"LayoutItem":{"Id":"0xa3011"}},{"X":3,"Y":0,"W":3,"H":1,"LayoutItem":{"Id":"0xa3013"}},{"X":0,"Y":1,"W":3,"H":1,"LayoutItem":{"Id":"0xa3012"}},{"X":3,"Y":1,"W":2,"H":1,"LayoutItem":{"Id":"0xa3014"}}]
      [
        { X: 0, Y: 0, W: 6, H: 20, LayoutItem: { Id: "0xa3011" } },
        { X: 0, Y: 21, W: 3, H: 20, LayoutItem: { Id: "0xa3012" } },
        { X: 7, Y: 0, W: 6, H: 20, LayoutItem: { Id: "0xa3013" } },
        { X: 7, Y: 21, W: 3, H: 20, LayoutItem: { Id: "0xa3014" } },
      ],
  },
};

export const gridStackPage3: graphql.Page = {
  __typename: "Page",
  Id: "0xa5",
  Name: "My CRM Dashboard",
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "0xa300",
      UniqueName: "KPI",
      Items: [formInGridStack],
    },
  ],
  Layout: {
    __typename: "LinearLayout",
    Id: "0xc0001",
    LayoutItem: {
      __typename: "Page",
      Id: "0xa5",
      Name: "My CRM Dashboard",
    },
  },
};
