import { FlexLayout, FreeFormLayout, GridStackLayout, TabLayout, TableLayout } from "../../resolvers-types";
import { AccordionLayout } from "../gql/graphql";
import { INGLayoutProps } from "../library/NGFieldExtensions";
import { updateItemContext } from "../library/dataService";
import NGAccordionLayout from "./NGAccordionLayout";
import NGFlexLayout from "./NGFlexLayout";
import NGFreeFormLayout from "./NGFreeFormLayout";
import NGGridStackLayout from "./NGGridStackLayout";
import NGTabLayout from "./NGTabLayout";
import NGTableLayout from "./NGTableLayout";

export default function NGLayout({ layout, layoutItem, inDesignMode, context }: INGLayoutProps) {
  const currentContext = context; // updateItemContext(context, layout);

  switch (layout?.__typename) {
    case "GridStackLayout": {
      const ll: GridStackLayout = layout as GridStackLayout;
      return (
        <NGGridStackLayout
          key={ll.Id}
          config={layoutItem}
          gridStackLayout={ll}
          inDesignMode={inDesignMode}
          context={currentContext}
        />
      );
    }

    case "TableLayout": {
      const tl: TableLayout = layout as TableLayout;
      return <NGTableLayout key={tl.Id} config={layoutItem} tableLayout={tl} context={currentContext} />;
    }
    case "TabLayout": {
      const tl: TabLayout = layout as TabLayout;
      return <NGTabLayout layoutItem={layoutItem} tabLayout={tl} context={currentContext} />;
    }
    case "AccordionLayout": {
      return <NGAccordionLayout layoutItem={layoutItem} context={currentContext} accordionLayout={layout} />;
    }

    case "FreeFormLayout": {
      const ff: FreeFormLayout = layout as FreeFormLayout;

      return (
        <NGFreeFormLayout
          key={ff.Id}
          config={ff}
          layoutItem={layoutItem}
          inDesignMode={inDesignMode}
          context={currentContext}
        />
      );
    }

    case "LinearLayout":
    case "FlexLayout":
    default: {
      const fl: FlexLayout = layout as FlexLayout;
      return (
        <NGFlexLayout
          key={fl.Id}
          config={fl}
          layoutItem={layoutItem}
          inDesignMode={inDesignMode}
          context={currentContext}
        />
      );
    }
  }
}
