import { useSignal, useSignalEffect } from "@preact/signals-react";
import {
  FormControl,
  Radio,
  RadioGroup,
  Box,
  Stack,
  Typography,
  FormControlLabel,
  IconButton,
  Menu,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  Height as HeightIcon,
  AlignVerticalTop,
  AlignVerticalCenter,
  AlignVerticalBottom,
  TextFormat,
  FormatLineSpacing,
  AlignHorizontalRight,
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  Balance,
  Expand,
  HorizontalRule,
  Circle,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  WrapText,
  Tune,
} from "@mui/icons-material";
import NGStyleInput from "./NGStyleInput";

export default function NGLayoutEditor({ selected }) {
  const getBoxPosition = () => {
    const { flexDirection, justifyContent, alignItems } = selected.value;

    let row = "";
    let column = "";

    switch (alignItems) {
      case "flex-start":
        if (flexDirection === "row") row = "top";
        else column = "left";
        break;
      case "center":
        if (flexDirection === "row") row = "middle";
        else column = "center";
        break;
      case "flex-end":
        if (flexDirection === "row") row = "bottom";
        else column = "right";
        break;
    }
    switch (justifyContent) {
      case "flex-start":
        if (flexDirection === "row") column = "left";
        else row = "top";
        break;
      case "center":
        if (flexDirection === "row") column = "center";
        else row = "middle";
        break;
      case "flex-end":
        if (flexDirection === "row") column = "right";
        else row = "bottom";
        break;
    }

    return row && column ? `${row}-${column}` : "";
  };

  const autoGapMode = useSignal(false);
  const currentHoveredBox = useSignal("");
  const positionBoxSelected = useSignal(getBoxPosition());
  const selectedDirection = useSignal(getDirection());
  const customGridMenu = useSignal<HTMLElement | null>(null);
  const customGridMenuOpen = Boolean(customGridMenu.value);

  useSignalEffect(() => {
    positionBoxSelected.value = getBoxPosition();
  });

  useSignalEffect(() => {
    selectedDirection.value = getDirection();
  });

  const handlePropertyChange = (e, value, changeDirection?) => {
    selected.value = { ...selected.value, ...value };

    if (changeDirection) {
      handleBoxPositionChange(e, positionBoxSelected.value, selected.value);
    } else {
      positionBoxSelected.value = getBoxPosition();
    }
  };

  const handleBoxPositionChange = (e, box, localValue?) => {
    const direction = selectedDirection.value;
    const boxRow = box?.split("-")[0];
    const boxCol = box?.split("-")[1];

    let alignItems = "";
    let justifyContent = "";
    let alignContent = "";

    switch (boxRow) {
      case "top":
        if (direction === "row") alignItems = "flex-start";
        else if (direction === "wrap") {
          alignItems = "flex-start";
          alignContent = "flex-start";
        } else justifyContent = "flex-start";
        break;
      case "middle":
        if (direction === "row") alignItems = "center";
        else if (direction === "wrap") {
          alignItems = "center";
          alignContent = "center";
        } else justifyContent = "center";
        break;
      case "bottom":
        if (direction === "row") alignItems = "flex-end";
        else if (direction === "wrap") {
          alignItems = "flex-end";
          alignContent = "flex-end";
        } else justifyContent = "flex-end";
        break;
    }
    switch (boxCol) {
      case "left":
        if (direction === "row" || direction === "wrap") justifyContent = "flex-start";
        else alignItems = "flex-start";
        break;
      case "center":
        if (direction === "row" || direction === "wrap") justifyContent = "center";
        else alignItems = "center";
        break;
      case "right":
        if (direction === "row" || direction === "wrap") justifyContent = "flex-end";
        else alignItems = "flex-end";
        break;
    }

    if (autoGapMode.value) justifyContent = "space-between";

    selected.value = {
      ...(localValue ?? selected.value),
      alignItems,
      alignContent,
      justifyContent,
    };
  };

  const handleDoubleClick = (e) => {
    autoGapMode.value = !autoGapMode.value;
    handleBoxPositionChange(e, e.target.value);
  };

  const onMouseEnterBox = (boxValue: string) => {
    currentHoveredBox.value = boxValue;
  };

  const getNoAutoGapsIcons = (box: string) => {
    if (selectedDirection.value === "row") {
      switch (box) {
        case "top-left":
        case "top-center":
        case "top-right":
          return <FormatAlignLeft className="icon-rotate-90" />;
        case "middle-left":
        case "middle-center":
        case "middle-right":
          return <FormatAlignCenter className="icon-rotate-90" />;
        case "bottom-left":
        case "bottom-center":
        case "bottom-right":
          return <FormatAlignRight className="icon-rotate-90" />;
      }
    } else {
      //Direction column
      switch (box) {
        case "top-right":
        case "middle-right":
        case "bottom-right":
          return <FormatAlignRight />;
        case "top-center":
        case "middle-center":
        case "bottom-center":
          return <FormatAlignCenter />;
        case "top-left":
        case "middle-left":
        case "bottom-left":
          return <FormatAlignLeft />;
      }
    }
  };

  const getBoxIcon = (box: string, selected: boolean) => {
    const direction = selectedDirection.value;
    const [boxRow, boxColumn] = box?.split("-") ?? [];
    const [selectedBoxRow, selectedBoxColumn] = positionBoxSelected.value?.split("-") ?? [];
    const [hoveredBoxRow, hoveredBoxColumn] = currentHoveredBox.value?.split("-") ?? [];

    const VerticalIcon = () => (
      <HorizontalRule
        className="icon-rotate-90"
        style={{
          scale: boxColumn === "center" ? "1.6" : "1",
          marginTop: boxColumn === "center" && boxRow === "top" ? "4px" : "",
          marginBottom: boxColumn === "center" && boxRow === "bottom" ? "4px" : "",
        }}
      />
    );

    const HorizontalIcon = () => (
      <HorizontalRule
        style={{
          scale: boxRow === "middle" ? "1.6" : "1",
          marginLeft: boxRow === "middle" && boxColumn === "left" ? "4px" : "",
          marginRight: boxRow === "middle" && boxColumn === "right" ? "4px" : "",
        }}
      />
    );

    if (!selected) {
      //Icon not selected
      if (!autoGapMode.value)
        if (currentHoveredBox.value === box) return getNoAutoGapsIcons(box);
        else return <Circle className="icon-scaled-down" />;
      //No auto gap => bullet
      else {
        if ((direction === "row" || direction === "wrap") && (selectedBoxRow === boxRow || hoveredBoxRow === boxRow))
          return <VerticalIcon />;
        else if (direction === "column" && (selectedBoxColumn === boxColumn || hoveredBoxColumn === boxColumn))
          return <HorizontalIcon />;
        else return <Circle className="icon-scaled-down" />;
      }
    } else {
      //Selected case
      if (!autoGapMode.value) {
        //Icon selected with not auto gap
        return getNoAutoGapsIcons(box);
      } else {
        //Icon selected with auto gap
        if (direction === "column") return <HorizontalIcon />;
        else return <VerticalIcon />;
      }
    }
  };

  const getBoxStyle = (box) => {
    const direction = selectedDirection.value;
    const boxRow = box.split("-")[0];
    const boxCol = box.split("-")[1];
    const selectedBoxRow = positionBoxSelected.value?.split("-")[0] ?? [];
    const hoveredBoxRow = currentHoveredBox.value?.split("-")[0] ?? [];
    const selectedBoxColumn = positionBoxSelected.value?.split("-")[1] ?? [];
    const hoveredBoxColumn = currentHoveredBox.value?.split("-")[1] ?? [];

    const showHoverBackground =
      autoGapMode.value &&
      ((direction === "column" && hoveredBoxColumn === boxCol) ||
        ((direction === "row" || direction === "wrap") && hoveredBoxRow === boxRow));

    const showSelected =
      autoGapMode.value &&
      ((direction === "column" && selectedBoxColumn === boxCol) ||
        ((direction === "row" || direction === "wrap") && selectedBoxRow === boxRow));

    return {
      padding: 0,
      height: "36px",
      width: "36px",
      color: showSelected ? "#1976d2" : undefined,
      backgroundColor: showHoverBackground ? "rgba(25, 118, 210, 0.04)" : showSelected ? "#eee" : undefined,
      justifyContent:
        autoGapMode.value &&
        direction === "column" &&
        boxCol !== "center" &&
        (selectedBoxColumn === boxCol || hoveredBoxColumn === boxCol)
          ? boxCol === "left"
            ? "left"
            : "right"
          : "",
      alignItems:
        autoGapMode.value &&
        (direction === "row" || direction === "wrap") &&
        boxRow !== "middle" &&
        (selectedBoxRow === boxRow || hoveredBoxRow === boxRow)
          ? boxRow === "top"
            ? "flex-start"
            : "flex-end"
          : "",
    };
  };

  const handleOpenCustomGrid = (event) => {
    customGridMenu.value = customGridMenu.value ? null : event.currentTarget;
  };

  const {
    display,
    justifyContent,
    alignItems,
    flexDirection: Direction,
    gridTemplateColumns,
    gridTemplateRows,
  } = selected.value;

  const boxes = [
    "top-left",
    "top-center",
    "top-right",
    "middle-left",
    "middle-center",
    "middle-right",
    "bottom-left",
    "bottom-center",
    "bottom-right",
  ];

  const handleCloseCustomGridMenu = () => {
    customGridMenu.value = null;
  };

  const getRotatedIcon = (rotation: string, direction = "column") =>
    Direction === direction ? `icon-rotate-${rotation}` : "";

  const numberToAutoString = (num) => {
    return num <= 0 ? "" : "auto ".repeat(num).trim();
  };

  const handleCustomGridChange = (property, value: string) => {
    if (!value) {
      handlePropertyChange(null, { [property]: "" });
      return;
    }

    const quantity = parseInt(value);

    const currentValues = (selected.value[property] ?? "").split(" ") as Array<string>;
    const currentQuantityValues = selected.value[property] ? currentValues.length : 0;
    if (!currentQuantityValues) {
      handlePropertyChange(null, { [property]: numberToAutoString(value) });
      return;
    }

    if (quantity < currentValues.length) {
      const newValues = currentValues.slice(0, quantity);
      handlePropertyChange(null, { [property]: newValues.join(" ") });
    } else {
      for (let i = 0; i < quantity - currentValues.length; i++) {
        currentValues.push("auto");
      }
      handlePropertyChange(null, { [property]: currentValues.join(" ") });
    }
  };

  const handleCustomColumnChange = (index: number, value: string) => {
    handleColumnChange("gridTemplateColumns", index, value);
  };

  const handleCustomRowChange = (index: number, value: string) => {
    handleColumnChange("gridTemplateRows", index, value);
  };

  const handleColumnChange = (property, index, newValue) => {
    const values = selected.value[property];
    if (index < 0 || !values.trim()) return;

    const customValuesSplit = values.split(" ");
    if (index >= customValuesSplit.length) return;

    customValuesSplit[index] = newValue;
    handlePropertyChange(null, { [property]: customValuesSplit.join(" ") });
  };

  return (
    <Stack gap="1rem" className="layout-editor-position-container">
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <InputLabel id="layout-multi-select">Layout</InputLabel>
        <Select
          labelId="layout-multi-select"
          id="layout-multi-select"
          value={chosenLayout}
          onChange={(e) => handleLayoutPropertyChange(e, "__typename")}
          sx={{ width: "70%", maxHeight: "32px" }}
          size="small"
        >
          <MenuItem value={"FlexLayout"}>Flex Layout</MenuItem>
          <MenuItem value={"FreeFormLayout"}>Free Form Layout</MenuItem>
        </Select>
      </Box> */}
      <Stack direction="row">
        <RadioGroup row name="display-radio-group" value={selectedDirection.value} className="display-type-radio-group">
          <FormControlLabel
            value="flex"
            label="Flex"
            data-testid="display-flex-option"
            control={<Radio sx={{ display: "none" }} />}
            className={display === "flex" ? "display-type-selected" : ""}
            onChange={(e) => {
              handlePropertyChange(e, { display: "flex" }, true);
            }}
          />
          <FormControlLabel
            value="grid"
            label="Grid"
            data-testid="display-grid-option"
            control={<Radio sx={{ display: "none" }} />}
            className={display === "grid" ? "display-type-selected" : ""}
            onChange={(e) => {
              handlePropertyChange(e, { display: "grid" });
            }}
          />
        </RadioGroup>
      </Stack>
      {display === "grid" && (
        <Stack direction="row" gap="1rem">
          <NGStyleInput
            label="Columns"
            name="layout-editor-grid-columns-input"
            onChange={(e) => handleCustomGridChange("gridTemplateColumns", e.target.value)}
            value={gridTemplateColumns?.split(" ").length ?? ""}
            type="number"
          />
          <NGStyleInput
            label="Rows"
            name="layout-editor-grid-rows-input"
            onChange={(e) => handleCustomGridChange("gridTemplateRows", e.target.value)}
            value={gridTemplateRows?.split(" ").length ?? ""}
            type="number"
          />
          <IconButton
            style={{
              minWidth: "50px",
              maxWidth: "50px",
              width: "50px",
              height: "40px",
              alignSelf: "end",
              border: "none",
            }}
            title="add-border"
            onClick={handleOpenCustomGrid}
            className="menu-button-prop-editor"
          >
            <Tune />
          </IconButton>
          <Menu
            id="custom-grid-menu"
            data-testid="custom-grid-menu"
            className="custom-grid-menu"
            anchorEl={customGridMenu.value}
            open={customGridMenuOpen}
            onClose={handleCloseCustomGridMenu}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  padding: "20px",
                  height: "50px",
                  backgroundColor: "#F5F5F7",
                  borderTop: "0.75px solid var(--divider, rgba(0, 0, 0, 0.12))",
                  borderBottom: "0.75px solid var(--divider, rgba(0, 0, 0, 0.12))",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Grid Settings
              </Typography>
              <Stack direction="column" gap="1rem" padding="20px">
                <Stack direction="column" gap="0.8rem">
                  <Typography>Columns</Typography>
                  <Stack direction="column" gap="0.5rem">
                    {gridTemplateColumns ? (
                      gridTemplateColumns.split(" ").map((column, index) => (
                        <Stack key={index} direction="row" alignItems="center" gap="0.8rem" className="icon-rotate-90">
                          <Expand />
                          <TextField
                            data-testid={`custom-column-input-${index}`}
                            className="prop-editor-input"
                            sx={{
                              "& .MuiInputBase-root": {
                                height: "40px",
                              },
                              width: "100%",
                            }}
                            onChange={(e) => handleCustomColumnChange(index, e.target.value)}
                            value={column ?? ""}
                          />
                        </Stack>
                      ))
                    ) : (
                      <Typography>No columns to customize</Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="column" gap="0.8rem">
                  <Typography>Rows</Typography>
                  <Stack direction="column" gap="0.5rem">
                    {gridTemplateRows ? (
                      gridTemplateRows.split(" ").map((row, index) => (
                        <Stack key={index} direction="row" alignItems="center" gap="0.8rem">
                          <Expand />
                          <TextField
                            data-testid={`custom-row-input-${index}`}
                            className="prop-editor-input"
                            sx={{
                              "& .MuiInputBase-root": {
                                height: "40px",
                              },
                              width: "100%",
                            }}
                            onChange={(e) => handleCustomRowChange(index, e.target.value)}
                            value={row ?? ""}
                          />
                        </Stack>
                      ))
                    ) : (
                      <Typography>No rows to customize</Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Menu>
        </Stack>
      )}
      {display === "flex" && (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <FormControl
            component="fieldset"
            sx={{ flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "flex-end" }}
          >
            <Box>
              <Stack direction="row" alignItems="center">
                <Typography className="control-label" sx={{ marginBottom: "1rem" }}>
                  Layout
                </Typography>
              </Stack>
              <Typography className="control-label">Direction</Typography>
              <RadioGroup row name="direction" value={selectedDirection.value} className="layout-editor-radio-button">
                <Radio
                  icon={<HeightIcon className="icon-rotate-90" />}
                  checkedIcon={<HeightIcon className="icon-rotate-90" />}
                  value="row"
                  onChange={(e) => {
                    handlePropertyChange(e, { flexDirection: "row", flexWrap: undefined }, true);
                    selectedDirection.value = "row";
                  }}
                  className="layout-editor-radio-child"
                  sx={{
                    "&.MuiRadio-root.Mui-checked": {
                      color: "rgba(50, 50, 50, 1)",
                    },
                  }}
                />
                <Radio
                  icon={<HeightIcon />}
                  checkedIcon={<HeightIcon />}
                  value="column"
                  onChange={(e) => {
                    handlePropertyChange(e, { flexDirection: "column", flexWrap: undefined }, true);
                    selectedDirection.value = "column";
                  }}
                  className="layout-editor-radio-child"
                  sx={{
                    "&.MuiRadio-root.Mui-checked": {
                      color: "rgba(50, 50, 50, 1)",
                    },
                  }}
                />
                <Radio
                  icon={<WrapText />}
                  checkedIcon={<WrapText />}
                  value="wrap"
                  onChange={(e) => {
                    handlePropertyChange(e, { flexWrap: "wrap", flexDirection: "row" }, true);
                    selectedDirection.value = "wrap";
                  }}
                  className="layout-editor-radio-child"
                  sx={{
                    "&.MuiRadio-root.Mui-checked": {
                      color: "rgba(50, 50, 50, 1)",
                    },
                  }}
                />
              </RadioGroup>
            </Box>

            <RadioGroup
              row
              name="align"
              value={positionBoxSelected}
              className="layout-editor-radio-button position-box"
              onChange={(e) => (positionBoxSelected.value = e.target.value)}
              onMouseLeave={() => onMouseEnterBox("")}
              onDoubleClick={handleDoubleClick}
            >
              <Box
                sx={{
                  display: "grid",
                  flexDirection: "row",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  width: "110px",
                  height: "110px",
                }}
              >
                {boxes.map((box) => (
                  <Radio
                    key={box}
                    value={box}
                    icon={getBoxIcon(box, false)}
                    checkedIcon={getBoxIcon(box, true)}
                    onMouseEnter={(e) => onMouseEnterBox(e.target.value)}
                    onChange={(e) => handleBoxPositionChange(e, e.target.value)}
                    style={getBoxStyle(box)}
                  />
                ))}
              </Box>
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset">
            <Typography className="control-label">Justify Content</Typography>
            <RadioGroup
              row
              name="justify"
              value={justifyContent ?? ""}
              onChange={(e) => handlePropertyChange(e, { justifyContent: e.target.value })}
              className="layout-editor-radio-button"
            >
              <Radio
                value="flex-start"
                className={`${getRotatedIcon("90")} layout-editor-radio-child`}
                icon={<AlignHorizontalLeft />}
                checkedIcon={<AlignHorizontalLeft />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="center"
                className={`${getRotatedIcon("270")} layout-editor-radio-child`}
                icon={<AlignHorizontalCenter />}
                checkedIcon={<AlignHorizontalCenter />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="flex-end"
                className={`${getRotatedIcon("90")} layout-editor-radio-child`}
                icon={<AlignHorizontalRight />}
                checkedIcon={<AlignHorizontalRight />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="space-between"
                className={`${getRotatedIcon("90", "row")} layout-editor-radio-child`}
                icon={<HeightIcon />}
                checkedIcon={<HeightIcon />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="space-around"
                className={`${getRotatedIcon("90", "row")} layout-editor-radio-child`}
                icon={<Expand />}
                checkedIcon={<Expand />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                className={`layout-editor-radio-child`}
                value="space-evenly"
                icon={<Balance />}
                checkedIcon={<Balance />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <Typography className="control-label">Align Items</Typography>
            <RadioGroup
              row
              name="align"
              value={alignItems ?? ""}
              onChange={(e) => handlePropertyChange(e, { alignItems: e.target.value })}
              className="layout-editor-radio-button"
              sx={{
                "&.MuiRadio-root.Mui-checked": {
                  color: "rgba(50, 50, 50, 1)",
                },
              }}
            >
              <Radio
                value="flex-start"
                className={`${getRotatedIcon("270")} layout-editor-radio-child`}
                icon={<AlignVerticalTop />}
                checkedIcon={<AlignVerticalTop />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="center"
                className={`${getRotatedIcon("90")} layout-editor-radio-child`}
                icon={<AlignVerticalCenter />}
                checkedIcon={<AlignVerticalCenter />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="flex-end"
                className={`${getRotatedIcon("270")} layout-editor-radio-child`}
                icon={<AlignVerticalBottom />}
                checkedIcon={<AlignVerticalBottom />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="stretch"
                className={`${getRotatedIcon("90")} layout-editor-radio-child`}
                icon={<FormatLineSpacing />}
                checkedIcon={<FormatLineSpacing />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
              <Radio
                value="baseline"
                icon={<TextFormat />}
                checkedIcon={<TextFormat />}
                sx={{
                  "&.MuiRadio-root.Mui-checked": {
                    color: "rgba(50, 50, 50, 1)",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </Stack>
  );

  function getDirection(): any {
    return selected.value?.["flexDirection"] ?? selected.value?.["flexWrap"] ?? "";
  }
}
