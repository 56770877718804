import { INGAiDialogProps } from "../../library/NGFieldExtensions";
import NGChatBox from "./NGChatBox";
import { useSignal } from "@preact/signals-react";
import { setupLocalState } from "../../library/dataService";
import { Box, LinearProgress } from "@mui/material";
import NGSimpleRepeater from "../NGRepeater/NGSimpleRepeater";

export default function NGAIDialog({ config, context }: INGAiDialogProps) {
  const chatHistoryMessages = useSignal([]);
  const local = setupLocalState(
    config,
    {
      Loading: useSignal(false),
    },
    context
  );

  // const local = setupLocalState(config, {
  //   Columns: useSignal(getColumns(config.ListColumns)),
  //   Rows: useSignal([]),
  //   Loading: useSignal(false),
  //   EditMode: useSignal(config.EditMode),
  //   RowModesModel: useSignal<GridRowModesModel>({} as GridRowModesModel),
  // });

  // TODO: deal with undefined case where the action is not defined
  const onFileUploadAction = config.Actions.find((action) => action.Trigger === "onFileUpload");

  //   <NGChatHistory
  //   config={{ Messages: ["testing", "messages", "from chatbox"] }}
  // />
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderColor: "grey",
          backgroundColor: "#142647",
          height: "150px",
          overflowY: "auto",
          borderRadius: "5px",
          fontSize: "12px",
        }}
      >
        <NGSimpleRepeater
          config={{
            Id: "12345",
            Bindings: {
              Rows: "State.NGService.AnalyzeFile.Progress",
            },
          }}
          context={context}
        />
      </Box>
      {local.Loading.value && <LinearProgress sx={{ marginTop: "20px", marginBottom: "20px" }} />}
      {!local.Loading.value && (
        <NGChatBox
          chatHistoryMessages={chatHistoryMessages}
          config={{
            ...config,
            // TODO: OnPrompt: handlers.onPrompt
            Placeholder: config.Placeholder,
            InputBoxHeight: config.InputBoxHeight,
            OnFileUpload: onFileUploadAction,
          }}
          context={context}
        />
      )}
    </>
  );
}
