export const categoryIcons = {
  DocumentAdded: "NoteAdd",
  DataModel: "AccountTreeOutlined",
  TaskComplete: "Task",
  Database: "Storage",
  CloudUpload: "CloudUpload",
  DataExtract: "Insights",
  DataExtractComplete: "TrendingUp",
  Complete: "CheckCircle",
  Image: "Image",
  Video: "VideoLibrary",
  Word: "Description",
  Excel: "TableChart",
  Powerpoint: "Slideshow",
  Pdf: "PictureAsPdf",
  Text: "TextSnippet",
  Audio: "LibraryMusic",
  Archive: "Archive",
  File: "InsertDriveFileIcon",
};
