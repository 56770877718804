import { INGGridStackLayoutProps } from "../library/NGFieldExtensions";
import { Form, GridStackLayout, LayoutItem, Maybe } from "../../resolvers-types";
import { createContext, useEffect } from "react";
import "/node_modules/gridstack/dist/gridstack.css";
import "./NGGridStackLayout.css";
import { batch, useSignal } from "@preact/signals-react";
import { cloneDeep, isNil } from "lodash-es";
import { setupHandlers } from "../library/dataService";
import { generateUID, getClassName, getVisibleProp } from "../library/utils";
import { pixelsToGridUnits } from "./NGGridStackUtils";
import { GridStackManaged } from "./NGGridStackManaged";
import { Box } from "@mui/material";
import NGLayoutItem from "../generators/NGLayoutItem";

export const GridStackContext = createContext({ InDesignMode: false });

// TODO: can we use the schema to generate this?
function determineComponentType(item) {
  if (item.props.control) {
    return "Items";
  } else if (item.props.application) {
    return "Applications";
  } else if (item.props.simpleContainer) {
    return "Containers";
  }
}

export default function NGGridStackLayout({
  config,
  gridStackLayout, // TODO: do we need this?
  inDesignMode,
  context,
}: INGGridStackLayoutProps) {
  const insideIFrame = window !== window.top;
  const dragging = useSignal(false);
  const item = useSignal(cloneDeep(config));
  const handlers = setupHandlers(item.value.Layout, context);
  // console.log("~~handlers", handlers);
  // const triggerRender = useSignal(0);
  const itemToDelete = useSignal(null);

  function getControls(controls: Maybe<LayoutItem[]> | undefined): any {
    if (isNil(controls)) return [];

    const ids: string[] = [];

    return controls
      ?.filter((component) => {
        if (isNil(component) || !getVisibleProp(component)) return false;

        if (isNil(component.Id)) console.error("Component is missing an Id.", component);
        else {
          if (ids.includes(component.Id))
            console.error(`Component id '${component.Id}' is not unique, it's duplicated`, component);

          ids.push(component.Id);
        }

        return true;
      })
      .map((component) => {
        return <NGLayoutItem key={component.Id} config={component as any} inDesignMode={false} context={context} />;
      });
  }

  function constructComponentRenderList(itemSignal) {
    const resultArray = [...getControls(itemSignal.value.Items)];
    return resultArray;
  }

  function handleOnRemove(removedItem, gsLayouts: GridStackLayout[]) {
    item.value.Layout.InnerLayouts = gsLayouts;

    const removedItemType = determineComponentType(removedItem);

    item.value[removedItemType] = item.value[removedItemType].filter((item) => item.Id !== removedItem.key);

    if (handlers.onChange) {
      // console.log(
      //   "~~gridstack event [change] layoutItemClone, gridStackLayout, item.value: ",
      //   item.value,
      //   item.value.Layout,
      //   window.location.href
      // );
      handlers.onChange(new Event("remove"), item.value);
    }
  }

  function handleOnChange(e, gsLayouts: GridStackLayout[]) {
    // console.log("~~gridstack event [change] GOT IT");

    item.value.Layout.InnerLayouts = gsLayouts;

    if (handlers.onChange) {
      // console.log(
      //   "~~gridstack event [change] layoutItemClone, gridStackLayout, item.value: ",
      //   item.value,
      //   item.value.Layout,
      //   window.location.href
      // );
      handlers.onChange(e, item.value);
    }
  }

  function handleOnSelectedComponent(selectedComponent) {
    // console.log(
    //   "~~handleOnSelected selectedComponent, handlers: ",
    //   selectedComponent,
    //   handlers
    // );

    if (handlers.onSelected) {
      // console.log("~~handleOnSelected: executing handlers.onSelected");
      handlers.onSelected(new Event("onSelected"), selectedComponent);
    }
  }

  function handleOnDraggingChange(draggingValue) {
    dragging.value = draggingValue;
  }

  function handleMessage(message) {
    const msg = message.data;
    if (msg.Source != "ng") return;

    switch (msg.Type) {
      case "onPageUpdate":
        // console.log(
        //   "~~we are not in an iframe and we got this msg layout",
        //   msg.Page.Layout.InnerLayouts,
        //   window.location.href
        // );
        handleOnChange(new Event("pageUpdate"), item.value.Layout.InnerLayouts);
        break;

      case "parentDraggingOver":
        dragging.value = true;
        break;

      case "propertiesChanged":
        if (insideIFrame) {
          // console.log("~~propertiesChanged msg:", msg, window.location.href);

          const itemToAdd = { ...msg.Component };
          const componentType = "Items";

          const existingItem = item.value[componentType].find((c) => c.Id === msg.Component.Id);

          if (!existingItem) {
            // console.error("~~propertiesChanged existingItem not found, adding");
            return;
          }

          const mergeItem = {
            ...existingItem,
            ...itemToAdd,
            Id: generateUID(),
          };

          const pageOrContainer = item.value;

          // Update the existing item
          batch(() => {
            dragging.value = false;

            pageOrContainer[componentType].push(mergeItem);

            // push the layout as well
            const existingItemLayout = pageOrContainer.Layout.InnerLayouts.find(
              (l) => l.LayoutItem.Id === existingItem.Id
            );
            pageOrContainer.Layout.InnerLayouts.push({
              ...existingItemLayout,
              LayoutItem: { Id: mergeItem.Id },
            });

            itemToDelete.value = { ...existingItem };

            // remove the existing item
            pageOrContainer[componentType] = pageOrContainer[componentType].filter((c) => c.Id !== existingItem.Id);

            // remove the old corresponding layout as well
            pageOrContainer.Layout.InnerLayouts = pageOrContainer.Layout.InnerLayouts.filter(
              (c) => c.LayoutItem.Id !== existingItem.Id
            );

            item.value = { ...pageOrContainer };
            // console.log("~~propertiesChanged item.value:", item.value);
          });

          // console.log("~~propertiesChanged running handleOnChange");
          handleOnChange(new Event("change"), item.value.Layout.InnerLayouts);
        }
        break;

      case "deleteItem":
        if (insideIFrame) {
          // find the item, it's layout and delete it
          const componentType = "Items";

          const existingItem = item.value[componentType].find((c) => c.Id === msg.ItemId);

          const pageOrContainer = item.value;

          batch(() => {
            // remove the existing item
            pageOrContainer[componentType] = pageOrContainer[componentType].filter((c) => c.Id !== existingItem.Id);

            // remove the old corresponding layout as well
            pageOrContainer.Layout.InnerLayouts = pageOrContainer.Layout.InnerLayouts.filter(
              (c) => c.LayoutItem.Id !== existingItem.Id
            );

            itemToDelete.value = { ...existingItem };
            item.value = { ...pageOrContainer };
          });

          handleOnChange(new Event("change"), item.value.Layout.InnerLayouts);
        }
        break;

      case "stylesUpdated":
        window.location.reload();
        break;

      case "parentDropping":
        if (insideIFrame) {
          // console.log("~~parentDropping msg:", msg, window.location.href);

          const itemToAddComponentContainer = msg.Component.ComponentContainer;
          const componentType = msg.Component.Type;
          const { gridX, gridY } = pixelsToGridUnits(msg.Mouse.x, msg.Mouse.y);
          const itemId = generateUID();

          // console.log(
          //   `~~parentDropping ${componentType} itemToAddComponentContainer, msg:`,
          //   itemToAddComponentContainer,
          //   msg,
          //   window.location.href
          // );

          // Prepare the item to add
          const itemToAdd = {
            ...itemToAddComponentContainer.Items[0].Items[0],
            Id: itemId,
          };

          const pageOrContainer = item.value;

          // Update the existing item
          batch(() => {
            dragging.value = false;

            if (!pageOrContainer[componentType]) {
              pageOrContainer[componentType] = [];
            }

            if (isNil(pageOrContainer.Layout.InnerLayouts)) {
              pageOrContainer.Layout.InnerLayouts = [];
            }

            pageOrContainer.Layout.InnerLayouts.push({
              X: gridX,
              Y: gridY,
              H: msg.Component.InitialHeight || 100,
              W: msg.Component.InitialWidth || 2,
              LayoutItem: { Id: itemId },
            });

            pageOrContainer[componentType].push(...(Array.isArray(itemToAdd) ? itemToAdd : [itemToAdd]));

            item.value = { ...pageOrContainer };
          });

          // console.log("~~parentDropping running handleOnChange");
          handleOnChange(new Event("change"), item.value.Layout.InnerLayouts);
        }
        break;
      default:
    }
  }

  // setup the listener only on initial render
  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  if (isNil(item.value.Layout.InnerLayouts)) item.value.Layout.InnerLayouts = [];

  const typeName: string = isNil(item.value.__typename) ? (item.value as any).Typename : item.value.__typename;

  const prefix: { [key: string]: string } = {
    Page: "P_",
    SimpleContainer: "C_",
    Form: "F_",
  };

  const componentsToRender = useSignal([]);
  componentsToRender.value = constructComponentRenderList(item);
  // console.log("~~GSL componentsToRender:", componentsToRender.value);

  switch (typeName) {
    case "SimpleContainer":
    case "Page": {
      return (
        <Box sx={{ width: "100%" }} className={item.value.Classes}>
          <GridStackManaged
            innerLayouts={item.value.Layout.InnerLayouts}
            onChange={handleOnChange}
            onRemove={handleOnRemove}
            itemToDelete={itemToDelete.value}
            ngkey={prefix[typeName] + item.value.Id}
            inDesignMode={inDesignMode}
            dragging={dragging}
            handleOnDraggingChange={handleOnDraggingChange}
            onSelectedComponent={handleOnSelectedComponent}
            minRows={insideIFrame ? Math.round(window.innerHeight / 2) : 0}
          >
            {componentsToRender.value}
          </GridStackManaged>
        </Box>
      );
    }
    case "Form": {
      const form: Form = config as Form;

      return (
        <Box sx={{ width: "100%" }}>
          <GridStackManaged
            innerLayouts={gridStackLayout.InnerLayouts}
            ngkey={prefix[typeName] + form.Id + generateUID()}
            minRows={0}
          >
            {getControls(form.Items)}
          </GridStackManaged>
        </Box>
      );
    }
    default:
      return <div>Grid stack for type '{config.__typename}' not implemented yet</div>;
  }
}
