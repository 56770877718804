import { INGSnackbarProps } from "../../library/NGFieldExtensions";
import { computed, signal, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./NGSnackbar.css";
import NGButton from "../NGButton/NGButton";
import { getTestId } from "../../library/utils";
import { isNil, toNumber } from "lodash-es";

export default function NGSnackbar({ config, context }: INGSnackbarProps) {
  const local = setupLocalState(
    config,
    {
      Open: useSignal(config.Open ?? false),
      Message: useSignal(config.Message ?? ""),
      Severity: useSignal(config.Severity ?? "error"),
      AutoHideDuration: useSignal(config.AutoHideDuration ?? 5000),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const autohide = computed(() => {
    if (config.Autohide === false) return null;
    if (!isNil(local.AutoHideDuration.value)) return toNumber(local.AutoHideDuration.value);

    return config.AutoHideDuration ?? 5000;
  });

  return (
    <>
      <Snackbar
        data-testid={getTestId(config)}
        open={local.Open.value}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={autohide.value}
        onClose={(e, reason: string) => {
          if (reason == "clickaway") return;

          local.Open.value = false;
          if (handlers["onClose"]) {
            handlers["onClose"](e, e);
          }
        }}
        style={{ width: "100%" }}
      >
        <Alert
          severity={local.Severity.value}
          sx={{
            width: "100%", // Set the Alert's width to 100%
            "& .MuiAlert-message": {
              width: "100%", // Set the Alert message's width to 100%
              flexGrow: 1, // Enable the message to grow and fill the space
            },
            "& .MuiAlert-icon": {
              paddingTop: "14px",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              justifyItems: "space-between",
              //alignItems: "top",
              width: "100%",
            }}
          >
            <div style={{ paddingTop: "6px" }}>
              {local.Message.value}
              {config.ShowAnimation && <span className="animated-ellipsis"></span>}
            </div>
            {config.Button && <NGButton config={config.Button} context={context} />}
          </div>
        </Alert>
      </Snackbar>
    </>
  );
}
