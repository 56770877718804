import { Autocomplete, Box, FilterOptionsState, TextField, Typography, createFilterOptions } from "@mui/material";
import { FontProp } from "./NGTextStylesEditor";

interface FontOptionType {
  inputValue?: string;
  label: string;
  value?: string;
}

interface INGAutocompleteProps {
  value: string;
  listIndex: number;
  handleChange: (property: string, newValue: string | number) => void;
  fontConfig: FontProp;
}

const filter = createFilterOptions<FontOptionType>();

export default function NGAutocomplete({ fontConfig, value, listIndex, handleChange, dataTestId }: INGAutocompleteProps) {
  const selectedValue = fontConfig?.options.find(op => !!op?.value && op?.value === value);
  return (
    <Box key={`font-field-${listIndex}-menu`} sx={{ ...(fontConfig?.sx || { width: "100%" }) }}>
      <Typography className="control-label">{fontConfig?.label || fontConfig?.property}</Typography>
      <Autocomplete
        data-testid={dataTestId}
        size="small"
        value={String((selectedValue?.label || value) ?? "")}
        onChange={(e, newValue) => {
          if (typeof Number(newValue) === "number" && Number(newValue) > 0) {
            handleChange(fontConfig?.property, Number(newValue));
          } else if (typeof newValue === "string") {
            handleChange(fontConfig?.property, newValue);
          } else if (newValue && newValue?.inputValue) {
            handleChange(fontConfig?.property, newValue?.inputValue);
          } else {
            handleChange(fontConfig?.property, newValue?.value || newValue?.label || "");
          }
        }}
        filterOptions={(options: FontOptionType[], params: FilterOptionsState<FontOptionType>) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: inputValue,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={`free-solo-${fontConfig?.property}`}
        options={fontConfig?.options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.label}
            style={fontConfig?.styledValue ? { [fontConfig?.property]: option?.label } : undefined}
          >
            {option.label}
          </li>
        )}
        sx={{
          "& .MuiInputBase-root": {
            height: "40px",
            padding: "8px 12px !important",
            input: {
              padding: "0 !important",
            },
          },
        }}
        freeSolo
        className="prop-editor-input"
        renderInput={(params) => <TextField {...params} className="prop-editor-input" />}
      />
    </Box>
  );
}
