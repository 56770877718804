import React from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import composeClasses from '@mui/utils/composeClasses';
import { InputAdornment } from '@mui/material';
import { Search } from "@mui/icons-material";

const useUtilityClasses = (ownerState: { classes: (slot: string) => string }) => {
  const { classes } = ownerState;

  const slots = {
    root: ['toolbarQuickFilter'],
  };

  return composeClasses(slots, classes);
};

const GridToolbarQuickFilterRoot = styled(TextField)(({ theme }) => ({
  width: 'auto',
  marginLeft: "auto",
  paddingBottom: theme.spacing(0.5),
  '& input': {
    marginLeft: theme.spacing(0.5),
  },
  '& .MuiInput-underline:before': {

    "&:hover": {
      borderBottom: `1px solid #000`,
    }
  },
  [`& input[type="search"]::-webkit-search-decoration,
  & input[type="search"]::-webkit-search-cancel-button,
  & input[type="search"]::-webkit-search-results-button,
  & input[type="search"]::-webkit-search-results-decoration`]: {
    /* clears the 'X' icon from Chrome */
    display: 'none',
  },
}));

const defaultSearchValueParser = (searchText: string) =>
  searchText.split(' ').filter((word) => word !== '');

const defaultSearchValueFormatter = (values: string[]) => values.join(' ');


const GridToolbarQuickFilter = React.forwardRef((props: any, ref) => {
  const {
    quickFilterParser = defaultSearchValueParser,
    quickFilterFormatter = defaultSearchValueFormatter,
    className,
    ...other
  } = props;

  const [searchValue, setSearchValue] = React.useState(() =>
    quickFilterFormatter([]),
  );

  const handleSearchValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = event.target.value;
      setSearchValue(newSearchValue);
    },
    [],
  );

  return (
    <GridToolbarQuickFilterRoot
      variant="standard"
      value={searchValue}
      onChange={handleSearchValueChange}
      className={clsx(className)}
      placeholder="Search..."
      type="search"
      inputRef={ref}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{ marginLeft: "auto" }}
      {...other}
    />
  );
})


export { GridToolbarQuickFilter };
