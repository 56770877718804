import { useState, useEffect, useRef, SyntheticEvent } from "react";
import { Button } from "@mui/material";
import { Mic, SettingsVoiceOutlined } from "@mui/icons-material";
import NGSnackbar from "../NGSnackbar/NGSnackbar.js";
import { IActionTrigger, Snackbar } from "../../../resolvers-types.js";
import { setupHandlers, setupLocalState } from "../../library/dataService.js";
import { signal, useSignal } from "@preact/signals-react";
import { isNil, isString } from "lodash-es";
import { client } from "../../library/nats-client";
import {
  generateUID,
  isNullOrEmpty,
  getTempFileName,
  getSupportedRecordingAudioMimeType,
  getTestId,
} from "../../library/utils.js";
import NGAudioPlayer from "../NGAudioPlayer/NGAudioPlayer.js";
import { LogTag, log } from "../../library/logger.js";
import { native } from "../../library/native.js";
import { v4 as uuidv4 } from "uuid";

const tag: LogTag = "AudioRecorder";

export default function NGAudioRecorder({ config, context }) {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const chunksRef = useRef([]);
  const constraints = { audio: true };
  const componentInstanceId = useRef(uuidv4());

  if (isNil(config.DestinationBucket)) throw new Error("DestinationBucket is required in the configuration");

  const mimeType = getSupportedRecordingAudioMimeType();
  log.info(tag, `mimeType detected ${mimeType}`);

  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      DestinationBucket: useSignal(config.DestinationBucket),
      Value: useSignal(config.Value),
    },
    context
  );
  const handlers = setupHandlers(config, context);

  useEffect(() => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = (e) => {
        chunksRef.current.push(e.data);
      };

      mediaRecorder.onstop = async (e) => {
        await uploadAudio();

        if (!isNil((handlers as any).onStopRecording)) {
          (handlers as any).onStopRecording(e, local.Value.value);
        }
      };
    }
  }, [mediaRecorder, uploadAudio]);

  async function startRecording() {
    try {
      if (native.isNative()) {
        native.publish(
          "audio.recording.start",
          {
            Bucket: local.DestinationBucket.value,
          },
          componentInstanceId.current
        );
        setRecording(true);
      } else {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        const recorder = new MediaRecorder(stream, {
          mimeType: mimeType,
        });
        setMediaRecorder(recorder);
        recorder.start();
        console.log("Recording started");
        setRecording(true);
      }
    } catch (err) {
      console.error("Failed to start recording", err);
    }
  }

  function stopRecording() {
    if (native.isNative()) {
      native.publish("audio.recording.stop", {}, componentInstanceId.current);
      native.subscribe(handleFilenameReceived);
      setRecording(false);
    } else {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        console.log("Recording stopped");
        setRecording(false);
      }
    }
  }

  function handleFilenameReceived(event) {
    if (
      event.data.action === "audio.recording.fileUpload.done" &&
      event.data.componentInstanceId === componentInstanceId.current
    ) {
      console.log("Recording done, received file name: ", event.data.params.filename);
      local.Value.value = event.data.params.filename;

      if (!isNil((handlers as any).onStopRecording)) {
        console.log("executing handlers...");
        (handlers as any).onStopRecording({}, local.Value.value);
      }

      // Clean up after ourselves
      native.unsubscribe(handleFilenameReceived);
    }
  }

  async function uploadAudio() {
    try {
      const blob = new Blob(chunksRef.current, {
        type: mimeType,
      });
      console.log("blob:", blob);

      // Convert the blob to a stream
      const stream = blob.stream();

      // Define the file name and other properties
      const fileName = getTempFileName(mimeType);
      const fileSize = blob.size;

      // Upload the stream to NATS object store
      await client
        .objPut(
          local.DestinationBucket.value,
          fileName,
          `A ${local.DestinationBucket.value} audio recording`,
          fileSize,
          stream
        )
        .then((r) => {
          if (!r.success) {
            const msg = r.reasons?.map((x) => x.message).join("\n");
            console.error("Error", msg);
            // Handle the error appropriately
          } else {
            console.log("Upload successful");
            // Update the local state or trigger any success handlers
            local.Value.value = fileName;
          }
        });
    } catch (err) {
      console.error("Error in uploadAudio", err);
    } finally {
      chunksRef.current = [];
    }
  }

  const snackbar: Snackbar = {
    //Id: "status",
    Severity: "success",
    Open: true,
    Message: "Recording",
    ShowAnimation: true,
    Autohide: false,
    Button: {
      Id: generateUID(),
      Label: "Stop",
      Color: "error",
      StartIcon: { IconName: "Stop" },
      Style: { marginRight: "10px", border: "1px solid red" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (
            handlerName: string,
            action: IActionTrigger,
            e: SyntheticEvent,
            data: object,
            formCtx: FormContextType
          ) => {
            stopRecording();
          },
        },
      ],
    },
  };

  function shouldPlayerBeVisible() {
    return (
      config.DisplayPlayerAfterRecording &&
      isString(local.Value.value) && // TODO: figure out why value goes to true
      !isNullOrEmpty(local.Value.value)
    );
  }

  return (
    <>
      {local.Visible.value && (
        <>
          {recording ? (
            <>
              <Button
                data-testid={getTestId(config, "StopRecording")}
                className="button"
                startIcon={<SettingsVoiceOutlined />}
                onClick={stopRecording}
                sx={{ color: "#FF0000" }}
              />
              <NGSnackbar config={snackbar} context={context}></NGSnackbar>
            </>
          ) : (
            !shouldPlayerBeVisible() && (
              <Button
                data-testid={getTestId(config, "StartRecording")}
                className="button"
                startIcon={<Mic />}
                onClick={startRecording}
              />
            )
          )}
        </>
      )}

      {local.Visible.value && shouldPlayerBeVisible() && (
        <NGAudioPlayer
          config={{
            Id: `${config.Id}_AP`,
            ...config.AudioPlayer,
            Value: local.Value.value,
            DestinationBucket: config.DestinationBucket,
          }}
          context={context}
        />
      )}
    </>
  );
}
