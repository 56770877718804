import type * as graphql from "../../resolvers-types";

// SharingInfo: {
//     SharedBy: { Name: "El moro mussa" },
//     NoteType: "Text",
//     Text: " This is my first shared component",
//   },

export const shareForm: graphql.Form = {
  Id: "shareForm",
  UniqueName: "shareForm",
  Style: {
    paddingBottom: "0.5rem",
  },
  __typename: "Form",

  Items: [
    {
      __typename: "SimpleContainer",
      Id: "SharedBy",
      Layout: {
        __typename: "FlexLayout",
        Direction: "row",
        AlignItems: "center",
        Style: {
          marginBottom: "0.5rem",
          width: "100%",
        },
      },

      Items: [
        {
          __typename: "Label",
          Name: "Id",
          Id: "Id",
          Visible: false,
        },
        {
          __typename: "Avatar",
          Name: "SharedBy.Image",
          Id: "Image",
          Source: "Vault",
          Style: {
            borderRadius: "6px",
            width: "50px",
            height: "50px",
            background: "linear-gradient(to bottom, #17599a 75%, #033e78);",
            marginRight: "0.5rem",
          },
          Bindings: {
            AlternativeText: "FormData.SharedBy.Name",
          },
        },
        // {
        //   __typename: "Avatar",
        //   Id: "Image",
        //   Source: "Vault",
        //   Name: "SharedBy.Image",
        //   Bindings: {
        //     Visible: "FormData.SharedBy.Image",
        //   },
        //   Style: {
        //     borderRadius: "10px",
        //   },
        //   Width: "50px",
        //   Height: "auto",
        // },
        {
          __typename: "SimpleContainer",
          Id: "SharedBy2",
          Layout: {
            __typename: "FlexLayout",
            Direction: "column",
            Style: {
              padding: "5px 0px 0px 5px",
              width: "100%",
            },
          },

          Items: [
            {
              __typename: "SimpleContainer",
              Layout: {
                __typename: "FlexLayout",
                Direction: "row",
                JustifyContent: "space-between",
                width: "100%",
              },
              Items: [
                {
                  __typename: "Label",
                  Id: "sdaasda",
                  Name: "SharedOn",
                  DateFormat: { DateStyle: "medium", TimeStyle: "short" },
                  Style: { fontSize: "0.8rem" },
                },
                {
                  __typename: "Button",
                  Id: "ewrh",
                  StartIcon: {
                    __typename: "Icon",
                    IconName: "VisibilityOff",
                    Variant: "outlined",
                    Style: { color: "gray" },
                  },
                  Style: { padding: "0px" },
                  Actions: [
                    {
                      Trigger: "onClick",
                      CommandSet: {
                        Id: "0fb61b5f-6bb1-4736-9d75-e2c796231be2",
                        FirstCommandId: "1",
                        ExecuteCommandsInParallel: false,
                        Commands: [
                          //SharUersList
                          {
                            Id: "1",
                            Instruction: {
                              Name: "CallService",
                            },
                            Parameters: [{ Name: "ServiceName", Value: "IgnoreShare" }],
                            NextCommandIdOnSuccess: "2",
                          },
                          {
                            Id: "2",
                            Instruction: {
                              Name: "CallService",
                            },
                            Parameters: [
                              { Name: "ServiceName", Value: "UpdateFeedManual" }, //TODO: make this more generic move to feed
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            {
              __typename: "SimpleContainer",
              Layout: {
                __typename: "FlexLayout",
                Direction: "row",
                JustifyContent: "flex-start",
              },
              Items: [
                {
                  __typename: "Label",
                  Id: "dsaf",
                  Name: "SharedBy.Name",
                  // Bindings: {
                  //   Value: "FormData.SharedBy.Name+ ' shared with you'",
                  //   Visible: "FormData.SharedBy.Name",
                  // },
                  Style: {
                    fontWeight: "bold",
                    paddingRight: "0.3rem",
                    fontSize: "0.8rem",
                  },
                },
                {
                  __typename: "Label",
                  Id: "dsawf",
                  Value: "shared with you",
                  Style: {
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                  },
                },
              ],
            },
            {
              __typename: "Label",
              Id: "65247",
              Name: "Note.Text",
              Bindings: {
                Visible: "FormData.Note.NoteType=='Text'",
              },
              Style: {
                fontSize: "0.8rem",
                lineHeight: "1.1rem",
              },
            },
          ],
        },
      ],
    },
    // {
    //   __typename: "Label",
    //   Id: "TextNoteLabel",
    //   Bindings: {
    //     Visible: "FormData.Note.NoteType=='Text'",
    //   },
    //   Value: "Text Note",
    //   Style: {
    //     fontWeight: "bold",
    //   },
    // },
    // {
    //   __typename: "Label",
    //   Id: "VoiceNoteLabel",
    //   Bindings: {
    //     Visible: "FormData.Note.NoteType=='Audio' && FormData.Note.File",
    //   },
    //   Value: "Voice Note",
    //   Style: {
    //     fontWeight: "bold",
    //   },
    // },

    {
      //NoteType
      __typename: "AudioPlayer",
      Name: "Note.File",
      Id: "3rwed",
      DestinationBucket: "notes",
      Bindings: {
        Value: "FormData.Note.File",
        Text: "FormData.Note.Text",
        Visible: "FormData.Note.NoteType=='Audio' && FormData.Note.File",
      },
      TranscriptStyle: { fontSize: "0.8rem" },
      Style: { maxWidth: "200px" },
    },

    // {
    //   __typename: "AudioPlayer",
    //   Id: "wqwwer",
    //   Name: "Note.File",
    //   Bindings: {
    //     Visible: "FormData.Note.NoteType=='Text'",
    //   },
    //   DestinationBucket: "notes",
    // },
  ],
};
