import { IActionTrigger, Label } from "../../../resolvers-types";
import { INGAudioPlayerProps } from "../../library/NGFieldExtensions";
import "./NGAudioPlayer.css";

import { useSignal, signal } from "@preact/signals-react";
import { SyntheticEvent, useEffect } from "react";
import { FormContextType, setupHandlers, setupLocalState } from "../../library/dataService";
import { log } from "../../library/logger";
import { getFilesNatsBaseUrl, isNullOrEmpty, getSupportedPlaybackAudioMimeType, getTestId } from "../../library/utils";
import NGSwitch from "../NGSwitch/NGSwitch";
import NGLabel from "../NGLabel/NGLabel";

const tag = "NGAudioPlayer";

export default function NGAudioPlayer({ config, context }: INGAudioPlayerProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      DestinationBucket: useSignal(config.DestinationBucket ?? null),
      Text: useSignal(config.Text ?? ""),
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  const audioType = getSupportedPlaybackAudioMimeType();

  log.info(tag, `render ${local.Value.value} `, config);

  // getFilesNatsBaseUrl
  const handlers = setupHandlers(config, context);

  const url = useSignal("");

  const showTranscript = useSignal(false);

  useEffect(() => {
    if (!local.Visible.value || !local.Value.value) return;
    const value = local.Value.value;

    if (value.startsWith("http")) {
      url.value = value;
    } else {
      let v = getFilesNatsBaseUrl();

      if (local.DestinationBucket.value) {
        v += local.DestinationBucket.value + "/";
      }

      url.value = v + local.Value.value;

      const currentExtension = url.value.split(".").pop();
      log.info(tag, "currentExtension", currentExtension);

      const recordingExtension = "mp3";
      log.info(tag, "recordingExtension", recordingExtension);
      if (recordingExtension !== currentExtension) {
        url.value = url.value.replace("." + currentExtension, "." + recordingExtension);
        log.info(tag, "~~post replacement url.value", url.value);
      }
      log.info(tag, "~~final url.value", url.value);
    }
  }, [local.Visible.value, local.Value.value, url, local.DestinationBucket.value]);

  return (
    <>
      {local.Visible.value && (
        <div className="audioPlayer">
          <audio
            controls
            className="audioControl"
            // onPlay={() => handleAudioPlay()}
            // onPause={() => handleAudioPause()}
            preload="metadata"
          >
            <source src={url.value} type={audioType} data-testid={getTestId(config)} />
          </audio>
          {!isNullOrEmpty(local.Text.value) && (
            <>
              <div className="transcriptSwitch">
                <NGSwitch
                  config={{
                    Id: `tr_${config.Id}`,
                    LabelWhenOff: "Show Transcript",
                    LabelWhenOn: "Hide Transcript",
                    //Color: "#0c76bd",
                    DefaultValue: showTranscript.value.toString(),
                    LabelStyle: {
                      fontSize: "0.8rem",
                      fontWeight: "semi-bold",
                    },
                    Actions: [
                      {
                        Trigger: "onChange",
                        preHandler: (
                          handlerName: string,
                          action: IActionTrigger,
                          e: SyntheticEvent,
                          data: object,
                          formCtx: FormContextType
                        ) => {
                          console.log("onChange", e, data, formCtx);
                          showTranscript.value = (data as unknown) === true;
                        },
                      },
                    ],
                  }}
                  layoutType={""}
                  context={context}
                />
              </div>
              {showTranscript.value && (
                <NGLabel
                  config={
                    {
                      Id: `lb_${config.Id}`,
                      Value: local.Text.value,
                      MultiLine: true,
                      Style: config.TranscriptStyle,
                    } as Label
                  }
                  context={context}
                />
                // <TextareaAutosize
                //   className="transcriptText"
                //   value={local.Text.value}
                //   disabled={true}
                // ></TextareaAutosize>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
