import { setupInitialState, setupLocalState, setupServices, updateItemContext } from "../library/dataService";
import { INGComponentProps } from "../library/NGFieldExtensions";
import { SimpleContainer } from "../../resolvers-types";
import NGLayoutItem from "./NGLayoutItem";
import { GlobalStyles } from "@mui/material";
import { useSignal } from "@preact/signals-react";
import { renderModalPopups } from "../components/ComponentUtils";

export default function NGComponent({ config, context }: INGComponentProps) {
  const currentContext = updateItemContext(context, config);

  // currentContext.InDesignMode = config.InDesignMode;

  const local = setupLocalState(
    config,
    {
      Visible: useSignal(true),
      Styles: useSignal(config.Styles ?? []),
    },
    currentContext
  );

  setupServices(config, currentContext);
  setupInitialState(config, currentContext);

  return (
    <>
      {renderModalPopups(config, currentContext)}
      <div data-testid={config.Id} data-type={config.__typename}>
        <GlobalStyles styles={{ ...local.Styles.value }} />
        <NGLayoutItem config={config.ComponentContainer as SimpleContainer} context={currentContext} />
      </div>
    </>
  );
}
