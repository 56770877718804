import { INGDateTimePickerProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { signal, useSignal } from "@preact/signals-react";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function NGDateTimePicker({ config, context }: INGDateTimePickerProps) {
  const local = setupLocalState(
    config,
    {
      Disabled: useSignal(config.Disabled ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Value: useSignal(config.Value ?? dayjs(new Date())),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateTimePicker value={local.Value.value} onChange={(e) => (local.Value.value = e.value)} {...handlers} />
    </LocalizationProvider>
  );
}
