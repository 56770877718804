import { useContext, useRef } from "react";
import { IEditable, IField } from "../../../resolvers-types";
import { IFieldMethods } from "../../library/NGFieldExtensions";
import NGInputField from "../NGInputField/NGInputField.js";
import { sendPromptToNATS } from "./APIs.js";
import NGButton from "../NGButton/NGButton.js";
import Grid from "@mui/material/Grid";
import { GetLocalString } from "../../library/localization.js";
import { CloudUpload, Input } from "@mui/icons-material";
import { FormContext, setupLocalState, setupHandlers } from "../../library/dataService.js";
import { useSignal } from "@preact/signals-react";
import NGBasicFileUpload from "../NGFileUpload/NGBasicFileUpload.js";
import { log } from "../../library/logger.js";
import { generateUID } from "../../library/utils.js";

export default function NGChatBox({ config, context }) {
  // console.log('ChatBox - pageSource:', pageSource)
  const inputRef = useRef(null);
  const tag = "NGChatBox";

  const data = useSignal(null);
  const local = setupLocalState(config, {}, context);
  config.getData = (e: any) => data.value;

  const handlers = setupHandlers(config, context);
  log.info(tag, "handlers.onFileUpload:", handlers["onFileUpload"]);

  const methods: IFieldMethods = {
    IsValid: (config: IField & IEditable, value: any) => {
      log.info(tag, "Validating", value);

      //if (!isNil(config.ValidationRules) && !isEmpty(config.ValidationRules)) {
      //  return applyValidation(config, value);
      //}

      return { success: true };
    },
    ValueChange: (config: IField & IEditable, e: { value: string }) => {
      log.info("CHATBOX: ValueChange", e.value);
      // setHtml(e.value);
    },
    OnKeyDown: (config: IField & IEditable, e: { value: string; key: string }) => {
      if (e.key === "Enter") {
        log.info(tag, "Enter key was hit");
        handleEnter(e.value);
      }
    },
  };

  async function handleEnter(userInputRaw: string) {
    console.log("you have hit enter");
  }

  log.info(tag, "config.OnFileUpload:", config.OnFileUpload);

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <NGInputField
          config={{
            Placeholder: config.Placeholder,
            AutoFocus: true,
            FullWidth: true,
            Value: config.html,
            Ref: inputRef,
            ClearValueOnEnter: true,
            Multiline: true,
            MinRows: config.InputBoxHeight,
            MaxRows: config.InputBoxHeight,
            Variant: "outlined",
          }}
          style={{
            backgroundColor: "#021430",
            flexShrink: 0,
            borderColor: "#42A5F5",
            color: "#fff",
            borderRadius: "5px",
          }}
          methods={methods}
          context={context}
        />
      </div>
      <div style={{ marginTop: "10px", marginBottom: "20px" }}>
        {config.OnFileUpload && (
          <NGBasicFileUpload
            config={{
              Actions: [
                {
                  Trigger: "onChange",
                  CommandSet: config.OnFileUpload.CommandSet,
                },
              ],
              DefaultValue: "",
              Value: "",
              Visible: false,
              DisplayUpload: false,
              DisableUnderline: true,
            }}
            context={context}
          />
        )}

        <NGButton
          config={{
            Id: generateUID(),
            Label: GetLocalString("Submit"),
            StartIcon: { IconName: "Send" },
            Variant: "ai-dialog-button",
          }}
          context={context}
        />
      </div>
    </>
  );
}
