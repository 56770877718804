import { signal, useSignal } from "@preact/signals-react";
import { getClassName } from "../../library/utils";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { INGContextMenuProps } from "../../library/NGFieldExtensions";
import { Menu } from "@mui/material";
import NGLayoutItem from "../../generators/NGLayoutItem";

export default function NGContextMenu({ config, context }: INGContextMenuProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Disabled: useSignal(config.Disabled ?? false),
      Visible: useSignal(config.Visible ?? false),
      AnchorElement: useSignal(config.AnchorElement),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={local.AnchorElement.value}
      open={local.Visible.value}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className={getClassName(local.Classes)}
      {...handlers}
      onClose={() => (local.Visible.value = false)}
    >
      <NGLayoutItem config={config.Content} context={context} />
    </Menu>
  );
}
