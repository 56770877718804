import { Stack, TextField, Typography } from "@mui/material";

export default function NGTextInput({ fontConfig, value, listIndex, handleChange, dataTestId }) {
  return (
    <Stack
      key={`font-field-${listIndex}-text`}
      sx={{
        alignItems: "flex-start",
        flexDirection: "column",
        color: "inherit",
        width: "48%",
      }}
      data-testid={dataTestId}
    >
      <Typography className="control-label">{fontConfig?.label || fontConfig?.property}</Typography>
      <TextField
        className="prop-editor-input"
        sx={{
          "& .MuiInputBase-root": {
            height: "40px",
          },
          width: "100%",
        }}
        onChange={(e) => {
          let newValue: string | number = parseInt(e.target.value, 10);
          if (isNaN(Number(e.target.value)) || !e.target.value) {
            newValue = e.target.value;
          }
          handleChange(fontConfig?.property, newValue);
        }}
        type="text"
        value={value ?? ""}
      />
    </Stack>
  );
}
