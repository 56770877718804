import { INGRadioGroupProps } from "../../library/NGFieldExtensions";
import { FormControlLabel, RadioGroup as MUIRadioGroup, Radio } from "@mui/material";
import { signal, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, isNullOrEmpty } from "../../library/utils";
import { isNil, isObject } from "lodash-es";

export default function NGRadioGroup({ context, config }: INGRadioGroupProps) {
  const tag = "NGRadioGroup";
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.DefaultValue ?? ""),
      MultiSelectPossibleValues: useSignal(config.MultiSelectPossibleValues ?? []),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <>
      <MUIRadioGroup
        data-testid={getTestId(config)}
        value={local.Value.value}
        {...handlers}
        onChange={(e) => {
          local.Value.value = e.target.value;
          if (handlers["onChange"]) handlers["onChange"](e, local.Value.value);
        }}
      >
        {local.MultiSelectPossibleValues.value.map((datum: any, index) => {
          let label = datum;
          let value = datum;

          if (isObject(datum) && !isNil(config.ValueExpression) && !isNil(config.LabelExpression)) {
            label = datum[config.LabelExpression];
            value = datum[config.ValueExpression];
          }

          return <FormControlLabel label={label} key={index} value={value} control={<Radio color="primary" />} />;
        })}
      </MUIRadioGroup>
    </>
  );
}
