import { Signal } from "@preact/signals-core";
import { createContext } from "react";
import { TableLayout } from "../../resolvers-types";

export type TableContextType = {
  ExpandedRows: Signal<string[]>;
  TableConfig: TableLayout;
  Handlers: any;
};

export const TableContext = createContext({
  ExpandedRows: new Signal([]),
  TableConfig: {} as TableLayout,
  Handlers: {},
} as TableContextType);
