import { INButtonGroupProps } from "../../library/NGFieldExtensions";
import { FormControlLabel, ToggleButtonGroup as MUIToggleButtonGroup, ToggleButton } from "@mui/material";
import { signal, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getsxObject, getTestId, isNullOrEmpty } from "../../library/utils";
import { isNil, isObject } from "lodash-es";
import NGIcon from "../NGIcon/NGIcon";

export default function NGToggleButtonGroup({ context, config }: INButtonGroupProps) {
  const tag = "NGToggleButton";
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.DefaultValue ?? ""),
      MultiSelectPossibleValues: useSignal(config.MultiSelectPossibleValues ?? []),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <>
      <MUIToggleButtonGroup
        data-testid={getTestId(config)}
        value={local.Value.value}
        {...handlers}
        onChange={(e, value) => {
          local.Value.value = value;
          if (handlers["onChange"]) handlers["onChange"](e, local.Value.value);
        }}
        onClick={(e) => handlers["onClick"](e, { Value: local.Value.value })}
        orientation={config.Orientation ?? "horizontal"}
        exclusive={config.Exclusive ?? false}
      >
        {local.MultiSelectPossibleValues.value.map((datum: any, index) => {
          let label = datum;
          let value = datum;

          if (isObject(datum) && !isNil(config.ValueExpression) && !isNil(config.LabelExpression)) {
            label = datum[config.LabelExpression];
            value = datum[config.ValueExpression];
          }

          if (isObject(datum) && !isNil(config.ValueExpression) && !isNil(datum["IconName"])) {
            label = <NGIcon config={{ IconName: datum["IconName"] }} context={context} />;
            value = datum[config.ValueExpression];
          }

          return (
            <ToggleButton
              key={index}
              sx={getsxObject(local.Style.value)}
              value={value}
              color="primary"
              size="small"
              selected={false}
            >
              {label}
            </ToggleButton>
          );
        })}
      </MUIToggleButtonGroup>
    </>
  );
}
