import { ReactElement, useEffect, useMemo } from "react";
import { useComputed, useSignal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { INGIconSelectorProps } from "../../library/NGFieldExtensions";
import {
  Radio,
  RadioGroup,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import * as Icons from "@mui/icons-material";
import { endsWithSuffix, generateUID, isNullOrEmpty } from "../../library/utils";
import NGIcon from "../NGIcon/NGIcon";

export default function NGIconSelector({ config, context }: INGIconSelectorProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
    },
    context
  );

  const searchIconInput = useSignal("");
  const expanded = useSignal(false);
  const handlers = setupHandlers(config, context);

  const useIconNames = (searchInput: string): string[] => {
    const iconNames = useMemo(() => {
      const names: string[] = [];
      for (const key in Icons) {
        if (
          typeof Icons[key] === "object" &&
          names.length < 40 &&
          !endsWithSuffix(key) &&
          key.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          names.push(key);
        }
      }
      return names;
    }, [searchInput]); // Dependency array includes searchIconInput

    return iconNames;
  };

  const iconNames = useIconNames(searchIconInput.value);

  const SelectedIconComponent = Icons[local.Value.value?.IconName];

  const setValue = (e, newIconName: string) => {
    local.Value.value = isNullOrEmpty(newIconName) ? null : { IconName: newIconName }; //, Id: generateUID();

    if (handlers["onChange"]) {
      handlers["onChange"](e, local.Value.value);
    }
  };

  const DeleteIcon = Icons["Delete"];

  return (
    <Accordion
      data-testid="controls-group-accordion"
      style={{ width: "100%" }}
      expanded={expanded.value}
      onChange={(e, value) => (expanded.value = value)}
    >
      <AccordionSummary
        data-testid="controls-group-title"
        aria-controls="controls-group-title"
        id="panel1-header"
        expandIcon={<NGIcon config={{ IconName: "ExpandMore" }} context={context} />}
        className="icons-group-header"
      >
        {config.Label}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", gap: 2, marginLeft: "10px", marginTop: "5px", height: "24px" }}
          >
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }} className="control-label">
              Icon selected:
            </Typography>
            {!!local.Value.value?.IconName && Icons[local.Value.value?.IconName] ? (
              <>
                <SelectedIconComponent />
                <DeleteIcon
                  onClick={(e) => setValue(e, "")}
                  className="icon-selector-delete"
                  style={{ color: "grey", cursor: "pointer", marginLeft: "auto", marginRight: "10px" }}
                />
              </>
            ) : (
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }} className="control-label">
                none
              </Typography>
            )}
          </Box>
          <TextField
            data-testid={`component-editor-search-icons-${config.Name}`}
            placeholder={"Search icons..."}
            fullWidth
            variant="outlined"
            size="small"
            style={{ padding: "0 6px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <NGIcon config={{ IconName: "Search" }} context={context} />
                </InputAdornment>
              ),
            }}
            value={searchIconInput}
            onChange={(e: any) => (searchIconInput.value = e.target.value)}
          />
          {iconNames.length ? (
            <>
              <RadioGroup
                row
                name="icon-selector-radio-group"
                defaultValue=""
                onChange={(e) => setValue(e, e.target.value)}
                value={local.Value.value?.IconName ?? ""}
              >
                {iconNames.map((icon) => {
                  const IconComponent = Icons[icon];
                  return <Radio key={icon} icon={<IconComponent />} checkedIcon={<IconComponent />} value={icon} />;
                })}
              </RadioGroup>
              {iconNames.length === 40 && <div className="icon-selector-label">Showing first 40 results...</div>}
            </>
          ) : (
            <div className="icon-selector-label">No matching results</div>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
