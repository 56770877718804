import { INGTableLayoutProps } from "../library/NGFieldExtensions";
import { generateUID, getTableItemsProps, getsxObject } from "../library/utils";
import { signal, useSignal } from "@preact/signals-react";
import { Repeater } from "../../resolvers-types";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import NGLayoutItem from "../generators/NGLayoutItem";
import { setupHandlers, setupLocalState, updateItemContext } from "../library/dataService";
import { TableContext, TableContextType } from "./NGTableContext";

export default function NGTableLayout({ config, tableLayout, context, children }: INGTableLayoutProps) {
  const currentContext = updateItemContext(context, tableLayout);
  const repeater = config as Repeater;

  const local = setupLocalState(
    tableLayout,
    {
      Style: useSignal(tableLayout.Style || {}),
      ExpandedRows: useSignal([]),
    },
    context
  );

  const handlers = setupHandlers(tableLayout, context);

  const tableContext: TableContextType = {
    ExpandedRows: local.ExpandedRows,
    TableConfig: tableLayout,
    Handlers: handlers,
  };

  return (
    <TableContext.Provider value={tableContext}>
      <TableContainer sx={getsxObject(tableLayout.TableStyle)}>
        <Table>
          {repeater.Header && (
            <TableHead>
              <TableRow sx={getsxObject(tableLayout.HeaderRowStyle)}>
                {tableLayout.Expandable && (
                  <TableCell sx={getsxObject((tableLayout as any).CellStyle)} key={generateUID()} />
                )}
                {(repeater.Header as any)?.Items?.map((x) => (
                  <TableCell key={generateUID()} {...getTableItemsProps(x.Id, tableLayout.HeaderItemsProps as any, {})}>
                    <NGLayoutItem config={x} context={currentContext} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </TableContext.Provider>
  );
}
