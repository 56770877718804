import * as graphql from "../../resolvers-types";

export const menuSample: graphql.Menu = {
  __typename: "Menu",
  Id: "0xa1",
  Name: "Demo Menu3",
  FeatureSets: [
    {
      __typename: "FeatureSet",
      Id: "0xa2",
      Name: "Dashboards",
      Label: "Dashboards",
      Features: [
        {
          __typename: "Feature",
          Id: "0xa3",
          Visible: true,
          Label: "My CRM Dashboard",
        },
        {
          __typename: "Feature",
          Id: "0xa4",
          Visible: true,
          Label: "Second Dashboard",
        },
      ],
    },
    {
      __typename: "FeatureSet",
      Id: "0xaa2",
      Name: "Contacts",
      Label: "Contacts",
      Features: [
        {
          __typename: "Feature",
          Id: "0xa23",
          Visible: true,
          Label: "My Contacts",
        },
        {
          __typename: "Feature",
          Id: "0xa24",
          Visible: true,
          Label: "New Contact",
        },
        {
          __typename: "Feature",
          Id: "0xa24",
          Visible: true,
          Label: "Import Contacts",
        },
      ],
    },
  ],
};
