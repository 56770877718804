import { ExpandMore } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useSignal } from "@preact/signals-react";
import { isNull } from "lodash-es";

type MenuButtonEditorProps = {
  name: string;
  options: { Text: string; Label?: string; Icon: JSX.Element; Value: object }[];
  selectedIndex: number | null;
  onChange: (value: object) => void;
  showIcon?: boolean;
  width?: string;
};

function MenuButtonEditor({
  name,
  options,
  selectedIndex,
  onChange,
  showIcon = true,
  width = "100%",
}: MenuButtonEditorProps) {
  const anchorEl = useSignal<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl.value);

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>, value: object) => {
    anchorEl.value = null;

    onChange(value);
  };

  const handleCloseMenu = () => {
    anchorEl.value = null;
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.value = event.currentTarget;
  };

  const selectedOption = isNull(selectedIndex) ? null : options?.[Number(selectedIndex)];

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "1rem", width }}>
      {showIcon && (selectedOption?.Icon || options[0].Icon)}
      <IconButton title={`${name}-button`} onClick={(e) => handleOpenMenu(e)} className="menu-button-prop-editor">
        <Typography>{selectedOption?.Label ?? selectedOption?.Text}</Typography>
        <ExpandMore />
      </IconButton>
      <Menu
        id={`${name}-menu`}
        anchorEl={anchorEl.value}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map(({ Value, Icon, Text }, index) => (
          <MenuItem
            key={index}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, Value)}
            sx={{ width: "184px" }}
          >
            <ListItemIcon>{Icon}</ListItemIcon>
            <ListItemText>{Text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}

export default MenuButtonEditor;
