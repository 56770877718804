import { IAccordionLayoutProps } from "../library/NGFieldExtensions";
import { isNil, isString } from "lodash-es";
import { signal, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { getClassName, getsxObject, keyByRec } from "../library/utils";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import NGLayout from "./NGLayout";
import NGLayoutItem from "../generators/NGLayoutItem";
import { log } from "../library/logger";
import NGIcon from "../components/NGIcon/NGIcon";
import { isLayout } from "../library/metadataUtils";

const tag = "NGaccordionLayout";

export default function NGAccordionLayout({ layoutItem, accordionLayout, context }: IAccordionLayoutProps) {
  const itemMap = keyByRec((layoutItem as any).Items, "Items", "Id", {});

  const local = setupLocalState(
    accordionLayout,
    {
      Style: useSignal(accordionLayout.Style ?? {}),
      Classes: useSignal(accordionLayout.Classes ?? []),
      Visible: useSignal(accordionLayout.Visible ?? true),
    },
    context
  );

  const localGroups = accordionLayout.Groups.map((group) => {
    return setupLocalState(
      group,
      {
        Expanded: useSignal(group.Expanded ?? true),
        ToggleExpanded: useSignal(group.ToggleExpanded ?? true),
      },
      context
    );
  });

  const handlers = setupHandlers(accordionLayout, context);

  return (
    <>
      {accordionLayout.Groups?.map((group, key) => {
        return (
          <Accordion
            aria-label={accordionLayout.AriaLabel ?? "accordion"}
            className={getClassName(local.Classes)}
            sx={getsxObject(local.Style.value)}
            key={key}
            expanded={localGroups[key].Expanded?.value ?? localGroups[key].ToggleExpanded?.value ?? true}
            {...handlers}
            onChange={(e, expanded) => {
              localGroups[key].Expanded = expanded;
              e.preventDefault();

              if (handlers["onChange"]) {
                handlers["onChange"](e, { Group: group.Id, Expanded: expanded });
              }
            }}
          >
            <AccordionSummary
              data-testid={`accordion-group-${group.Label}`}
              aria-controls={`accordion-group-${group.Label}`}
              id="panel1-header"
              expandIcon={<NGIcon config={{ IconName: "ExpandLess" }} context={context} />}
              key={key}
            >
              {group.Label}
            </AccordionSummary>
            <AccordionDetails>
              {group.Items?.map((x, key) => {
                const item = isString(x) ? itemMap[x] : x;

                if (isNil(item)) {
                  log.error(tag, `Item with id '${x}' could not be found`);
                  return <></>;
                }
                // The Box below is necessary to avoid error: "Each child in a list should have a unique "key" prop"
                return (
                  <Box key={`${group.Label}-${key}`}>
                    {!isLayout(x) ? (
                      <NGLayoutItem config={item} context={context} />
                    ) : (
                      <NGLayout layoutItem={layoutItem} layout={x} inDesignMode={false} context={context} />
                    )}
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
