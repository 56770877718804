import { Slider } from "../../../resolvers-types";
import { INGSliderProps } from "../../library/NGFieldExtensions";
import { Slider as MUISlider } from "@mui/material";
import { useSignal } from "@preact/signals-react";
import { isNil } from "lodash-es";
import { getTestId, getsxObject, toNumberOrZero, useConfig, getClassName } from "../../library/utils";
import { setupHandlers, setupLocalState } from "../../library/dataService";

export default function NGSlider({ config, context }: INGSliderProps) {
  config = useConfig("NGSlider", config) as Slider;
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.DefaultValue ?? 0),
      Visible: useSignal(config.Visible ?? true),
      Disabled: useSignal(config.Disabled ?? false),
      Classes: useSignal(config.Classes ?? []),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  let marks: any = config.Marks;

  if (isNil(marks) && !isNil(config.MarkFrequency) && !isNil(config.Max)) {
    marks = generateValueLabelPairs(
      config.MarkFrequency.Value,
      config.MarkFrequency.Label,
      config.Min ?? 0,
      config.Max
    );
  }

  const handlers = setupHandlers(config, context);

  return (
    <MUISlider
      data-testid={getTestId(config)}
      data-type={config.__typename}
      sx={getsxObject(local.Style.value, {
        maxWidth: "calc(100% - 22px)",
        marginLeft: "11px",
        touchAction: "pan-y", // this is critical to allow the slider to be used on mobile
      })}
      //ariaLabel={config.AriaLabel as any }
      //ariaLabelledby={config.AriaLabelledby as string }
      //ariaValuetext={config.AriaValuetext as string }
      className={getClassName(local.Classes)}
      color={config.Color as any}
      disabled={local.Disabled.value}
      disableSwap={config.DisableSwap as boolean}
      marks={marks}
      max={config.Max as number}
      min={config.Min as number}
      orientation={config.Orientation as any}
      size={config.Size as any}
      step={config.Step as number}
      value={toNumberOrZero(local.Value.value)}
      valueLabelDisplay={(config.ValueLabelDisplay as any) ?? "off"}
      // components={{
      //   ValueLabel: <NGLayoutItem layoutItem={config.ValueLabelItem} />,
      // }}
      {...handlers}
      onChange={(e, v) => {
        local.Value.value = v;

        if (!isNil((handlers as any).onChange)) {
          (handlers as any).onChange(e, local.Value.value);
        }
      }}
    ></MUISlider>
  );
}

interface ValueLabelPair {
  value: number;
  label?: string; // Making Label optional
}
function generateValueLabelPairs(
  valueFrequency: number,
  labelFrequency: number,
  min: number,
  max: number
): ValueLabelPair[] {
  {
    const pairs: ValueLabelPair[] = [];
    for (let i = min; i <= max; i += valueFrequency) {
      const label = i % labelFrequency === 0 ? i.toString() : undefined;
      pairs.push({ value: i, label: label });
    }
    return pairs;
  }
}
