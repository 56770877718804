import { INGModalPopupEditorProps } from "../../library/NGFieldExtensions";
import { Box, Switch, SwitchProps, styled } from "@mui/material";
import NGCodeEditor from "../NGCodeEditor/NGCodeEditor";
import { Signal, useComputed, useSignal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { cloneDeep, isEqual, isNil, isObject } from "lodash-es";
import CodeIcon from "@mui/icons-material/Code";
import NGMultiSelect from "../NGMultiSelect/NGMultiSelect";
import { Button, MultiSelect } from "../../../resolvers-types";
import NGSimpleContainer from "../../generators/NGSimpleContainer";
import NGButton from "../NGButton/NGButton";
import { Star } from "@mui/icons-material";
import { findReferences, stringToCustomNumber } from "../../library/utils";
import CustomSwitch from "../NGCustomSwitch/NGCustomSwitch";

export default function NGModalPopupEditor({ config, context }: INGModalPopupEditorProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal((config as any).Value ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  // useSignalEffect(() => {
  //   const keys = currentConfiguration.peek();
  //   //const newValue = { ...cloneDeep(local.Value.peek()) }; //, [keys.Value[0]]: subObject.value };

  //   const o = isObject(local.Value.peek()) ? local.Value.peek() : {};

  //   const newValueDirty = updateDeepProperty(cloneDeep(o), keys.Value, subObject.value);

  //   if (isEqual(newValueDirty, local.Value.peek())) {
  //     return;
  //   }

  //   const sanitizedValue = Object.fromEntries(Object.entries(newValueDirty).filter(([_, v]) => !!v));

  //   local.Value.value = sanitizedValue;

  //   if (handlers["onChange"]) {
  //     handlers["onChange"](new Event("onChange"), sanitizedValue);
  //   }
  // });

  // [
  //   {
  //     "__typename": "Dialog",
  //     "Id": "00x4vbyyoh",
  //     "Title": "Generate Component",
  //     "FullScreen": true,
  //     "ContentContainer": {
  //       "__typename": "SimpleContainer",
  //       "Id": "00x4vbyyoh_Container",
  //       "Items": [
  //         {
  //           "__typename": "Reference",
  //           "Id": "00x4vbyyohwyx_Reference",
  //           "ReferenceType": "Component",
  //           "ReferenceId": "account-balance-details",
  //           "Inputs": {
  //             "AccountBalance": "Parent.AccountBalance"
  //           }
  //         }
  //       ]
  //     }
  //   }
  // ]

  const references = useComputed(() => {
    if (isNil(local.Value.value) || !Array.isArray(local.Value.value) || local.Value.value.length === 0) {
      return [];
    }
    const a: any[] = [];

    for (const item of local.Value.value) {
      const refs = findReferences(item);

      refs.forEach((r) => {
        a.push({
          Id: r.ReferenceId,
          Title: `${item.Title} (${r.ReferenceId})`,
        });
      });
    }

    return a;
  });

  const stateValue = `State.SelectedPopup${stringToCustomNumber(config.Id)}`; //${config.Id}`;

  const msConfig: any = useComputed(() => {
    const o = {
      Id: `${config.Id}_ListOfPopups`,
      __typename: "MultiSelect",
      Label: "Modal Popup",
      MultiSelectPossibleValues: references.value,
      Bindings: {
        Value: stateValue,
      },
      LabelExpression: "Title",
      ValueExpression: "Id",
    };

    return o;
  });

  const btnConfig: any = {
    Id: `${config.Id}_Btn`,
    __typename: "Button",
    StartIcon: { IconName: "Edit" },
    Style: {
      width: "30px",
    },
    Bindings: {
      Disabled: `!${stateValue}`,
    },
    Actions: [
      {
        Trigger: "onClick",
        CommandSet: {
          FirstCommandId: "1",
          ExecuteCommandsInParallel: false,
          Commands: [
            {
              Id: "1",
              Instruction: {
                Id: "1",
                Name: "GoToPage",
              },
              Parameters: [
                {
                  Name: "Page.Url",
                  Value: "/local/component-editor-gui",
                },
                {
                  Name: "URLBindings",
                  Value: {
                    ModelId: stateValue,
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  };

  const showCodeEditor = useSignal(false);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.6rem" }}>
        <CustomSwitch
          checked={showCodeEditor.value}
          onChange={() => {
            showCodeEditor.value = !showCodeEditor.value;
          }}
          icon={<CodeIcon fontSize="small" />}
          checkedIcon={<CodeIcon fontSize="small" />}
          sx={{
            alignSelf: "flex-end",
            marginBottom: "3px",
            position: "absolute",
            top: 0,
            transform: "translateY(75%)",
          }}
        />
        {showCodeEditor.value ? (
          <NGCodeEditor config={{ ...config, Height: "200px" }} context={context} />
        ) : (
          <>
            <NGSimpleContainer
              config={{ Id: `${config.Id}_SC`, Style: { display: "flex", flexDirection: "row" }, IgnoreLayout: true }}
              context={context}
            >
              <NGMultiSelect config={msConfig.value} context={context} />
              <NGButton config={btnConfig} context={context} />
            </NGSimpleContainer>
          </>
        )}

        {/* <>
          <NGMultiSelect config={msConfig.value} context={context} />
          <NGCodeEditor config={{ ...config, Height: "200px" }} context={context} />
        </> */}
      </Box>
    </>
  );
}

const getNestedProperty = (obj: any, keys: string[]): any => {
  return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
};

type DeepObject = { [key: string]: any };

function updateDeepProperty(obj: DeepObject, path: string[], value: any): DeepObject {
  const [head, ...tail] = path;

  // If the path is empty, return the value
  if (!head) {
    return value;
  }

  // If the property doesn't exist, create it
  if (!Object.prototype.hasOwnProperty.call(obj, head)) {
    obj[head] = {};
  }

  // Recursive case: update or create the nested property
  obj[head] = updateDeepProperty(obj[head], tail, value);

  return obj;
}
