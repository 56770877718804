import { IconButton, TableCell, TableRow } from "@mui/material";
import NGLayoutItem from "../generators/NGLayoutItem";
import { INGTableRowProps } from "../library/NGFieldExtensions";
import { getsxObject } from "../library/utils";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { useContext } from "react";
import { TableContext } from "./NGTableContext";
import NGTableCollapsableRow from "./NGTableCollapsableRow";
import { isNil } from "lodash";
import { useComputed } from "@preact/signals-react";
import NGIcon from "../components/NGIcon/NGIcon";

export default function NGTableRow({ item, details, context }: INGTableRowProps) {
  item.Layout = {
    __typename: "FormInCell",
    Id: "GLO_" + item.Id,
  };

  const row = context.Repeater?.Row;

  const tableContext = useContext(TableContext);

  const isExpanded = useComputed(() => {
    if (isNil(tableContext.ExpandedRows.value) || tableContext.ExpandedRows.value.length == 0) return false;

    return tableContext.ExpandedRows.value.includes(item.Id);
  });

  function toggleExpanded(e) {
    if (isNil(tableContext.ExpandedRows)) return;

    if (tableContext.ExpandedRows.value.includes(item.Id)) {
      // Collapse
      const handlers = tableContext.Handlers;
      if (!isNil(handlers) && handlers["onRowCollapse"]) {
        handlers["onRowCollapse"](e, row);
      }

      tableContext.ExpandedRows.value = tableContext.ExpandedRows.value.filter((item) => item !== item.Id);
    } else {
      // Expand
      const handlers = tableContext.Handlers;
      if (!isNil(handlers) && handlers["onRowExpand"]) {
        handlers["onRowExpand"](e, row);
      }

      tableContext.ExpandedRows.value.push(item.Id);
      tableContext.ExpandedRows.value = [...tableContext.ExpandedRows.value];
    }
  }

  const tableConfig = tableContext.TableConfig;

  if (!isNil(details)) details.Data = row;

  // TODO Allow for row styles
  const s = getsxObject({}, { "&:last-child td, &:last-child th": { border: 0 } });

  return (
    <>
      <TableRow sx={s}>
        {tableConfig.Expandable && (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={(e) => toggleExpanded(e)}>
              {isNil(tableConfig.ExpandIcon) && (isExpanded.value ? <KeyboardArrowDown /> : <KeyboardArrowRight />)}

              {!isNil(tableConfig.ExpandIcon) && !isExpanded.value && (
                <NGIcon config={tableConfig.ExpandIcon} context={context} />
              )}

              {!isNil(tableConfig.CollapseIcon) && isExpanded.value && (
                <NGIcon config={tableConfig.CollapseIcon} context={context} />
              )}
            </IconButton>
          </TableCell>
        )}
        <NGLayoutItem config={item} context={context} />
      </TableRow>
      {tableConfig.Expandable && details && isExpanded.value && (
        <NGTableCollapsableRow item={details} context={context} />
      )}
    </>
  );
}
