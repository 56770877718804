import { SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import CommentIcon from "@mui/icons-material/Comment";
import { Box, Paper } from "@mui/material";
import "./NGReviewDialog.css";
import { setupHandlers, setupLocalState, updateItemContext } from "../../library/dataService";
import { signal, useSignal } from "@preact/signals-react";
import { INGReviewDialogProps, RuntimeContext } from "../../library/NGFieldExtensions";
import { IActionTrigger, ReviewDialogData } from "../../../resolvers-types";
import { InfoOutlined, RateReviewOutlined, ThumbDownAltOutlined, ThumbUpAltOutlined } from "@mui/icons-material";
import NGAudioRecorder from "../NGAudioRecorder/NGAudioRecorder";
import HistoryIcon from "@mui/icons-material/History";
import { isNil } from "lodash-es";
import { getTestId } from "../../library/utils";

export default function NGReviewDialog({ config, context }: INGReviewDialogProps) {
  const currentContext = updateItemContext(context, config);
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Enabled: useSignal(config.Enabled ?? true),
      Data: useSignal(config.Data ?? {}),
    },
    currentContext
  );

  const handlers = setupHandlers(config, currentContext);

  function isReactionLike() {
    return local.Data.value.Reaction?.toLocaleLowerCase() == "like";
  }
  function isReactionDislike() {
    return local.Data.value.Reaction?.toLocaleLowerCase() == "dislike";
  }
  function onReactionLikeClick(e) {
    local.Data.value = { ...local.Data.value, Reaction: "Like" };

    if (handlers["onReviewAction"]) {
      handlers["onReviewAction"](e, local.Data.value);
    }
  }

  function onReactionDislikeClick(e) {
    local.Data.value = { ...local.Data.value, Reaction: "Dislike" };
    if (handlers["onReviewAction"]) {
      handlers["onReviewAction"](e, local.Data.value);
    }
  }

  return (
    <>
      {local.Visible.value && (
        <Paper className="container" elevation={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {config.ShowReaction && (
              <>
                <Button
                  data-testid={getTestId(config, "ThumbsUp")}
                  className="button"
                  startIcon={<ThumbUpAltOutlined />}
                  color={isReactionLike() ? "success" : "primary"}
                  onClick={onReactionLikeClick}
                />
                <Button
                  data-testid={getTestId(config, "ThumbsDown")}
                  className="button"
                  startIcon={<ThumbDownAltOutlined />}
                  color={isReactionDislike() ? "error" : "primary"}
                  onClick={onReactionDislikeClick}
                />
              </>
            )}
            {config.ShowTextNotes && (
              <Button
                data-testid={getTestId(config, "CreateTextNote")}
                className="button"
                startIcon={<RateReviewOutlined />}
                onClick={(e) => {
                  if (handlers["onCreateTextNote"]) handlers["onCreateTextNote"](e, local.Data.value);
                }}
              />
            )}
            {config.ShowVoiceNotes && (
              <NGAudioRecorder
                config={{
                  DestinationBucket: "reviews",
                  DisplayPlayerAfterRecording: false,
                  Actions: [
                    {
                      Trigger: "onStopRecording",
                      preHandler: (
                        handlerName: string,
                        action: IActionTrigger,
                        e: SyntheticEvent,
                        data: object,
                        context: RuntimeContext
                      ) => {
                        console.log("onStopRecording", e, data, context);

                        // data has the file name
                        if (isNil(data)) return;

                        local.Data.value.FileName = data;

                        if (handlers["onCreateVoiceNote"]) handlers["onCreateVoiceNote"](e, local.Data.value);
                      },
                    },
                  ],
                }}
                context={currentContext}
              />
            )}
            {config.ShowComments && (
              <>
                <Button
                  className="button"
                  startIcon={<CommentIcon />}
                  data-testid={getTestId(config, "ShowComments")}
                />
              </>
            )}
            {config.ShowInfo && (
              <Button
                data-testid={getTestId(config, "ShowInfo")}
                className="button"
                startIcon={<InfoOutlined />}
                onClick={(e) => {
                  if (handlers["onShowInfo"]) handlers["onShowInfo"](e, local.Data.value);
                }}
              />
            )}
            <Button
              data-testid={getTestId(config, "ShowHistory")}
              className="button"
              startIcon={<HistoryIcon />}
              onClick={(e) => {
                if (handlers["onShowHistory"]) handlers["onShowHistory"](e, local.Data.value);
              }}
            />
          </Box>
          <span className="provideFeedbackText" data-testid={getTestId(config, "FeedbackText")}>
            Feedback for {local.Data.value.Item?.Name}{" "}
          </span>
        </Paper>
      )}
    </>
  );
}
