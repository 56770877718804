import { useSignal } from "@preact/signals-react";
import { ExpandMore, VerticalAlignCenter, Expand } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { INGDimensionFieldProps } from "../../library/NGFieldExtensions";
import _ from "lodash-es";
import React, { useCallback, useEffect } from "react";

function DimensionField({
  value,
  label,
  field,
  onValueChange,
  sizeField,
  valueType,
  styles = { width: "75%" },
  styleSelected = {},
  fullWidth
}: INGDimensionFieldProps) {
  const anchorEl = useSignal<HTMLElement | null>(null);
  const showMinField = useSignal(false);
  const showMaxField = useSignal(false);
  const menuOpen = Boolean(anchorEl.value);
  const widthField = field === "width";

  const updateMinMaxFields = useCallback(() => {
    if (Object.keys(styleSelected).length > 0 && (field === "width" || field === "height")) {
      if (!showMaxField.value) {
        showMaxField.value = !!styleSelected[`max${_.capitalize(field)}`];
      }
      if (!showMinField.value) {
        showMinField.value = !!styleSelected[`min${_.capitalize(field)}`];
      }
    }
  }, [styleSelected]);

  useEffect(() => {
    updateMinMaxFields();
  }, [updateMinMaxFields]);

  const handleMenuItemClick = (e: React.SyntheticEvent<HTMLElement>, value: string) => {
    anchorEl.value = null;

    if (value === "min") {
      if (showMinField.value) {
        onValueChange(e, { [`min${_.capitalize(field)}`]: undefined });
      }
      showMinField.value = !showMinField.value;
    } else {
      if (showMaxField.value) {
        onValueChange(e, { [`max${_.capitalize(field)}`]: undefined });
      }
      showMaxField.value = !showMaxField.value;
    }
  };

  const handleCloseMenu = () => {
    anchorEl.value = null;
  };

  const handleOpenMenu = (e) => {
    //TODO: Fix it and change it to open it from the TextField and not the Icon
    anchorEl.value = e.target;
  };

  const options = [
    {
      Text: `${showMinField.value ? "Remove" : "Add"} min ${field}`,
      Value: "min",
      Icon: <VerticalAlignCenter className={`prop-editor-icon-color ${widthField ? "icon-rotate-90" : ""}`} />,
    },
    {
      Text: `${showMaxField.value ? "Remove" : "Add"} max ${field}`,
      Value: "max",
      Icon: <Expand className={`prop-editor-icon-color ${widthField ? "icon-rotate-90" : ""}`} />,
    },
  ];

  return (
    <Stack sx={{ flexDirection: "column", alignItems: "flex-start", gap: "0.2rem", width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: fullWidth ? "flex-start" : "space-between", width: "100%" }}>
        {!!label && (
          <Typography
            className="control-label"
            width="2rem"
            paddingLeft={typeof label === "string" ? "4px" : ""}
          >
            {label}
          </Typography>
        )}
        <TextField
          data-testid={`${field}-prop-editor-input`}
          className="dimension-editor-input"
          fullWidth
          value={value}
          onChange={(e) =>
            onValueChange(
              e,
              { ...{ [field]: e.target.value }, ...(field === "width" ? { alignSelf: undefined } : {}) },
              true
            )
          }
          variant="outlined"
          type={valueType}
          sx={styles}
          InputLabelProps={{ shrink: false }} // Prevents the label from being displayed even as an empty space
          InputProps={{
            ...(sizeField && {
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <ExpandMore onClick={handleOpenMenu} />
                </InputAdornment>
              ),
            }),
          }}
        />
        <Menu
          id={`${field}-menu`}
          anchorEl={anchorEl.value}
          open={menuOpen}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ marginTop: '10px', marginLeft: '10px' }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} onClick={(e) => handleMenuItemClick(e, option.Value)}>
              <ListItemIcon>{option.Icon}</ListItemIcon>
              <ListItemText>{option.Text}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {showMinField.value && (
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "0.5rem", width: "100%" }}>
          <VerticalAlignCenter className={`prop-editor-icon-color ${widthField ? "icon-rotate-90" : ""}`} />
          <TextField
            data-testid={`min-${field}-prop-editor-input`}
            className="dimension-editor-input"
            fullWidth
            value={styleSelected[`min${_.capitalize(field)}`]}
            onChange={(e) => onValueChange(e, { [`min${_.capitalize(field)}`]: e.target.value })}
            variant="outlined"
            type="string"
            InputLabelProps={{ shrink: false }}
            sx={{ flex: 1 }}
          />
        </Stack>
      )}
      {showMaxField.value && (
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "0.5rem", width: "100%" }}>
          <Expand className={`prop-editor-icon-color ${widthField ? "icon-rotate-90" : ""}`} />
          <TextField
            data-testid={`max-${field}-prop-editor-input`}
            className="dimension-editor-input"
            fullWidth
            value={styleSelected[`max${_.capitalize(field)}`]}
            onChange={(e) => onValueChange(e, { [`max${_.capitalize(field)}`]: e.target.value })}
            variant="outlined"
            type="string"
            InputLabelProps={{ shrink: false }}
            sx={{ flex: 1 }}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default DimensionField;
