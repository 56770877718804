import { isNil, reject } from "lodash-es";
import { log } from "../../library/logger";
import { camelCaseToTitleCase, generateUID, isNullOrEmpty, makeSlug } from "../../library/utils";
import {
  AppFieldList,
  extractFieldList,
  getFieldsFromServiceNameInOpenAPI,
  getPathFromVerb,
  resolveRefs,
} from "./openApiParser";
import sampleOpenApi from "./openapi-watchlist.json";
import { Component, LayoutItem, Service, SimpleContainer, SimpleContainerBindings } from "../../../resolvers-types";

type IGenerationMethod = (inputData: any, options: any | null) => Promise<any>;

export const generationMethods: { [key: string]: IGenerationMethod } = {
  GenerateDataGrid: generateDataGrid,
  GenerateDisplayComponent: generateDisplayComponent,
  GenerateList: generateList,
};

async function getServiceDefinition(serviceName: string, sampleService: string) {
  return resolveRefs(sampleOpenApi as any, sampleOpenApi as any);
}

function getSampleFields(): AppFieldList {
  const f = {
    Id: {
      type: "string",
      format: "uuid",
      description: "The unique identifier of the watchlist",
    },
    Name: {
      type: "string",
      description: "The name of the watchlist",
    },
    Tickers: {
      type: "array",
      items: {
        type: "string",
      },
      description: "A list of items in the watchlist",
    },
  };
  return {} as AppFieldList; // TODO - VS
}

async function generateDataGrid(inputData: any, options: any | null): Promise<any> {
  const tag = "generateDataGrid";

  if (isNullOrEmpty(inputData.ComponentName)) reject("InputData.ComponentName is null or empty");

  const fields: AppFieldList = isNullOrEmpty(inputData.ServiceName)
    ? getSampleFields()
    : await getFieldsFromAPI(inputData, "query", options);

  const list = getBaseDataGrid(inputData.ComponentName, fields, inputData.ServiceName);
  const queryService = getServiceQuery(inputData.ComponentName, inputData.ServiceName, "query", options?.Operation);
  const services = [queryService];
  const controls = [list as LayoutItem];

  if (options?.MasterDetail == "ViewDetails") {
    const fieldsForDisplay: AppFieldList = isNullOrEmpty(inputData.ServiceName)
      ? getSampleFields()
      : await getFieldsFromAPI(inputData, "query", options);

    const app: SimpleContainer = getDisplayApp(inputData.ComponentName, fieldsForDisplay, inputData.ServiceName);
    const getService = getServiceGet(inputData.ComponentName, inputData.ServiceName, "get", options?.Operation);

    if (isNil(app.Bindings)) app.Bindings = {};

    app.Bindings.Visible = `!isEmpty(${getStateVariable(inputData.ComponentName, "", inputData.ServiceName)})`;

    services.push(getService);
    controls.push(app as LayoutItem);
  }

  const component: Component | null = getComponentWithControl(inputData.ComponentName, controls, services, options);

  return component;
}

//
async function generateList(inputData: any, options: any | null): Promise<any> {
  const tag = "generateList";

  if (isNullOrEmpty(inputData.ComponentName)) reject("InputData.ComponentName is null or empty");

  const fields: AppFieldList = isNullOrEmpty(inputData.ServiceName)
    ? getSampleFields()
    : await getFieldsFromAPI(inputData, "query", options);

  const modalPopupId = options?.MasterDetail == "ViewDetails" ? generateUID() : null;

  const list = getRepeater(inputData.ComponentName, fields, inputData.ServiceName, modalPopupId);
  const queryService = getServiceQuery(inputData.ComponentName, inputData.ServiceName, "query", options);
  const services = [queryService];
  const controls = [list as LayoutItem];

  let modalPopup: any = null;
  let component2: Component | null = null;

  if (options?.MasterDetail == "ViewDetails" && !isNil(modalPopupId)) {
    if (isNullOrEmpty(inputData.DetailsComponentName))
      inputData.DetailsComponentName = `${inputData.ComponentName}Details`;

    component2 = await generateComponentForViewingDetails(inputData, {
      ...options,
      AlwaysVisible: true,
      BindThroughState: true,
    });

    modalPopup = getModalPopupForComponent(modalPopupId, component2, inputData.ComponentName, inputData.ServiceName);
  }

  const component: Component | null = getComponentWithControl(inputData.ComponentName, controls, services, options);

  if (!isNil(modalPopup) && !isNil(component)) component.ModalPopups = [modalPopup];

  return [component, component2];
}

function getModalPopupForComponent(modalPopupId: string, component: Component | null, entityName, serviceName) {
  return {
    __typename: "Dialog",
    Id: modalPopupId,
    Title: camelCaseToTitleCase(entityName),
    ContentClasses: ["dialog-no-padding"],
    FullScreen: true,
    ContentContainer: {
      __typename: "SimpleContainer",
      Id: `${modalPopupId}_Container`,
      Items: [
        {
          __typename: "Reference",
          Id: `${modalPopupId}_Reference`,
          ReferenceType: "Component",
          ReferenceId: component?.Id,
          Inputs: {
            [`${getStateVariable(entityName, "", serviceName, "")}`]: getStateVariable(
              entityName,
              "",
              serviceName,
              "Parent"
            ),
          },
        },
      ],
    },
  };
}

async function generateComponentForViewingDetails(inputData: any, options: any) {
  const fieldsForDisplay: AppFieldList = isNullOrEmpty(inputData.ServiceName)
    ? getSampleFields()
    : await getFieldsFromAPI(inputData, "query", options);

  const app: SimpleContainer = getDisplayApp(inputData.ComponentName, fieldsForDisplay, inputData.ServiceName, options);
  const getService = getServiceGet(inputData.ComponentName, inputData.ServiceName, "get", options?.Operation);

  if (isNil(app.Bindings)) app.Bindings = {};

  if (options?.AlwaysVisible !== true)
    app.Bindings.Visible = `!isEmpty(${getStateVariable(inputData.ComponentName, "", inputData.ServiceName)})`;

  const services = [getService];
  const controls = [app as LayoutItem];

  const component: Component | null = getComponentWithControl(
    inputData.DetailsComponentName,
    controls,
    services,
    options
  );

  if (!isNil(component)) {
    component.Tags = [];

    component.Inputs = {
      [`${getStateVariable(inputData.ComponentName, "", inputData.ServiceName, "")}`]: {},
    };
  }

  return component;
}

async function generateDisplayComponent(inputData: any, options: any | null): Promise<any> {
  const tag = "generateDisplayApp";

  if (isNullOrEmpty(inputData.ComponentName)) reject("InputData.ComponentName is null or empty");

  const fields: AppFieldList = isNullOrEmpty(inputData.ServiceName)
    ? getSampleFields()
    : await getFieldsFromAPI(inputData, "get", options);

  const app = getDisplayApp(inputData.ComponentName, fields, inputData.ServiceName);
  const getService = getServiceGet(inputData.ComponentName, inputData.ServiceName, "get", options?.Operation);

  const component: Component | null = getComponentWithControl(
    inputData.ComponentName,
    [app as LayoutItem],
    [getService],
    options
  );

  return component;
}

function getComponentWithControl(
  componentName: string,
  controls: LayoutItem[],
  services: Service[],
  options: any
): Component | null {
  const component: Component = getBaseComponent(componentName, options);

  if (!component || !component.ComponentContainer || !component.ComponentContainer.Items) return null;

  component.ComponentContainer.Items = controls;
  (component as any).Services = services;

  return component;
}

async function getFieldsFromAPI(inputData: any, verb: string, options: any) {
  //const api = await getServiceDefinition(inputData.ServiceName, inputData.SampleService);

  const fields = await getFieldsFromServiceNameInOpenAPI(sampleOpenApi as any, inputData.ServiceName, verb);

  const path = getPathFromVerb(inputData.ServiceName, verb, options?.Operation);

  if (isNullOrEmpty(path))
    reject(`Path not found for service '${inputData.ServiceName}', verb '${verb}', operation '${options?.Operation}`);

  //const fields = extractFieldList(api as any, path);
  return fields;
}

function getServiceQuery(entityName: string, serviceName: string, verb: string, options: any): Service {
  let fullName = getFullServiceName(serviceName, verb);
  const operation = options?.Operation;
  if (!isNullOrEmpty(operation)) fullName += `.${operation}`;

  return {
    Type: "Internal",
    Name: fullName,
    Id: fullName,
    Service: fullName,
    Fields: options?.Fields ?? [],
    Bindings: {
      [`State.${entityName}List`]: "Result.Items",
    },
    UseSampleData: false,
  };
}

function getFullServiceName(serviceName: string, verb: string) {
  return `${serviceName}.${verb}`;
}

function getServiceGet(entityName: string, serviceName: string, verb: string, operation: string): Service {
  let fullName = getFullServiceName(serviceName, verb);
  if (!isNullOrEmpty(operation)) fullName += `.${operation}`;

  return {
    Type: "Internal",
    Name: fullName,
    Id: fullName,
    Service: fullName,
    Fields: [], //TODO VS - Add fields for query
    Trigger: "Action",
    Bindings: {
      [`State.${entityName}`]: "Result",
    },
    UseSampleData: false,
  };
}

export function getBaseComponent(componentName: string, options: any): Component {
  return {
    __typename: "Component",
    Id: makeSlug(componentName),
    Name: componentName,
    Tags: options?.Tags ?? ["new component"],
    HideTitle: true,
    Icon: { IconName: "HomeSharp" },
    ComponentContainer: {
      __typename: "SimpleContainer",
      Id: generateUID(),
      Items: [],
      IgnoreLayout: true,
      Style: {
        display: "flex",
        flexDirection: "column",
      },
    },
  };
}

function AddOptionsToList() {
  const listButtons = [
    {
      Id: generateUID(),
      Label: "Add",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            Id: generateUID(),
            FirstCommandId: "0x01",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0x01",
                Instruction: {
                  Id: generateUID(),
                  Name: "OpenModalPopup",
                },
                Parameters: [
                  { Name: "Title", Value: "Create New ${entityName}" },
                  { Name: "ModalPopupId", Value: "{{ modalPopupId }}" },
                ],
              },
            ],
          },
        },
      ],
    },
  ];

  const listRowButtonDelete = {
    __typename: "ListColumn",
    Id: generateUID(),
    HeaderName: "",
    Name: "DeleteButton",
    MinWidth: 150,
    Visible: true,
    Button: {
      __typename: "Button",
      Id: generateUID(),
      StartIcon: { IconName: "Delete" },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            Id: generateUID(),
            FirstCommandId: "0x01",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0x01",
                Instruction: {
                  Id: generateUID(),
                  Name: "ShowMessage",
                },
                Parameters: [
                  { Name: "Title", Value: "Delete {{ camelCaseEntityName }}" },
                  {
                    Name: "Message",
                    Value: "Are you sure you want to delete this {{ camelCaseEntityName }}?",
                  },
                  { Name: "ShowOkButton", Value: true },
                  { Name: "ShowCancelButton", Value: true },
                ],
                NextCommandIdOnSuccess: "0x02",
              },
              {
                Id: "0x02",
                Instruction: {
                  Name: "CallService",
                },
                Parameters: [{ Name: "ServiceName", Value: "${entityName}Delete" }],
                NextCommandIdOnFailure: "0x03",
                NextCommandIdOnSuccess: "0x04",
              },
              {
                Id: "0x03",
                Instruction: {
                  Id: generateUID(),
                  Name: "ShowMessage",
                },
                Parameters: [
                  { Name: "Message", Value: "Delete failed" },
                  { Name: "ShowOkButton", Value: true },
                ],
              },
              {
                Id: "0x04",
                Instruction: {
                  Id: generateUID(),
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.{{ entityName }}": "{}",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "0x05",
              },
              {
                Id: "0x05",
                Instruction: {
                  Name: "CallService",
                },
                Parameters: [{ Name: "ServiceName", Value: "${entityName}Query" }],
              },
            ],
          },
        },
      ],
    },
  };
}

function getBaseDataGrid(entityName: string, fields: AppFieldList, serviceName: string) {
  const onRowClick = {
    Trigger: "onRowClick",
    CommandSet: {
      Id: generateUID(),
      FirstCommandId: "1",
      ExecuteCommandsInParallel: false,
      Commands: [
        {
          Id: "1",
          Instruction: {
            Name: "ClearForm",
          },
          Parameters: [
            {
              Name: "Form",
              Value: `${entityName}EditForm`,
            },
          ],
          NextCommandIdOnSuccess: "2",
        },
        {
          Id: "2",
          Instruction: {
            Id: generateUID(),
            Name: "SetState",
          },
          Parameters: [
            {
              Name: "Bindings",
              Value: {
                [getStateVariable(entityName, "", serviceName)]: "Event",
              },
            },
          ],
        },
      ],
    },
  };

  const props: (keyof AppFieldList)[] = Object.keys(fields) as (keyof AppFieldList)[];
  const listColumns = props.map((name) => {
    const o: any = {
      __typename: "ListColumn",
      Id: generateUID(),
      HeaderName: `${name}`,
      Name: `${name}`,
    };

    if (name == "Id") o.IsPrimaryKey = true;

    return o;
  });

  const list = {
    __typename: "List",
    Id: generateUID(),
    Toolbar: {
      ShowExport: true,
      ShowFilter: true,
    },
    ShowFloatingFilter: true,
    ShowGroupingPanel: "always",
    ShowQuickFilter: true,
    EnableSideBar: true,
    Actions: [onRowClick],
    ListColumns: listColumns,
    Rows: [],
    Bindings: {
      Rows: `State.${entityName}List`,
      Loading: `State.NGService.Query${entityName}.Loading`,
    },
  };

  const title = camelCaseToTitleCase(entityName) + " List";
  const d = containerWithTitle(title, [list] as LayoutItem[]);
  return d as SimpleContainer;
}

function getRepeater(entityName: string, fields: AppFieldList, serviceName: string, modalPopupId: string | null) {
  const props: (keyof AppFieldList)[] = Object.keys(fields) as (keyof AppFieldList)[];

  const onClick = {
    Trigger: "onClick",
    CommandSet: {
      Id: generateUID(),
      FirstCommandId: "1",
      ExecuteCommandsInParallel: false,
      Commands: [
        {
          Id: "1",
          Instruction: {
            Id: generateUID(),
            Name: "SetState",
          },
          Parameters: [
            {
              Name: "Bindings",
              Value: {
                [getStateVariable(entityName, "", serviceName)]: "Form",
              },
            },
          ],
        },
      ],
    },
  };

  if (!isNil(modalPopupId)) {
    onClick.CommandSet.Commands.push({
      Id: "2",
      Instruction: {
        Id: generateUID(),
        Name: "OpenModalPopup",
      },
      Parameters: [
        { Name: "Title", Value: "Details" },
        { Name: "ModalPopupId", Value: modalPopupId },
      ],
    });
    onClick.CommandSet.Commands[0].NextCommandIdOnSuccess = "2";
  }

  const itemCount = props.length;
  const items = props.map((name) => {
    const o: any = {
      __typename: "Label",
      Id: generateUID(),
      Name: `${name}`,
      Style: {
        width: `${100 / itemCount}%`,
      },
      Classes: ["table-cell"],
    };
    return o;
  });

  const repeater = {
    __typename: "Repeater",
    Id: generateUID(),
    Bindings: {
      Rows: `State.${entityName}List`,
      Loading: `State.NGService.Query${entityName}.Loading`,
    },
    Variant: "Flex",
    Items: [
      {
        __typename: "SimpleContainer",
        Id: generateUID(),
        Items: items,
        IgnoreLayout: true,
        Actions: [onClick],
        Style: {
          display: "flex",
          flexDirection: "row",
          minWidth: "20px",
          minHeight: "20px",
          justifyContent: "space-between",
        },
      },
    ],
  };

  const title = camelCaseToTitleCase(entityName) + " List";
  const d = containerWithTitle(title, [repeater] as LayoutItem[]);
  return d as SimpleContainer;
}

function getStateVariable(entityName: string, type: string, serviceName?: string, root = "State") {
  const last = isNullOrEmpty(entityName) ? serviceName : entityName;

  if (isNullOrEmpty(root)) return `${type}${last}`;
  else return `${root}.${type}${last}`;
}

function getDisplayApp(entityName: string, fields: AppFieldList, serviceName: string, options: any): SimpleContainer {
  const fieldPairs = Object.keys(fields).map((name) => {
    const f: any = {
      Id: generateUID(),
      __typename: "Label",
      Classes: ["table-cell"],
      Name: name,
      //FormatName: "wholeNumberWithCommas",
    };

    if (options?.BindThroughState)
      f.Bindings = {
        Value: `${getStateVariable(entityName, "", serviceName)}.${name}`,
      };
    else f.Name = name;

    const o: any = {
      __typename: "SimpleContainer",
      Id: generateUID(),
      Items: [
        {
          Id: generateUID(),
          __typename: "Label",
          Value: camelCaseToTitleCase(name),
          Classes: ["table-cell"],
        },
        f,
      ],
      Classes: ["bottomBorder"],
      Style: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "",
        justifyContent: "space-between",
      },
      IgnoreLayout: true,
    };

    return o;
  });

  const title = camelCaseToTitleCase(entityName) + " Details";
  const d = containerWithTitle(title, fieldPairs as LayoutItem[]);

  if (isNil(d.Bindings)) d.Bindings = {};
  (d.Bindings as SimpleContainerBindings).Data = getStateVariable(entityName, "", serviceName);

  return d as SimpleContainer;
}

function containerWithTitle(title: string, items: LayoutItem[]): SimpleContainer {
  return {
    __typename: "SimpleContainer",
    Id: generateUID(),
    Items: [
      {
        Id: generateUID(),
        __typename: "Label",
        Value: title,
        Classes: ["field-padding-top", "section-title"],
      },
      ...items,
    ],
    Classes: [],
    Style: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "1px",
    },
    IgnoreLayout: true,
  };
}
