import NGPage from "../../generators/NGPage";
import { Layout } from "../../gql/graphql";
import { INGDrawerProps } from "../../library/NGFieldExtensions";
import { Drawer as MUIDrawer } from "@mui/material";

export default function NGDrawer({ config, context }: INGDrawerProps) {
  return (
    <>
      <MUIDrawer
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: config.Width,
            boxSizing: "border-box",
            height: config.Height,
            marginLeft: config.MarginLeft,
            marginRight: config.MarginRight,
            marginTop: config.MarginTop,
            marginBottom: config.MarginBottom,
          },
          display: { xs: "none", sm: "flex" },
        }}
        anchor={config.Anchor as any}
        hideBackdrop={config.HideBackdrop as boolean}
        open={config.Open as boolean}
        transitionDuration={config.TransitionDuration as any}
        variant={config.Variant as any}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <NGPage config={config.Content} layout={config.Content.Layout as Layout} context={context} />
      </MUIDrawer>
    </>
  );
}
