import Stack from "@mui/material/Stack";
import { INGFlexLayoutProps } from "../library/NGFieldExtensions";
import { getStyleObject, getClassName, keyByRec } from "../library/utils";
import { useSignal } from "@preact/signals-react";
import { isNil, merge } from "lodash-es";
import { FlexLayout, LayoutItem } from "../../resolvers-types";
import _ from "lodash";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { renderAsLayoutItem, renderAsLayoutItemCollection } from "./LayoutHelpers";

export default function NGFlexLayout({ config, context, layoutItem, children }: INGFlexLayoutProps) {
  const itemMap = keyByRec((layoutItem as any).Items, "Items", "Id", {});
  // const id = config.Id ?? layoutItem.Id;
  const id = layoutItem.Id;

  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? (layoutItem as any).Style ?? {}),
      Classes: useSignal(config.Classes ?? (layoutItem as any).Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  function itemWithLayoutStyle(item: any) {
    if (isNil(config.ItemStyles)) return;

    if (isNil(config.ItemStyles[item.Id])) return;

    if (item.__typename.includes("Container")) {
      item.Layout.Style = getStyleObject(config.ItemStyles[item.Id], item.Layout.Style);
    } else {
      item.Style = getStyleObject(config.ItemStyles[item.Id], item.Style);
    }
  }

  return (
    <>
      {local.Visible.value && (
        <Stack
          // ref={dropRef}
          data-testid={id}
          // data-type={config.__typename}
          data-type={layoutItem.__typename}
          id={getHTMLId(layoutItem, config)}
          direction={config.Direction ?? ("column" as any)}
          flexWrap={config.FlexWrap as any}
          spacing={config.Spacing || 0}
          className={getClassName(local.Classes)}
          columnGap={config.ColumnGap as any}
          rowGap={config.RowGap as any}
          justifyContent={config.JustifyContent as any}
          alignContent={config.AlignContent as any}
          alignItems={config.AlignItems as any}
          alignSelf={config.AlignSelf as any}
          sx={local.Style.value}
          {...handlers}
          // sx={getsxObject(flexLayout.Style)}
        >
          {!children && !config.Items && renderAsLayoutItem(layoutItem, itemWithLayoutStyle, context, handlers)}

          {!children && renderAsLayoutItemCollection(config, itemMap, layoutItem, itemWithLayoutStyle, context)}

          {children}
        </Stack>
      )}
    </>
  );
}
function getHTMLId(layoutItem: LayoutItem, flexLayout: FlexLayout): any {
  return (layoutItem as any).SetAsBookmark
    ? (layoutItem as any).Id
    : flexLayout.SetAsBookmark
      ? flexLayout.Id
      : undefined;
}
