import MenuItem from "@mui/material/MenuItem";
import { INGFeatureProps } from "../../library/NGFieldExtensions";
import { NavLink } from "react-router-dom";
import { getTestId, getURLForFeature } from "../../library/utils";
import { setupHandlers } from "../../library/dataService";

export default function NGFeature({ config, context, handleClose }: INGFeatureProps) {
  const handlers = setupHandlers(config, context);

  return handlers["onClick"] || !config.AllowNavigation ? (
    <MenuItem
      data-testid={getTestId(config)}
      color="inherit"
      key={config.Id}
      onClick={(e) => {
        console.log("onClick", e);
        if (handlers["onClick"] && config.AllowNavigation) {
          handlers["onClick"]();
        }
        handleClose();
      }}
    >
      {config.Label}
    </MenuItem>
  ) : (
    <MenuItem
      data-testid={getTestId(config)}
      color="inherit"
      key={config.Id}
      component={NavLink}
      to={getURLForFeature(config)}
      onClick={handleClose}
    >
      {config.Label}
    </MenuItem>
  );
}
