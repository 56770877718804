import { INGPageTemplateProps as INGPageTemplateProps } from "../../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import NGAppBar from "../NGAppBar/NGAppBar";
import NGDrawer from "../NGDrawer/NGDrawer";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";

export default function NGPageTemplate({ config, context, menu, children }: INGPageTemplateProps) {
  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  if (config.Header) config.Header.Anchor = "top";
  if (config.Footer) config.Footer.Anchor = "bottom";

  const drawerWidth = 0;
  const appBarHeight = "64px";
  const appBarHeightXS = "56px";

  if (!isNil(config.LeftSidebar)) config.LeftSidebar.MarginTop = appBarHeight;

  return (
    <>
      <CssBaseline />
      {config.TopAppBar && (
        <NGAppBar
          config={config.TopAppBar}
          context={context}
          menu={menu}
          handleClose={() => {
            console.log("Menu close");
          }}
        />
      )}
      {config.LeftSidebar && <NGDrawer config={config.LeftSidebar} context={context} />}
      <Box
        component="main"
        sx={{
          width: "100vw", //`calc(100vw - ${drawerWidth}px)` },
          paddingLeft: { sm: `${drawerWidth}px` },
          paddingTop: { xs: appBarHeightXS, sm: appBarHeight },
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}

// TODO: Add other sidebars, footers, etc. as needed
/*       <>
        { config.Header && <NGDrawer config={config.Header} /> }
        {
            config.TopAppBar && 
            <NGAppBar menu={menu} handleClose={ () => { console.log("Menu close") } }  />  
        }
        { config.LeftSidebar && <NGDrawer config={config.LeftSidebar} /> }
        <main>
        <div style={{ width: "100%", height: "100%", padding: "10px" }}>

                    {children}
        </div>
        </main>
        { config.Footer && <NGDrawer config={config.Footer} /> }
        {
            config.BottomAppBar && 
            <NGAppBar menu={menu} handleClose={ () => { console.log("Menu close") } } />  
        }
        </>
        */
