import { GridStackWidget } from "gridstack";
import { GridStackLayout } from "../../resolvers-types";

export function getGridStackLayoutFromNode(node: GridStackWidget, id: string): GridStackLayout {
  return {
    X: node.x,
    Y: node.y,
    W: node.w,
    H: node.h,
    MaxW: node.maxW,
    MinW: node.minW,
    MaxH: node.maxH,
    MinH: node.minH,
    AutoPosition: node.autoPosition,
    FitToContent: node.fitToContent,
    LayoutItem: { Id: id },
  };
}

export function getNodeFromGridStackLayout(
  layout: GridStackLayout,
  inDesignMode: boolean
): {
  node: GridStackWidget;
  id: string;
} {
  return {
    node: {
      w: layout.W as number,
      h: layout.H as number,
      x: layout.X as number,
      y: layout.Y as number,
      maxW: layout.MaxW as number,
      minW: layout.MinW as number,
      maxH: layout.MaxH as number,
      minH: layout.MinH as number,
      autoPosition: layout.AutoPosition as boolean,
      fitToContent: layout.FitToContent as boolean,
      locked: !inDesignMode,
      noResize: !inDesignMode,
      noMove: !inDesignMode,
    },
    id: layout?.LayoutItem?.Id as string,
  };
}

export function pixelsToGridUnits(pixelX, pixelY, gridWidth = window.innerWidth) {
  const columns = 24;
  const cellHeight = 2; // Height of each grid cell in pixels

  const columnWidth = gridWidth / columns;

  // TODO: this needs to be more consistent
  const gridX = Math.floor(pixelX / columnWidth);
  const gridY = Math.floor(pixelY / cellHeight);

  // console.log(
  //   "~~totalGridHeight, gridWidth, columnWidth, pixelX, pixelY, gridX, gridY:",
  //   window.innerHeight,
  //   window.innerWidth,
  //   columnWidth,
  //   pixelX,
  //   pixelY,
  //   gridX,
  //   gridY
  // );

  return { gridX, gridY };
}
