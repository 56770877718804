import * as graphql from "../../../resolvers-types";

export const serviceNotesHistory: graphql.Service = {
  Type: "Internal",
  Name: "review-notes-history",
  Service: "reviews.history.get",
  Trigger: "Action",
  UseSampleData: false,
  //Headers: null,
  Fields: [
    {
      Name: "Filter",
      Value: {},
      Bindings: {
        Value: "Event.Item",
        "Value.Reviewer": "Global.User.Id", // TODO: this is a hack, need to fix
      },
    },
  ],
  Bindings: {
    "State.ReviewHistory": "Result.ReviewHistory",
  },
  SampleData: {
    ReviewHistory: {
      Item: {
        Id: "12345",
        __typename: "Component",
        SerialNumber: "ABC12345",
      },
      Reviewer: {
        email: "John.Doe@company.com",
        Id: "2232",
        Image: "abc.png",
        Name: "John Doe",
      },
      Reaction: "Like",
      Notes: [
        {
          Id: "1",
          ParentId: null,
          Author: "John.Doe@company.com",
          ReviewDateTime: "2023-12-18T15:30:00Z",
          Text: "I found this application extremely user-friendly and efficient.",
          NoteType: "Audio",
          Audio: "reviews/review1.mp4",
          //"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-9.mp3", // "voice_note_1.mp3",
          Screenshot: "screenshot_1.png",
          Summary: "Positive feedback on the application\u0027s user-friendliness and efficiency.",
          Sentiment: "Positive",
          Excerpts: ["Extremely user-friendly", "Efficient"],
        },
        {
          Id: "2",
          ParentId: "1",
          Author: "John.Doe@company.com",
          ReviewDateTime: "2023-12-18T16:45:00Z",
          NoteType: "Text",
          Text: "It may need changing the color scheme",
          Screenshot: "screenshot_2.png",
          Summary: "Agreement with positive aspects, but with minor bug issues.",
          Sentiment: "Mixed",
          Excerpts: ["Agree with user-friendliness", "Minor bugs"],
        },
      ],
    },
  },
  Id: "review-notes-history",
};

export default serviceNotesHistory;

export const serviceNotesHistoryAdmin: graphql.Service = {
  Type: "Internal",
  Name: "review-notes-history-admin",
  Service: "reviews.history.get",
  Trigger: "Action",
  UseSampleData: false,
  //Headers: null,
  Fields: [
    {
      Name: "Filter",
      Value: {},
      Bindings: {
        Value: "Event.Item",
      },
    },
  ],
  Bindings: {
    "State.ReviewHistory": "Result.ReviewHistory",
  },
  Id: "review-notes-history",
};
