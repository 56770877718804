import { Box, Button, Popover, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, KeyboardEvent, useEffect } from "react";
import { RGBColor, SketchPicker } from "@vitor-jbi/react-color";
import { debounce } from "lodash-es";
import tinycolor from "tinycolor2";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { INGColorPickerProps } from "../../../library/NGFieldExtensions";
import alphaMap from "./NGColorAlphaMap";

const maxAlpha = 100;

const isColorValid = (colorStr: string) => tinycolor(colorStr).isValid();

const getAlphaFromStringHex = (colorString?: string) => {
  if (!colorString) return maxAlpha;
  if (!isColorValid(colorString)) return maxAlpha
  return Math.round(tinycolor(colorString).getAlpha() * 100);
};

const debouncedColorUpdate = debounce((fn, value) => {
  fn(value);
}, 200);

export default function NGColorPicker({ selected, property = "color", label, sx, dataTestId }: INGColorPickerProps) {
  const anchorEl = useSignal<HTMLButtonElement | null>(null);
  const color = useSignal(isColorValid(selected.value[property]) ? selected.value[property] : "");
  const alphaValue = useSignal<number>(getAlphaFromStringHex(selected.value[property]));

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl.value = e.currentTarget;
  };

  const onChange = (newValue: { [property: string]: string }) => {
    if (isColorValid(newValue[property]) || !newValue[property]) {
      const c = { ...selected.value };
      const o1 = newValue[property]
      if (!o1) {
        delete c?.[property]
      } else {
        c[property] = o1;
      }
      selected.value = c;
    }
  };
  const handlePropertyChange = (value: { [property: string]: string }) => debouncedColorUpdate(onChange, value);

  const updateColorValue = (value: string) => {
    return (color.value = value);
  };

  const updateAlphaValue = (value: number) => {
    return (alphaValue.value = value);
  };

  const OnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const inputValue = (e as unknown as ChangeEvent<HTMLInputElement>).target.value;
    if (e.key === "Enter") {
      updateColorValue(inputValue);
      const alpha = Math.round(tinycolor(inputValue).getAlpha() * 100);
      updateAlphaValue(alpha);
      onChange({ [property]: inputValue });
    }
  };

  const handleClose = () => {
    anchorEl.value = null;
  };

  const handleChange = (colorObj: { rgb: RGBColor; hex: string }, e) => {
    const { a } = colorObj?.rgb;
    const alphaNumber = Math.round(a * maxAlpha);
    if (alphaNumber === maxAlpha) {
      handlePropertyChange({ [property]: colorObj.hex });
      updateColorValue(colorObj.hex);
      return;
    }
    const newValue = `${colorObj.hex}${alphaMap[alphaNumber]}`;
    handlePropertyChange({ [property]: newValue });
    updateAlphaValue(alphaNumber);
    updateColorValue(newValue);
  };

  const handleClearValue = () => {
    onChange({ [property]: "" });
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) handleClearValue();
    updateColorValue(e.target.value);
  };

  const handleAlphaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);
    updateAlphaValue(inputValue);
    const currentColorString = tinycolor(String(color.value).slice(0, 7)).toHexString();
    if (inputValue === maxAlpha) {
      updateColorValue(currentColorString);
      return handlePropertyChange({ [property]: currentColorString });
    }
    const newColor = `${currentColorString}${alphaMap[inputValue]}`;
    updateColorValue(newColor);
    handlePropertyChange({ [property]: newColor });
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value) return handleClearValue();
    const inputValue = e.target.value;
    if (isColorValid(inputValue)) {
      onChange({ [property]: inputValue });
      const alpha = Math.round(tinycolor(inputValue).getAlpha() * 100);
      updateAlphaValue(alpha);
      updateColorValue(e.target.value);
    }
  };

  useSignalEffect(() => {
    if (!color.value && isColorValid(selected.value[property])) {
      updateColorValue(selected.value[property] || "");
      updateAlphaValue(getAlphaFromStringHex(selected.value[property]));
    }
  });

  useSignalEffect(() => {
    if (!alphaValue.value && isColorValid(selected.value[property])) {
      updateAlphaValue(getAlphaFromStringHex(selected.value[property]));
    }
  });

  useEffect(() => {
    if (isColorValid(selected.value[property])) {
      updateColorValue(selected.value[property] || "");
      updateAlphaValue(getAlphaFromStringHex(selected.value[property]));
    }
  }, [selected.value[property]]);

  const open = Boolean(anchorEl.value);
  const id = open ? "color-picker-popover" : undefined;

  return (
    <Stack direction="column" sx={{ alignItems: "flex-start", justifyContent: "center", width: "100%", ...sx }} flex="1 1 180px">
      <Typography className="control-label">{label || property}</Typography>
      <Stack className="color-picker-container" direction="row" data-testid={dataTestId}>
        <Button className="color-picker-button" onClick={handleClick} aria-describedby={id}>
          <Box
            sx={{
              backgroundColor: selected.value[property] as string,
              width: "20px",
              height: "20px",
              "&:hover": {
                backgroundColor: selected.value[property] as string,
              },
              minWidth: "unset !important",
            }}
          />
        </Button>
        <TextField
          value={String(color.value || "")}
          onChange={handleFieldChange}
          type="text"
          className="color-picker-text-field"
          onBlur={handleBlur}
          onKeyDown={OnKeyDown}
          sx={{ flex: 2 }}
        />
        <TextField
          type="number"
          className="prop-editor-input color-picker-alpha-field"
          value={alphaValue}
          disabled={!selected.value[property]}
          onChange={handleAlphaChange}
          InputProps={{
            endAdornment: (
              <Typography sx={{ marginRight: "12px", marginTop: "2px", fontSize: "14px", lineHeight: 1 }}>%</Typography>
            ),
          }}
          inputProps={{
            max: maxAlpha,
            min: 0,
          }}
          sx={{ flex: 1 }}
        />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl.value}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SketchPicker color={color.value} onChange={handleChange} />
      </Popover>
    </Stack>
  );
}
