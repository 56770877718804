import { isNil } from "lodash-es";

export class ObjectCache<T> {
  cache: Map<string, T> = new Map<string, T>();
  private maxCacheSize: number;
  private lruQueue: string[] = [];

  constructor(maxCacheSize: number) {
    this.maxCacheSize = maxCacheSize;
  }

  set(key: string, value: T): void {
    // If the cache size exceeds the maximum, evict the least recently used item
    if (this.cache.size >= this.maxCacheSize) {
      const lruKey = this.lruQueue.shift();
      if (lruKey) {
        this.cache.delete(lruKey);
      }
    }

    this.cache.set(key, value);
    this.updateLRU(key);
  }

  get(key: string): T | undefined {
    const value = this.cache.get(key);
    if (value) {
      this.updateLRU(key);
    }
    return value;
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  delete(key: string): void {
    this.cache.delete(key);
    this.removeFromLRU(key);
  }

  clear(): void {
    this.cache.clear();
    this.lruQueue = [];
  }

  private updateLRU(key: string): void {
    // Remove key from the queue if it exists
    this.removeFromLRU(key);

    // Add the key to the end of the queue
    this.lruQueue.push(key);
  }

  private removeFromLRU(key: string): void {
    const index = this.lruQueue.indexOf(key);
    if (index !== -1) {
      this.lruQueue.splice(index, 1);
    }
  }
}

// Usage example:

//   const maxCacheSize = 3;
//   const cache = new ObjectCache<string>(maxCacheSize);

//   cache.set("key1", "value1");
//   cache.set("key2", "value2");
//   cache.set("key3", "value3");
//   cache.set("key4", "value4"); // This will evict "key1" due to the maximum cache size

//   console.log(cache.get("key1")); // Output: undefined (evicted)
//   console.log(cache.get("key2")); // Output: "value2"
