import { useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import "./NGResolutionButtons.css";
import { useEffect, useState } from "react";
import ComputerIcon from "@mui/icons-material/Computer";
import TabletIcon from "@mui/icons-material/Tablet";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import StayCurrentLandscapeIcon from "@mui/icons-material/StayCurrentLandscape";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import isNil from "lodash-es/isNil";
import { Button } from "@mui/material";
import { INGResolutionButtonsProps } from "../../library/NGFieldExtensions";
import { OpenInFull } from "@mui/icons-material";
import { ResolutionButtonsEnum } from './NGResolutionButtonsEnum';

export default function NGResolutionButtons({ config, context }: INGResolutionButtonsProps) {
  const local = setupLocalState(
    config,
    {
      SetWidth: useSignal(config.SetWidth ?? "100%"),
      SetHeight: useSignal(config.SetHeight ?? "90vh"),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  // Add a state to track the selected button
  const [selectedButton, setSelectedButton] = useState(config?.InitialState ?? "StayCurrentPortrait");

  const handlers = setupHandlers(config, context);

  const SetResolutionButton = ({ size, iconName }) => {
    const IconComponent = {
      Home: OpenInFull,
      Computer: ComputerIcon,
      Tablet: TabletIcon,
      TabletMac: TabletMacIcon,
      StayCurrentLandscape: StayCurrentLandscapeIcon,
      StayCurrentPortrait: StayCurrentPortraitIcon,
    }[iconName];

    return (
      <Button
        onClick={(e) => {
          // Update the selected button state
          setSelectedButton(iconName);

          local.SetWidth.value = size.width;
          local.SetHeight.value = size.height;

          if (!isNil((handlers as any).onClick)) {
            (handlers as any).onClick(e, {
              SetWidth: local.SetWidth.value,
              SetHeight: local.SetHeight.value,
            });
          }
        }}
        style={{
          // Apply different color if this button is selected
          backgroundColor: selectedButton === iconName ? "#DCE2E5" : "none",
        }}
        size="small"
      >
        {IconComponent ? <IconComponent /> : null}
      </Button>
    );
  };

  useEffect(() => {
    // Simulate click for the default button
    local.SetWidth.value = config?.InitialState ? ResolutionButtonsEnum[config?.InitialState]?.width : "480px";
    local.SetHeight.value = config?.InitialState ? ResolutionButtonsEnum[config?.InitialState]?.height : "90vh";

    if (!isNil((handlers as any).onClick)) {
      (handlers as any).onClick(null, {
        SetWidth: local.SetWidth.value,
        SetHeight: local.SetHeight.value,
      });
    }
  }, []);

  function convertVhToPixels(vh) {
    if (typeof vh === "string" && vh.includes("vh")) vh = vh.replace("vh", "");
    vh = parseFloat(vh); // Ensure vh is a numeric value
    if (isNaN(vh)) return "0px"; // Return '0px' if vh is not a number

    const viewportHeight = window.innerHeight;
    const pixels = (vh / 100) * viewportHeight;
    return Math.round(pixels) + "px";
  }

  function convertWidthToPixels(widthValue) {
    let pixels;

    if (typeof widthValue === "string" && widthValue.includes("%")) {
      let percentWidth = parseFloat(widthValue.replace("%", ""));
      if (isNaN(percentWidth)) {
        console.warn("Invalid percentWidth input:", percentWidth);
        percentWidth = 100; // Default to 100% if input is invalid
      }
      const parentWidth = document.documentElement.clientWidth;
      pixels = (percentWidth / 100) * parentWidth;
    } else {
      pixels = parseFloat(widthValue);
      if (isNaN(pixels)) {
        console.warn("Invalid pixel input, defaulting to full viewport width:", widthValue);
        pixels = document.documentElement.clientWidth; // Default to full viewport width
      }
    }

    return Math.round(pixels) + "px";
  }

  // TODO: expose the hidden ones as configs for this component, ie ShowTablet, ShowMobile, etc instead of hardcoding them
  return (
    <>
      <div className="button-container" style={local.Style.value}>
        {Object.keys(ResolutionButtonsEnum).map(
          (resolution) =>
            <SetResolutionButton
              key={resolution}
              size={ResolutionButtonsEnum[resolution]}
              iconName={resolution}
            />
        )}
        {
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              paddingRight: "10px",
            }}
          >
            {convertWidthToPixels(local.SetWidth.value)} x {convertVhToPixels(local.SetHeight.value)}
          </span>
        }
        {/* <SetResolutionButton size={{ width: "800px", height: "90vh" }} iconName="TabletMac" /> */}
        {/* <SetResolutionButton size={{ width: "640px", height: "90vh" }} iconName="StayCurrentLandscape" /> */}
      </div>
    </>
  );
}
