import { useEffect } from "react";
import { log } from "../library/logger";

const tag = "action";

export default function useCopyPasteListener(handlers: object, hasSelected = true) {
  const eventfunc = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'c' && handlers["onCopy"]) {
      handlers["onCopy"](new Event("copy"));
      log.info(tag, "~~onCopy handler executed");
    } else if ((event.ctrlKey || event.metaKey) && event.key === 'v' && handlers["onPaste"]) {
      handlers["onPaste"](new Event("paste"));
      log.info(tag, "~~onPaste handler executed");
    }
  }

  useEffect(() => {
    if (hasSelected) {
      document.addEventListener('keydown', eventfunc);
    } else {
      document.removeEventListener("keydown", eventfunc);
    }

    return () => {
      document.removeEventListener("keydown", eventfunc);
    };
  }, [hasSelected]);

  return { copyAndPaste: hasSelected }
}
