import { useRef } from "react";
import { TreeItem, SimpleTreeView as TreeView } from "@mui/x-tree-view";
import { batch, useSignal, useSignalEffect } from "@preact/signals-react";
import { Box } from "@mui/material";
import { isNil, isEmpty } from "lodash";
import { UseTreeViewItemsPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewItems/useTreeViewItems.types";
import { UseTreeViewExpansionPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewExpansion/useTreeViewExpansion.types";
import { UseTreeViewFocusPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewFocus/useTreeViewFocus.types";
import { getTypename } from "../../library/metadataUtils";
import { setupHandlers, setupLocalState } from "../../library/dataService";

function isNullOrEmpty(str: string): boolean {
  return isNil(str) || str === "";
}

function NGReadOnlyTreeList({ config, context, data, name = "Page" }) {
  type Dictionary = {
    [key: string]: any;
  };

  config.NodeDisplayInstructions = {
    Items: "PassThrough",
    ListColumns: "PassThrough",
    ComponentReferenceIds: "Ignore",
    Services: "Ignore",
    Bindings: "Ignore",
    Layout: "Ignore",
    State: "Ignore",
  };

  config.NodeLeaves = ["ListColumn"];

  const local = setupLocalState(
    config,
    {
      Dirty: useSignal(config.Dirty ?? false),
      TreeData: useSignal(config.TreeData ?? (isEmpty(config.TreeData) ? data : config.TreeData)), // This contains the treedata with a first element of children
      Rows: useSignal(config.Rows ?? {}),
      NodesExpanded: useSignal(config.NodesExpanded ?? []),
      Loading: useSignal(config.Loading ?? false),
      AllowReordering: useSignal(config.AllowReordering || false),
      AllowAdd: useSignal(config.AllowAdd || false),
      AllowDelete: useSignal(config.AllowDelete || false),
      SelectedItem: useSignal(config.DefaultValue || null),
    },
    context
  );
  const apiRef = useRef<(UseTreeViewItemsPublicAPI<any> & UseTreeViewExpansionPublicAPI & UseTreeViewFocusPublicAPI) | undefined>(undefined);
  const pageName = isEmpty(local.TreeData.value) ? name : (local.TreeData.value?.Name ?? name);
  config.getData = (e: any, e2: any) => {
    // const d = findItem(local.Rows.value as TreeNode[], e2);
    // if (isNil(d)) return {};

    // // if I have not parent, I'm at the top level
    // if (isNil(d.parent)) return local.Rows.value[d.index]; // renameProperties(local.Rows.value[d.index], map);

    // if (isNil(d.children)) return {};

    // //const data = renameProperties(d.children[d.index], map);

    return local.Rows.value[local.SelectedItem.value];
  };

  const handlers = setupHandlers(config, context);

  function lastSegment(inputString: string): string {
    const segments = inputString.split("."); // Split the string by dots
    return segments[segments.length - 1]; // Get the last segment
  }
  const handleNodeSelect = (e, node) => {
    console.log("node selected", node);
    local.SelectedItem.value = node;

    if ((handlers as any).onNodeSelect) (handlers as any).onNodeSelect(e, node);
    // e.stopPropagation();
  };

  function getFriendlyName(item: any, path: string) {
    const typeName = getTypename(item) ?? "";

    if (typeName == "Label" && isNullOrEmpty(item.Name) && !isNullOrEmpty(item.DefaultValue))
      return `${item.DefaultValue} (${typeName})`;

    if (!isNullOrEmpty(item.Name) && !isNullOrEmpty(typeName)) return `${item.Name} (${typeName})`;

    if (!isNullOrEmpty(item.UniqueName) && !isNullOrEmpty(typeName)) return `${item.UniqueName} (${typeName})`;

    if (!isNullOrEmpty(item.Name)) return item.Name;

    if (!isNullOrEmpty(item.UniqueName)) return item.UniqueName;

    if (!isNullOrEmpty(typeName)) return `(${typeName})`;

    if (!isNullOrEmpty(path)) return path;

    if (!isNullOrEmpty(item.Id)) return item.Id;

    return "Unknown";
  }
  // Function to recursively create tree items
  const renderTree = (node, key, path: string) => {
    const handleDoubleClick = (e) => {
      console.log("double click selected", path);
      //local.SelectedItem.value = path;
      //e.stopPropagation();
    };

    let passThrough = false;
    let ignore = false;

    const tp = lastSegment(path);
    if (tp in config.NodeDisplayInstructions) {
      passThrough = config.NodeDisplayInstructions[tp] === "PassThrough";
      ignore = config.NodeDisplayInstructions[tp] === "Ignore";
    }
    if (ignore) return null;

    const typeName = getTypename(node);
    const leaf = isNil(typeName) || config.NodeLeaves.indexOf(typeName) == -1 ? false : true;

    if (typeof node !== "object" || node === null) {
      local.Rows.value[path] = node;
      // If the node is a primitive value or null
      return leaf && <TreeItem key={key} itemId={path} label={`${node}`} onDoubleClick={handleDoubleClick} />;
    } else if (Array.isArray(node)) {
      local.Rows.value[path] = node;
      // If the node is an array
      if (passThrough)
        return node.map((item, index) => renderTree(item, `${getFriendlyName(item, path)}`, `${path}[${index}]`));
      else
        return (
          <TreeItem key={key} itemId={path} label={key} onDoubleClick={handleDoubleClick}>
            {!leaf && node.map((item, index) => renderTree(item, `${key}-${index}`, `${path}[${index}]`))}
          </TreeItem>
        );
    } else {
      // If the node is an object
      local.Rows.value[path] = node;
      return (
        <TreeItem key={key} itemId={path} label={key} onDoubleClick={handleDoubleClick}>
          {!leaf &&
            Object.keys(node).map((subKey) => renderTree(node[subKey], `${key}-${subKey}`, `${path}.${subKey}`))}
        </TreeItem>
      );
    }
  };

  useSignalEffect(() => {
    local.NodesExpanded.value = Object.keys(local.Rows.value)
  })
  return (
    <div>
      {local.SelectedItem && (
        <Box
          onClick={() => {
            local.SelectedItem.value = "Page.Items[0].Items[0].Items[0].FieldType";
          }}
        >
          Selected Path: {local.SelectedItem}
        </Box>
      )}
      <TreeView
        apiRef={apiRef}
        expandedItems={local.NodesExpanded.value}
        onSelectedItemsChange={handleNodeSelect}
        onExpandedItemsChange={
          (e, value) => {
            local.NodesExpanded.value = value;
            return true;
          }
        }
        selectedItems={local.SelectedItem.value}
      >
        {batch(() => renderTree(local.TreeData.value, pageName, pageName))}
      </TreeView>
    </div>
  );
}

export default NGReadOnlyTreeList;
