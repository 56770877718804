import { v4 as uuidv4 } from 'uuid';

class Tracing {
  private readonly anonymousIdKey = "NG-AnonymousId";
  private readonly sessionIdKey = "NG-SessionId";
  private readonly requestIdKey = "NG-RequestId";

  createAnnonymousId() {
    const id = this.getFromSessionStore(this.anonymousIdKey);
    if (!id) {
      this.saveToSessionStore(this.anonymousIdKey, uuidv4());
    }
  }

  createSessionId() {
    this.saveToSessionStore(this.sessionIdKey, uuidv4());
  }

  getHeaders() {
    const anonymousId = this.getFromSessionStore(this.anonymousIdKey) ?? "";
    const sessionId = this.getFromSessionStore(this.sessionIdKey) ?? "";

    return {
      [this.anonymousIdKey]: anonymousId,
      [this.sessionIdKey]: sessionId,
      [this.requestIdKey]: uuidv4(),
    };
  }

  private saveToSessionStore(key: string, value: string) {
    if (typeof sessionStorage != "undefined") {
      sessionStorage.setItem(key, value);
    }
  }

  private getFromSessionStore(key: string) {
    if (typeof sessionStorage != "undefined") {
      return sessionStorage.getItem(key);
    }
    return null;
  }
}

const tracing = new Tracing();
export default tracing;
