import { Switch } from "../../../resolvers-types";
import { INGSwitchProps } from "../../library/NGFieldExtensions";
import { Switch as MUISwitch } from "@mui/material";
import { useComputed, useSignal } from "@preact/signals-react";
import { getClassName, getTestId, isNullOrEmpty, toBoolean } from "../../library/utils";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { isNil } from "lodash-es";
import { createControlWithLabel } from "../ComponentUtils";

export default function NGSwitch({ config, context }: INGSwitchProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(toBoolean(config.DefaultValue)),
      Label: useSignal(config.Label || ''),
      Visible: useSignal(config.Visible ?? true),
      Disabled: useSignal(config.Disabled || false),
      Classes: useSignal(config.Classes ?? []),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const label = useComputed(() => {
    if (local.Value.value === true && !isNullOrEmpty(config.LabelWhenOn)) return config.LabelWhenOn;
    if (!local.Value.value && !isNullOrEmpty(config.LabelWhenOff)) return config.LabelWhenOff;

    return local.Label.value;
  });

  return (
    <>
      {local.Visible.value &&
        createControlWithLabel({
          local,
          handlers,
          config,
          label,
          createControl: createSwitch,
          className: getClassName(local.Classes),
          labelPlacement: "start",
        })}
    </>
  );
}

function createSwitch(local: any, handlers: any, config: Switch, className: string, addTestId: boolean) {
  return (
    <MUISwitch
      data-testid={addTestId ? getTestId(config) : undefined}
      data-type={addTestId ? config.__typename : undefined}
      onChange={(e) => {
        local.Value.value = e.target.checked;

        if (!isNil((handlers as any).onChange)) {
          (handlers as any).onChange(e, local.Value.value);
        }
      }}
      checked={toBoolean(local.Value.value)}
      color={config.Color as any}
      disabled={local.Disabled.value}
      disableRipple={config.DisableRipple as boolean}
      edge={config.Edge as any}
      required={config.Required as boolean}
      size={config.Size as any}
      className={className}
    />
  );
}
