import { INGChartProps } from "../../library/NGFieldExtensions";
import NGHighchart from "../NGHighchart/NGHighchart";

export default function NGChart({ config, context }: INGChartProps) {
  switch (config.ChartLibrary) {
    case "Highcharts":
      return <NGHighchart key={"HC_" + config.Id} config={config} context={context} />;
    case "D3":
      return <div>TODO: D3</div>;
    default:
      throw new Error("NGChart: " + config.ChartLibrary + " not implemented yet");
  }
}
