import Box from "@mui/material/Box";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { useComputed, signal, useSignal } from "@preact/signals-react";
import { INGProportionalBarProps } from "../../library/NGFieldExtensions";
import { isNil } from "lodash";
import { getTestId, getsxObject, isNullOrEmpty, getClassName } from "../../library/utils";

export default function NGProportionalBar({ config, context }: INGProportionalBarProps) {
  const local = setupLocalState(
    config,
    {
      Rows: useSignal(config.Rows ?? []),
      Data: useSignal(config.Data ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? []),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  // Calculate the total to determine proportions
  const total = useComputed(() => {
    if (rows.value.length == 0) return 0;

    return rows.value.reduce((a, b) => a + b, 0);
  });

  const separator = config.DataSeparator ?? ",";
  const rows = useComputed(() => {
    if (isNullOrEmpty(local.Data.value)) return local.Rows.value;
    return local.Data.value.split(separator).map((r) => parseInt(r));
  });

  // Calculate the width percentage for each section
  //   const widthA = (a / total) * 100;
  //   const widthB = (b / total) * 100;
  //   const widthC = (c / total) * 100;

  const colorsGradients = [
    "linear-gradient(to right, #dc3545, orange)",
    "linear-gradient(to right, orange, yellow)",
    "linear-gradient(to right, yellow, green)",
  ];
  return (
    <Box
      data-testid={getTestId(config)}
      className={getClassName(local.Classes)}
      sx={getsxObject(local.Style, {
        display: "flex",
        width: "100%",
        height: "20px",
      })}
    >
      {rows.value.map((r, i) => {
        if (total.value == 0) return;

        const percent = (r / total.value) * 100;
        return (
          <Box
            key={i}
            sx={{
              width: `${percent}%`,
              background: !isNil(config.Colors) ? config.Colors[i % config.Colors.length] : undefined,
            }}
          />
        );
      })}
    </Box>
  );
}
