import { INGChartProps } from "../../library/NGFieldExtensions";
import { useComputed, useSignal } from "@preact/signals-react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC3D from "highcharts/highcharts-3d";
import HCCylinder from "highcharts/modules/cylinder";
import HCTree from "highcharts/modules/treemap";
import HCBullet from "highcharts/modules/bullet";
import HCExporting from "highcharts/modules/exporting";
import HCExportData from "highcharts/modules/export-data";
import HCAccessibility from "highcharts/modules/accessibility";
import { useRef } from "react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, isNullOrEmpty } from "../../library/utils";
import { isNil } from "lodash-es";

HC3D(Highcharts);
HCCylinder(Highcharts);
HCExporting(Highcharts);
HCExportData(Highcharts);
HCAccessibility(Highcharts);
HCTree(Highcharts);
HCBullet(Highcharts);

function transformDataForTreeMap(input) {
  const { rows, transposeRowNames, transposeRowValueFrom } = input;
  const dataMap = new Map();
  const colors = new Map();

  rows.forEach((row) => {
    const name = row[transposeRowNames];
    const value = parseFloat(row[transposeRowValueFrom]); // Ensure value is treated as a number

    if (isNullOrEmpty(name)) return; // Skip empty names, could be enhanced to use a default name

    if (dataMap.has(name)) {
      // If the name already exists, sum the values
      dataMap.set(name, dataMap.get(name) + value);
    } else {
      // Otherwise, add the new name and value
      dataMap.set(name, value);
    }

    if (row["Color"] && !colors.has(name)) colors.set(name, row["Color"]);
  });

  // Convert the map to the format Highcharts expects
  const transformedData = Array.from(dataMap, ([name, value]) => ({
    name,
    value,
    color: colors.get(name) ?? chooseColorBasedOnName(name), // Placeholder for a color assignment function
  }));

  return [
    {
      layoutAlgorithm: "squarified",
      data: transformedData,
    },
  ];
}

function chooseColorBasedOnName(name) {
  // Basic logic to assign color, could be enhanced to be more dynamic
  const baseColors = ["#EC2500", "#ECE100", "#EC9800", "#9EDE00", "#00C0EF", "#00ECB4", "#0099EC"];
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return baseColors[Math.abs(hash) % baseColors.length];
}

function transformDataForBarChart(input) {
  const { rows, transposeRowNames, transposeRowValueFrom } = input;

  // Accumulate results in a map to merge duplicates
  const resultsMap = {};

  rows.forEach((row) => {
    transposeRowNames.forEach((transposeRowName) => {
      if (row.hasOwnProperty(transposeRowName)) {
        const rowKey = row[transposeRowName];
        const rowValue = row[transposeRowValueFrom];
        if (!resultsMap[rowKey]) {
          // If it's not inside yet, initialize it
          resultsMap[rowKey] = {
            name: rowKey,
            data: [rowValue],
            color: row["Color"] ?? chooseColorBasedOnName(rowKey), // Placeholder for a color assignment function
            dataLabels: { enabled: false },
          };
        } else {
          // If it's already inside, add the rowValue to the existing data
          resultsMap[rowKey].data[0] += rowValue;
        }
      }
    });
  });

  return Object.values(resultsMap);
}

function transformDataForBulletChart(input) {
  const { rows, transposeRowNames, transposeRowValueFrom } = input;
  const resultsMap: any[] = [];
  // expect result for rows to be array
  // it should contain an Id or add transposeRowNames
  // for each transposeRowName it will generate a new bullet chart with target
  // for each record of different transposeRowName it can have a property called Target to add target
  // transposeRowName will be series name
  // value can be reached from transposeRowValueFrom

  const rowNames = !!transposeRowNames.length ? transposeRowNames : ["Id"];

  rows.forEach((row) => {
    rowNames.forEach((transposeRowName) => {
      if (row.hasOwnProperty(transposeRowName)) {
        const rowKey = row[transposeRowName];
        const rowValue = row[transposeRowValueFrom ?? "Value"];
        const rowTarget = row['Target'];
        resultsMap.push({
          name: rowKey,
          color: row["Color"],
          data: [{ y: rowValue, target: rowTarget }]
        })
      }
    });
  });

  return resultsMap;
}

export default function NGHighchart({ config, context }: INGChartProps) {
  console.log("~~NGHighchart", config);

  const testId = getTestId(config);

  const local = setupLocalState(
    config,
    {
      Rows: useSignal(config.Rows ?? []), // Rows is the data as passed in by the bindings
      TransposeRowsToSeries: useSignal(config.TransposeRowsToSeries ?? false),
      TransposeRowNames: useSignal(config.TransposeRowNames ?? ""),
      TransposeRowValueFrom: useSignal(config.TransposeRowValueFrom ?? ""),
      Loading: useSignal(config.Loading ?? false),
      ChartMetadata: useSignal(config.ChartMetadata || {}),
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  // const handlers = setupHandlers(config, context);

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const options = useComputed(() => {
    if (isNil(local.Rows.value)) return [];
    if (local.Rows.value.length === 0) return [];

    if (!local.TransposeRowsToSeries.value) return local.Rows.value;

    const chartType = local.ChartMetadata.value?.chart?.type ?? "bar";

    let series;
    const chartTypesSeries = {
      "treemap": transformDataForTreeMap,
      "bar": transformDataForBarChart,
      "bullet": transformDataForBulletChart,
      // add other chart types
      "default": () => []
    }

    series = (chartTypesSeries[chartType] ?? chartTypesSeries.default)({
      rows: local.Rows.value,
      transposeRowNames: local.TransposeRowNames.value,
      transposeRowValueFrom: local.TransposeRowValueFrom.value,
    });

    return {
      ...local.ChartMetadata.value,
      series: series,
      exporting: {
        enabled: false, // This disables the hamburger menu
      },
      credits: {
        enabled: false, // This removes the Highcharts credit link
      },
    };
  });

  const { chart } = chartComponentRef.current || {};

  chart?.redraw();

  return (
    <>
      {local.Loading.value && <div>Loading...</div>}
      {local.Visible.value && (
        <HighchartsReact
          highcharts={Highcharts}
          options={options.value}
          ref={chartComponentRef}
          containerProps={{
            style: {
              maxHeight: "100%",
              height: "100%",
              minHeight: "100px",
              minWidth: "100px",
              flex: "1",
              width: "100%",
            },
            "data-testid": testId,
            "data-type": config.__typename,
          }}
        />
      )}
    </>
  );
}
