import { Icon } from "../../../resolvers-types";
import { INGImageProps } from "../../library/NGFieldExtensions";
import { Image as MUIImage } from "mui-image";
import { useSignal } from "@preact/signals-react";
import { useEffect } from "react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, isNullOrEmpty, getClassName } from "../../library/utils";
import { log } from "../../library/logger";
import { setupImageDetails, convertToSizedImageFilename } from "../ComponentUtils";

const tag = "NGImage";

export default function NGImage({ config, context }: INGImageProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      AlternativeText: useSignal(config.AlternativeText || ""),
      Size: useSignal(config.Size || "Medium"),
      Path: useSignal(config.Path || ""),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Source: useSignal(config.Source ?? null), // Asset, Vault, External
    },
    context
  );

  // config.getData = (e) => {
  //   return local.Value.value;
  // };

  log.info(tag, `render ${local.Value?.value} `, config);

  const url = useSignal("");

  useEffect(() => {
    log.info(tag, `use effect ${local.Value?.value} `, config);
    if (!local.Visible.value) return;

    let value = local.Value?.value;

    if (isNullOrEmpty(value)) {
      if (!isNullOrEmpty(config.FallbackImage)) value = config.FallbackImage as string;
      else {
        url.value = "";
        return;
      }
    }

    setupImageDetails(local, value, url, convertToSizedImageFilename);
  }, [
    local.Visible.value,
    local.Value?.value,
    local.Path.value,
    local.Size.value,
    url,
    local.Source,
    local,
    config.FallbackImage,
  ]);

  const handlers = setupHandlers(config, context);

  return (
    <>
      {local.Visible.value && url.value && (
        <MUIImage
          data-testid={getTestId(config)}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          src={url.value}
          alt={config.AlternativeText as string}
          bgColor={config.BgColor as string}
          distance={config.Distance as string}
          duration={config.Duration ?? 0}
          easing={config.Easing ?? ""}
          errorIcon={config.ErrorIcon as Icon}
          fit={config.Fit as string}
          height={config.Height as string}
          iconWrapperClassName={config.IconWrapperClassName as string}
          position={config.Position as string}
          shift={config.Shift as string}
          shiftDuration={config.ShiftDuration as number}
          showLoading={config.ShowLoading as boolean}
          width={config.Width as string}
          // wrapperClassName={config.WrapperClassName as string}
          onError={(e) => {
            console.log("error", config.FallbackImage);
            if (!isNullOrEmpty(config.FallbackImage)) {
              setupImageDetails(local, config.FallbackImage, url, convertToSizedImageFilename);
              console.log("error-2", local);
            }

            if ((handlers as any).onError) (handlers as any).onError(e);
          }}
          {...handlers}
          onClick={(e) => {
            if ((handlers as any).onClick) (handlers as any).onClick(e, local.Value.value);
          }}
        />
      )}
    </>
  );
}
