import { ITabLayoutProps } from "../library/NGFieldExtensions";
import { isNil, isString } from "lodash-es";
import { computed, signal, useComputed, useSignal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { getClassName, getsxObject, keyByRec } from "../library/utils";
import { Box, Tab, Tabs } from "@mui/material";
import NGLayout from "./NGLayout";
import NGReviewDialog from "../components/NGReviewDialog/NGReviewDialog";
import { containerWithPaper } from "../generators/GeneratorUtils";
import NGLayoutItem from "../generators/NGLayoutItem";
import { log } from "../library/logger";
import { isLayout } from "../library/metadataUtils";

const tag = "NGTabLayout";

export default function NGTabLayout({ layoutItem, tabLayout, context }: ITabLayoutProps) {
  const itemMap = keyByRec((layoutItem as any).Items, "Items", "Id", {});

  const local = setupLocalState(
    tabLayout,
    {
      Style: useSignal(tabLayout.Style ?? {}),
      TabClasses: useSignal(tabLayout.TabClasses ?? []),
      ContentClasses: useSignal(tabLayout.ContentClasses ?? []),
      Visible: useSignal(tabLayout.Visible ?? true),
      InReviewMode: useSignal(tabLayout.InReviewMode || false),
      SelectedTabId: useSignal(tabLayout.SelectedTabId ?? tabLayout.Tabs[0].Id),
    },
    context
  );

  const selectedTab = useComputed(() => {
    const index = tabLayout.Tabs?.findIndex((item) => item.Id === local.SelectedTabId.value);
    return index > -1 ? index : 0;
  });

  const handlers = setupHandlers(tabLayout, context);

  if (!isNil(tabLayout.ReviewDialogOptions)) {
    (tabLayout.ReviewDialogOptions as any).ContextId = (tabLayout as any).ContextId;
  }

  function renderContainer() {
    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
      const { Title, Id } = tabLayout.Tabs[newValue];
      local.SelectedTabId.value = Id;

      if (handlers["onChange"]) {
        handlers["onChange"](e, { Index: newValue, Id, Title });
      }
    };

    return (
      <>
        <Tabs
          // variant="scrollable"
          // scrollButtons="auto"
          // allowScrollButtonsMobile
          value={selectedTab.value}
          aria-label={tabLayout.AriaLabel ?? "tabs"}
          className={getClassName(local.TabClasses)}
          TabIndicatorProps={{ sx: tabLayout.TabIndicatorStyles }}
          sx={getsxObject(local.Style.value)}
          {...handlers}
          onChange={handleChange}
        >
          {tabLayout.Tabs?.map((tab, key) => {
            return <Tab key={key} disabled={false} label={tab.Title} sx={{ textTransform: "none" }} />;
          })}
        </Tabs>
        {tabLayout.Tabs?.map((tab, tabkey) => {
          return tab.Items.map((x, key) => {
            const item = isString(x) ? itemMap[x] : x;

            if (isNil(item)) {
              log.error(tag, `Item with id '${x}' could not be found`);
              return <></>;
            }
            // The Box below is necessary to avoid error: "Each child in a list should have a unique "key" prop"
            return (
              <Box key={`${tabkey}-${key}`} className={getClassName(local.ContentClasses)}>
                {selectedTab.value === tabkey &&
                  (!isLayout(x) ? (
                    <NGLayoutItem config={item} context={context} />
                  ) : (
                    <NGLayout layoutItem={layoutItem} layout={x} inDesignMode={false} context={context} />
                  ))}
              </Box>
            );
          });
        })}

        {local.InReviewMode.value && (
          <NGReviewDialog config={tabLayout.ReviewDialogOptions ?? {}} context={context}></NGReviewDialog>
        )}
      </>
    );
  }

  return containerWithPaper(local, tabLayout, renderContainer, context);
}
