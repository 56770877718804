import { useEffect } from "react";
import { batch, useSignal, useSignalEffect } from "@preact/signals-react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  FormControl,
  FormLabel,
  Stack,
  IconButton,
  Modal,
  Typography,
  Autocomplete,
  TextField,
  Paper,
} from "@mui/material";
import { AddSharp, RemoveSharp } from "@mui/icons-material";
import { isEqual } from "lodash-es";
import { setupLocalState } from "../../library/dataService";
import { getChildrenAndState, getNonObjectProperies, hasObjectProperies } from "./StyleConfigurationHelper";

export default function NGConfigurationEditor({ selected, typename, config, context, configurations }) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal((config as any).Value ?? {}),
    },
    context
  );

  const selectedChildConfiguration = useSignal<string | null>("");
  const selectedStateConfiguration = useSignal<string | null>("");
  const addConfigurationModalOpen = useSignal(false);
  const internalIndex = useSignal(0);

  const childrenAndState = getChildrenAndState(typename);
  const children = Object.entries(childrenAndState.children ?? {}).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  const states = Object.entries(childrenAndState.states ?? {}).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  //const configurations = [default, ...controlConfigurations];
  const handleCloseModal = () => {
    selectedChildConfiguration.value = null;
    selectedStateConfiguration.value = null;
    addConfigurationModalOpen.value = false;
  };

  const handleCreateNewConfiguration = () => {
    const localValue = local.Value.value;
    const child = selectedChildConfiguration.value ? `& .${selectedChildConfiguration.value}` : null;
    const state = selectedStateConfiguration.value ? `&.${selectedStateConfiguration.value}` : null;

    if (child && !state && !localValue[child]) {
      localValue[child] = {};
    }

    if (state && !child && !localValue[state]) {
      localValue[state] = {};
    }

    if (state && child) {
      if (localValue[child]) {
        if (!localValue[child][state]) {
          localValue[child][state] = {};
        }
      } else {
        localValue[child] = { [state]: {} };
      }
    }

    local.Value.value = { ...local.Value.value, ...localValue };
    handleCloseModal();
  };

  const handleDeleteConfiguration = (path) => {
    if (!Array.isArray(path) || path.length === 0) {
      return;
    }

    const current = local.Value.value;

    if (current[path[0]] === undefined) {
      return;
    } else {
      const currentProps = Object.keys(current[path[0]]);
      const childProp = current[path[0]][path[1]] ?? null;
      if (
        currentProps.length === 0 ||
        (!childProp && !currentProps.some((prop) => prop.includes("&"))) ||
        (childProp && currentProps.length === 1)
      ) {
        delete current[path[0]];
      } else {
        currentProps.forEach((currentProp) => {
          if (currentProp === path[1] || (!path[1] && !currentProp.includes("&"))) delete current[path[0]][currentProp];
        });
      }
    }

    batch(() => {
      local.Value.value = { ...current };
      internalIndex.value = 0;
      selected.value = configurations[0];
    });
  };

  useSignalEffect(() => {
    internalIndex.value = getDefaultValue(configurations, selected?.value);
  });

  function getDefaultValue(configurations: any, value: any): any {
    if (value) {
      return configurations.findIndex((config) => isEqual(config, value));
    }
    return 0;
  }

  useEffect(() => {
    selected.value = configurations[configurations?.length - 1];
  }, [configurations?.length]);

  const handleAutocompleteSelection = (prop: "child" | "state", value?: string) => {
    if (prop === "child") selectedChildConfiguration.value = value ?? null;
    if (prop === "state") selectedStateConfiguration.value = value ?? null;
    return;
  };

  return (
    <Box className="configuration-editor-container">
      <FormControl>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <FormLabel>Configuration</FormLabel>
          <Button sx={{ textTransform: "none", gap: "5px" }} onClick={() => (addConfigurationModalOpen.value = true)}>
            <AddSharp /> New{" "}
          </Button>
        </Stack>
        {configurations?.length > 1 && (
          <RadioGroup
            name="style-configuration-radio-group"
            value={internalIndex.value}
            className="style-configuration-radio-group"
          >
            {configurations.map((configuration, index) => (
              <Stack key={index} direction="row">
                <FormControlLabel
                  key={index}
                  control={<Radio />}
                  label={configuration.Label}
                  value={index}
                  onChange={(e) => {
                    internalIndex.value = index;
                    selected.value = configurations[index];
                  }}
                />
                {index > 0 && (
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => handleDeleteConfiguration(configuration.Value)}
                  >
                    <RemoveSharp />
                  </IconButton>
                )}
              </Stack>
            ))}
          </RadioGroup>
        )}
        <Modal
          open={addConfigurationModalOpen.value}
          onClose={handleCloseModal}
          aria-labelledby="add-new-style-configuration-modal-title"
          aria-describedby="add-new-style-configuration-modal-description"
        >
          <Paper elevation={1} className="style-configuration-add-new-modal">
            <Stack direction="column" alignItems="center" gap="2rem">
              <Typography id="add-new-style-configuration-modal-title" variant="h6" component="h2">
                Add new style configuration
              </Typography>
              <Stack>
                <Autocomplete
                  id="configuration-editor-children-selector"
                  data-testid="configuration-editor-children-selector"
                  disablePortal
                  options={children}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Child" />}
                  onChange={(e, value) => handleAutocompleteSelection("child", value?.value)}
                />
                <Autocomplete
                  id="configuration-editor-state-selector"
                  data-testid="configuration-editor-state-selector"
                  disablePortal
                  options={states}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="State" />}
                  onChange={(e, value) => handleAutocompleteSelection("state", value?.value)}
                />
              </Stack>
              <Button onClick={handleCreateNewConfiguration} sx={{ width: "200px" }}>
                Create
              </Button>
            </Stack>
          </Paper>
        </Modal>
      </FormControl>
    </Box>
  );
}
