import { log } from "../../library/logger";
import { isNullOrEmpty } from "../../library/utils";
import { IOpenAPI } from "./openAPISpec";
import { get, isArray, isBoolean, isEmpty, isNil, isNull, isNumber, isString, isSymbol, isUndefined } from "lodash-es";
// import Resolver from "json-schema-resolver";

// Gets the list of services and methods
export interface PathDetail {
  path: string;
  method: string;
  summary: string;
  description: string;
}

export interface AppServiceList {
  [serviceName: string]: PathDetail[];
}

type OpenAPIObject = { [key: string]: any };

export const resolveRefs = (obj: OpenAPIObject, root: OpenAPIObject): OpenAPIObject => {
  const traverse = (node: any, depth: number): any => {
    depth = depth + 1;

    if (depth > 15) {
      console.log("Max depth reached", node);
      return node;
    }

    if (Array.isArray(node)) {
      return node.map(traverse);
    } else if (node && typeof node === "object") {
      if (node.$ref) {
        const refPath = node.$ref.split("/").slice(1); // Remove the '#' and split the path
        const refValue = refPath.reduce((acc, key) => acc[key], root); // Traverse the path in the root object
        return traverse(refValue, depth); // Recursively resolve the reference
      } else {
        const resolvedNode: any = {};
        for (const key in node) {
          resolvedNode[key] = traverse(node[key], depth);
        }
        return resolvedNode;
      }
    }
    return node; // Primitive value (string, number, etc.)
  };

  const a = traverse(obj, 0);

  return a;
};

export function getServiceFromPath(path: string) {
  if (isNullOrEmpty(path)) return "";

  if (path[0] == "/") path = path.slice(1);

  return path.replace(/\//g, ".");
}
function getFriendlyPathName(path: string) {
  const offset = getOffset(path);
  const t = path
    .split("/")
    .slice(offset + 1)
    .join(": ");

  return t[0].toUpperCase() + t.slice(1);
}
export const parseOpenAPIServices = (spec: IOpenAPI) => {
  const t = extractPathDetails(spec);

  return Object.keys(t).map((key) => {
    return {
      serviceName: key,
      methods: t[key].map((m) => {
        return {
          path: m.path,
          shortPath: getFriendlyPathName(m.path),
          method: m.method,
          summary: m.summary,
          description: m.description,
          //fields: extractFieldList(spec, m.path),
        };
      }),
    };
  });
};

function getOffset(path: string): number {
  return path.indexOf("/api") == 0 ? 2 : 1;
}

export const extractPathDetails = (spec: IOpenAPI): AppServiceList => {
  const details: AppServiceList = {};

  for (const path in spec.paths) {
    const offset = getOffset(path);

    const firstSegment = path.split("/")[offset] || "";

    if (!details[firstSegment]) {
      details[firstSegment] = [];
    }

    for (const method in spec.paths[path]) {
      const { summary, description } = spec.paths[path][method];
      details[firstSegment].push({
        path,
        method: method.toUpperCase(),
        summary: summary || "",
        description: description || "",
      });
    }
  }

  return details;
};

export const getPathFromVerb = (service: string, verb: string, operation: string | null = null): string => {
  let s = `/api/${service}/${verb}`;

  if (!isNullOrEmpty(operation)) s += `/${operation}`;

  return s;
};

export interface FieldDetail {
  type: string;
  format: string;
  description: string;
  required: boolean;
}
export interface AppFieldList {
  [fieldName: string]: FieldDetail[];
}

function getRef(spec: IOpenAPI, ref: string): any {
  const refPath = ref.split("/").slice(1); // Remove the '#' and split the path
  const refValue = refPath.reduce((acc, key) => acc[key], spec); // Traverse the path in the root object

  return refValue;
}

interface OutputField {
  name: string;
  type: string;
  directives?: object;
  description?: string;
}

interface OutputObject {
  FieldList: {
    fields: OutputField[];
  };
}

const isBasicType = (value: any): boolean => {
  return (
    isString(value) || isNumber(value) || isBoolean(value) || isNull(value) || isUndefined(value) || isSymbol(value)
  );
};

const isArrayOfStrings = (value: any): boolean => {
  return isArray(value) && value.every(isString);
};

export const convertServiceResultToPropertiesEditor = (input: any): OutputObject => {
  const outputFields: OutputField[] = Object.keys(input).map((key) => {
    const field = input[key];
    const type = isBasicType(field) ? typeof field : isArrayOfStrings(field) ? "[String]" : "Object";

    return {
      name: key,
      type,
      directives: {},
    };
  });

  return {
    FieldList: {
      fields: outputFields,
    },
  };
};

export const getPropertiesEditorDataFromServiceResult = (input: any) => {
  return { ...input, __typename: "FieldList" };
};

//  //{ __typename: 'FieldList'}

// {
//   "Id": "a2308487-8189-4560-9303-e2b3723e52c6",
//   "Name": "Test1",
//   "Tickers": [
//       "AAPL"
//   ]
// }

export const convertOpenAPIFieldsToPropertiesEditor = (input: any): OutputObject => {
  const outputFields: OutputField[] = Object.keys(input).map((key) => {
    const field = input[key];
    let type = field.type.charAt(0).toUpperCase() + field.type.slice(1);

    if (field.type === "array" && field.items && field.items.type === "string") {
      type = "[String]";
    }

    return {
      name: key,
      type,
      directives: {},
      description: field.description,
    };
  });

  return {
    FieldList: {
      fields: outputFields,
    },
  };
};

function findDeepestPropertiesNode(obj: any, depth = 0): { depth: number; node: any | null } {
  let deepestNode = { depth: -1, node: null as any | null };

  function traverse(currentObj: any, currentDepth: number) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentObj.hasOwnProperty("properties")) {
      if (currentDepth > deepestNode.depth) {
        deepestNode = { depth: currentDepth, node: currentObj };
      }
    }

    for (const key in currentObj) {
      if (typeof currentObj[key] === "object" && currentObj[key] !== null) {
        traverse(currentObj[key], currentDepth + 1);
      }
    }
  }

  traverse(obj, depth);
  return deepestNode?.node?.properties;
}

export async function getFieldsFromServiceNameInOpenAPI(spec: IOpenAPI, serviceName: string, verb: string) {
  const path = getPathFromVerb(serviceName, verb); //`/api/${serviceName}/${verb}`;

  return extractFieldList(spec, path);
}

function getItemsFromPathInOpenAPI(spec: IOpenAPI, path: string) {
  const schema = spec.paths[path]?.["post"]?.responses?.["200"]?.content?.["application/json"]?.schema;

  if (isNil(schema)) {
    log.error("extractFieldList", `schema not found for path '${path} in schema`, spec);
    return null;
  }
  const items = resolveRefs(schema.items ?? schema, spec);
  return items;
}

export const extractFieldList = (spec: IOpenAPI, path: string): any => {
  const items = getItemsFromPathInOpenAPI(spec, path);

  if (isNil(items)) {
    log.error("extractFieldList", `Items not found for path '${path} in schema`, spec);
    return {};
  }

  const properties = findDeepestPropertiesNode(items);
  // isEmpty(items.properties?.data) ? items.properties : items.properties?.data?.properties;

  // if (isNil(properties)) {
  //   log.error("extractFieldList", `No properties found for path '${path} in schema`, spec);
  //   return {};
  // }

  // if (!isNil(items?.required) && items?.required.length > 0)
  //   items.required.forEach((field: string) => {
  //     if (!isNil(properties[field])) properties[field].required = true;
  //   });

  return properties;
};
