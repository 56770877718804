import * as graphql from "../../resolvers-types";
import { generateUID } from "../library/utils";

export const ngEditorMenu: graphql.Menu = {
  Features: [
    {
      BottomIcon: null,
      Disabled: null,
      EndIcon: null,
      ExternalUrl: null,
      Id: "0xa201",
      Label: "Development",
      PageUrl: null,
      StartIcon: null,
      TopIcon: null,
      UrlOpenTarget: null,
      Visible: true,
      Features: [
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa231",
          Label: "Properties Editor",
          PageUrl: "/local/prop-editor",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32",
          Label: "Menu Editor",
          PageUrl: "/local/menu-editor",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa21/2",
          Label: "Component Editor GUI",
          PageUrl: "/local/component-editor-gui",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32aaa",
          Label: "Component Manager",
          PageUrl: "/local/component-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa33aaa",
          Label: "Page Manager",
          PageUrl: "/local/page-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32aaar",
          Label: "Theme Manager",
          PageUrl: "/local/theme-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32aaare",
          Label: "Site Manager",
          PageUrl: "/local/site-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32wwaaare",
          Label: "Style Editor",
          PageUrl: "/local/style-editor",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xb42wwaaare",
          Label: "Format Editor",
          PageUrl: "/local/format-editor",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32a",
          Label: "Asset Manager",
          PageUrl: "/local/asset-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32aab",
          Label: "Service Manager",
          PageUrl: "/local/service-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa32aabc",
          Label: "Plugin Manager",
          PageUrl: "/local/plugin-manager",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa31",
          Label: "Data Model Editor",
          PageUrl: "/local/data-model-editor",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
      ],
    },
    {
      Id: "0xasdwdsasd20",
      Label: "Components",
      Visible: true,
      Features: [
        {
          Id: "0xwera342w1",
          Label: "Component Catalog",
          PageUrl: "/local/product-catalog",
          Visible: true,
        },
        {
          Id: "0xa342w1",
          Label: "Component Feedback",
          PageUrl: "/local/dashboard-review-by-widget",
          Visible: true,
        },
      ],
    },
    {
      BottomIcon: null,
      Disabled: null,
      EndIcon: null,
      ExternalUrl: null,
      Id: "0xasdwsd20",
      Label: "Examples",
      PageUrl: null,
      StartIcon: null,
      TopIcon: null,
      UrlOpenTarget: null,
      Visible: true,
      Features: [
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa3421",
          Label: "Manual Feed",
          PageUrl: "/local/main-feed",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa342",
          Label: "User Profile Feed",
          PageUrl: "/local/user-feed",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa34a1",
          Label: "All Component Test",
          PageUrl: "/local/comp-test",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa33",
          Label: "Repeater Example",
          PageUrl: "/local/site-keyapp",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa343",
          Label: "Login Page Example",
          PageUrl: "/local/login-page",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa34312",
          Label: "Grid Test",
          PageUrl: "/local/grid-test",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa3431",
          Label: "Complex Form",
          PageUrl: "/local/form-test",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa342231",
          Label: "Excel List",
          PageUrl: "/local/excel-list",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa343ss2",
          Label: "Flex Layout",
          PageUrl: "/local/flex-test",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa34321",
          Label: "Nested Components",
          PageUrl: "/local/component-nesting-test",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa3433",
          Label: "Reviewer Feedback Layout",
          PageUrl: "/local/reviewer-feedback-layout",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa3434",
          Label: "Article Full Page",
          PageUrl: "/local/article-fullpage",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa343122",
          Label: "Sample Components",
          PageUrl: "/local/sample-components",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa3435",
          Label: "Style Layout",
          PageUrl: "/local/style-test",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
        {
          BottomIcon: null,
          Disabled: null,
          EndIcon: null,
          ExternalUrl: null,
          Features: null,
          Id: "0xa343222",
          Label: "Single Hierarhcy",
          PageUrl: "/local/single-hierarchy",
          StartIcon: null,
          TopIcon: null,
          UrlOpenTarget: null,
          Visible: true,
        },
      ],
    },
  ],
  Id: "custom-menu",
  Description: null,
  Name: "Custom Menu",
  __typename: "Menu",
};

export const generateFeature = () => ({
  BottomIcon: null,
  Disabled: null,
  EndIcon: null,
  ExternalUrl: null,
  Features: null,
  Id: generateUID(),
  Label: "New Feature",
  __typename: "Feature",
  PageUrl: null,
  StartIcon: null,
  TopIcon: null,
  UrlOpenTarget: null,
  Visible: true,
});
