import React, { ChangeEvent, useMemo, useRef } from "react";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  RichTreeView,
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Provider,
  TreeItem2Root,
  UseTreeItem2ContentSlotOwnProps,
  UseTreeItem2Parameters,
  useTreeItem2Utils,
} from "@mui/x-tree-view";
import * as MuiIcons from "@mui/icons-material";
import {
  attachInstruction,
  extractInstruction,
  Instruction,
  ItemMode,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { flatMapDeep, isNil } from "lodash-es";
import { Avatar, Box, ClickAwayListener, IconButton, InputAdornment, Stack, TextField, styled } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { UseTreeViewItemsPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewItems/useTreeViewItems.types";
import { UseTreeViewExpansionPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewExpansion/useTreeViewExpansion.types";
import { UseTreeViewFocusPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewFocus/useTreeViewFocus.types";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { NGDropIndicatorTreeItem } from "../../generators/NGDropIndicatorTreeItem";
import { elementIsVisibleInParent, getClassName, getDOMElementById, getTestId, getsxObject, queryDOMElementBySelector, scrollToView } from "../../library/utils";
import NGIcon from "../NGIcon/NGIcon";
import { getFilteredTree } from "../../library/metadataUtils";
import { hasDropRestriction } from "../../library/designer";
import useCopyPasteListener from "../../hooks/useCopyPasteListener";
import { ngEditorMenuSample } from "../../sampleData/ngEditorMenuSample";

export const idleState: { type: string; instruction: Instruction | null } = { type: "idle", instruction: null };

function extractIds(data): string[] {
  return flatMapDeep(data, (item) => (item.children ? [item.id, ...extractIds(item.children)] : []));
}

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  // padding: theme.spacing(0.5, 1),
}));

const CustomLabel = React.forwardRef(function CustomLabel(props: CustomTreeItemProps, ref: React.Ref<HTMLElement>) {
  const { id, itemId, label, disabled, children, icon: Icon } = props;

  const { getLabelProps, publicAPI } = useTreeItem2({
    id,
    itemId,
    children,
    label,
    disabled,
    // rootRef: ref,
  });

  const idleState: { type: string; instruction: Instruction | null; parentRestriction?: boolean } = { type: "idle", instruction: null };
  const dragState = useSignal(idleState);

  const itemRef = useRef(null);
  useSignalEffect(() => {
    if (isNil(itemRef.current)) return;

    const element = itemRef.current;
    const item = publicAPI.getItem(itemId);

    return combine(
      draggable({
        element,
        getInitialData: () => ({ ...item, itemType: "TreeItem" }),
        canDrag: () => {
          return item.operations.canDrag;
        },
        onDragStart: ({ source }) => {
          if (source.data.expanded) {
            publicAPI.setItemExpansion(null, source.data.id, false);
          }
        },
        onDrop: ({ source }) => {
          if (source.data.expanded) {
            publicAPI.setItemExpansion(null, source.data.id, true);
          }
        },
      }),
      dropTargetForElements({
        element,
        getData: ({ input, element }) => {
          let data = { ...item };

          const mode: ItemMode = (() => {
            if (item.children.length && item.expanded) {
              return "expanded";
            }

            if (item.index === item.parent?.children.length - 1) {
              return "last-in-group";
            }

            return "standard";
          })();

          const block = [];
          if (!item.operations.canBeParent) {
            block.push("make-child");
          }

          data = attachInstruction(data, {
            input,
            element,
            currentLevel: item.level,
            indentPerLevel: 1,
            mode,
            block,
          });
          return data;
        },
        getIsSticky: () => false,
        canDrop: (args) => {
          const source = args.source.data;
          const self = item.id == source.id;
          return source.itemType == "TreeItem" && !self;
        },
        onDragEnter: ({ source, self, location }) => {
          const instruction = extractInstruction(self.data);
          const parentRestriction = hasDropRestriction({ location, source });
          dragState.value = {
            ...idleState,
            type: "item-over",
            instruction,
            parentRestriction
          };
        },
        onDrag: ({ source, self, location }) => {
          const instruction: Instruction | null = extractInstruction(self.data);
          const parentRestriction = hasDropRestriction({ location, source });
          const current = dragState.value;
          // skip re-render if edge is not changing
          if (current.type == "item-over" && current?.instruction == instruction) {
            return;
          }
          dragState.value = { ...idleState, type: "item-over", instruction, parentRestriction };
        },
        onDragLeave: () => {
          // closestEdge.value = null;
          dragState.value = idleState;
        },
        onDrop: ({ source, location }) => {
          // closestEdge.value = null;
          dragState.value = idleState;
        },
      })
    );
  });

  return (
    <Box
      sx={{ flexGrow: 1, display: "flex", gap: 1, alignItems: "center", position: "relative", padding: "2px 4px" }}
      ref={itemRef}
    >
      {Icon && MuiIcons[Icon?.IconName] ? (
        <Box
          component={MuiIcons[Icon?.IconName]}
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.8rem",
          }}
        />
      ) : (
        <Avatar
          sx={(theme) => ({
            background: theme.palette.primary.main,
            width: 24,
            height: 24,
            fontSize: "0.8rem",
          })}
        >
          {(label as string)[0]}
        </Avatar>
      )}
      <TreeItem2Label {...getLabelProps()} sx={{ fontSize: "0.8rem" }} />
      {dragState.value.instruction && <NGDropIndicatorTreeItem instruction={dragState.value.instruction} parentRestriction={!!dragState.value.parentRestriction} />}
    </Box>
  );
});

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
  Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> { }

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const { getRootProps, getContentProps, getIconContainerProps, getGroupTransitionProps, publicAPI } = useTreeItem2({
    id,
    itemId,
    children,
    label,
    disabled,
    rootRef: ref,
  });

  const item = publicAPI.getItem(itemId);
  const status = getContentProps().status;

  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps["onClick"] = (event) => {
    event.defaultMuiPrevented = true;
    interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)} id={itemId}>
        <CustomTreeItemContent {...getContentProps()} onClick={handleContentClick}>
          <TreeItem2IconContainer {...getIconContainerProps()} onClick={handleIconContainerClick}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <CustomLabel {...props} icon={item?.icon} />
        </CustomTreeItemContent>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default function NGRichTreeList({ config, context }) {
  const local = setupLocalState(
    config,
    {
      Items: useSignal(config.Items ?? []), // This contains the treedata with a first element of children
      Loading: useSignal(config.Loading ?? false),
      AllowReordering: useSignal(config.AllowReordering ?? false),
      AllowAdd: useSignal(config.AllowAdd ?? false),
      AllowDelete: useSignal(config.AllowDelete ?? false),
      SelectedItem: useSignal(config.DefaultValue ?? null),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Searchable: useSignal(config.Searchable ?? false),
    },
    context
  );
  const handlers = setupHandlers(config, context);

  const apiRef = useRef<(UseTreeViewItemsPublicAPI<any> & UseTreeViewExpansionPublicAPI & UseTreeViewFocusPublicAPI) | undefined>(undefined);
  const expandedItems = useSignal<string[]>([]);
  const selectedItems = useSignal<string>("");
  const focusedItem = useSignal<string>("");
  const searchInput = useSignal("");
  const stopExpandAll = useSignal(false);

  const expandAll = () => {
    expandedItems.value = extractIds(local.Items.value);
  };

  const collapseAll = (e) => {
    handleSelect(e, []);
    stopExpandAll.value = false;
    expandedItems.value = [];
  };

  useSignalEffect(() => {
    if (!stopExpandAll.value) expandAll();
  });

  useSignalEffect(() => {
    if (local.SelectedItem.value) {
      stopExpandAll.value = true;
      const selectedDomElement = getDOMElementById(local.SelectedItem.value);
      if (
        !selectedDomElement &&
        !elementIsVisibleInParent(
          selectedDomElement,
          queryDOMElementBySelector('tree-view', 'data-testid')
        )
      ) {
        expandAll();
      }
      selectedItems.value = local.SelectedItem.value;
    }
  });

  useSignalEffect(() => {
    return combine(
      monitorForElements({
        canMonitor: ({ source }) => source.data?.itemType === "TreeItem",
        onDrop: ({ source, location }) => {
          stopExpandAll.value = true;
          if (!hasDropRestriction({ source, location }))
            handlers["onDrop"]?.(new Event("onDrop"), { source, location });
        },
      })
    );
  });

  useSignalEffect(() => {
    if (!!selectedItems.value && expandedItems.value.includes(selectedItems.value)) {
      scrollToView(selectedItems.value, { wait: 300, topSubtract: 150, testId: 'tree-view' });
    }
  })

  const toggleExpanded = (e, id, expanded) => {
    apiRef.current!.getItem(id).expanded = expanded;
    if (expanded) {
      expandedItems.value = [...expandedItems.value, id];
    } else {
      selectedItems.value = id;
      expandedItems.value = expandedItems.value.filter((x) => x != id);
    }
  };

  const handleSelect = (e, itemIds) => {
    if (handlers["onSelect"]) handlers["onSelect"](e, itemIds);

    const node = apiRef.current?.getItem(itemIds)?.item;
    if (handlers["onNodeSelect"] && node) handlers["onNodeSelect"](e, node);
  };

  const handleClickAway = () => {
    if (focusedItem.value) {
      focusedItem.value = ""
    }
  }

  const handleItemFocus = (event: React.SyntheticEvent | null, itemId: string) => {
    focusedItem.value = itemId;
  }

  const itemList = useMemo(() => {
    if (!!local.Searchable.value && !!searchInput.value) {
      return getFilteredTree(local.Items.value, searchInput.value);
    }
    return local.Items.value;
  }, [local.Searchable.value, searchInput.value, local.Items.value]);

  useCopyPasteListener(handlers, !!focusedItem.value);

  return (
    <Box
      data-testid={getTestId(config)}
      data-type={config.__typename}
      sx={getsxObject(local.Style.value)}
      className={getClassName(local.Classes)}
    >
      <Stack direction="row" width="100%" >
        <IconButton onClick={expandAll} title="Expand All">
          <ExpandMore />
        </IconButton>
        <IconButton onClick={collapseAll} title="Collapse All">
          <ExpandLess />
        </IconButton>
        {local.Searchable.value && (
          <TextField
            // data-testid={`component-editor-search-icons-${config.Name}`}
            placeholder={"Search components..."}
            fullWidth
            name="treeItemSearch"
            variant="outlined"
            size="small"
            style={{ padding: "0 6px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <NGIcon config={{ IconName: "Search" }} context={context} />
                </InputAdornment>
              ),
            }}
            value={searchInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => (searchInput.value = e.target.value)}
          />
        )}
      </Stack>
      <ClickAwayListener onClickAway={handleClickAway}>
        <RichTreeView
          apiRef={apiRef}
          items={itemList}
          getItemId={(item: { id: string, label: string }) => item.id}
          getItemLabel={(item: { label: string }) => item.label}
          expandedItems={expandedItems.value}
          selectedItems={selectedItems.value}
          onItemExpansionToggle={toggleExpanded}
          onSelectedItemsChange={handleSelect}
          onItemFocus={handleItemFocus}
          slots={{ item: CustomTreeItem }}
        />
      </ClickAwayListener>
    </Box>
  );
}
