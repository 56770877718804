import { INGControlProps } from "../../library/NGFieldExtensions";
import { Alert, Button, Input, Snackbar } from "@mui/material";
import { signal, useSignal } from "@preact/signals-react";
import { useRef } from "react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { CloudUpload } from "@mui/icons-material";

export default function NGBasicFileUpload({ config, context, style }: INGControlProps) {
  const message = useSignal("");
  const openSnackbar = useSignal(false);
  const data = useSignal(null);

  const local = setupLocalState(
    config,
    {
      DefaultValue: useSignal(config.DefaultValue || ""),
      Value: useSignal(config.DefaultValue || ""),
      Visible: useSignal(config.Visible ?? true),
      DisplayUpload: useSignal(config.DisplayUpload || false),
      DisableUnderline: useSignal(config.DisableUnderline || true),
    },
    context
  );

  config.getData = (e: any) => data.value;

  const handlers = setupHandlers(config, context);

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsBinaryString(file); //.readAsArrayBuffer(file);
    });
  };

  const handleFileUpload = async (event) => {
    if (!handlers.onChange) {
      console.error("No file upload handler bound");
      return;
    }

    console.log("fileUpload handler running");

    // TODO: Add support for multiple files
    const file = event.target.files[0];
    if (!file) return;

    try {
      const fileData = await readFileAsync(file);

      data.value = {
        Name: file.name,
        Size: file.size,
        LastModified: file.lastModifiedDate,
        Data: window.btoa(fileData), //  base64ArrayBuffer(fileData),
      };

      handlers.onChange(event);

      // message.value = "File uploaded successfully.";
      // openSnackbar.value = true;
    } catch (error) {
      message.value = "Error uploading file.";
      openSnackbar.value = true;
    }
  };

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleCloseSnackbar = () => {
    openSnackbar.value = false;
  };

  return (
    <>
      {local.Visible.value && (
        <>
          <Input
            {...handlers}
            onChange={handleFileUpload}
            type="file"
            inputRef={inputRef}
            inputProps={{
              accept: ".pdf,.docx",
              style: {
                ...style,
                display: local.DisplayUpload.value ? "block" : "none",
              },
            }}
          />

          <Button
            variant="ai-dialog-button"
            color="primary"
            startIcon={<CloudUpload />}
            onClick={handleButtonClick}
            sx={{ textTransform: "none" }}
          >
            Upload File
          </Button>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar.value}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity={message.value.includes("Error") ? "error" : "success"}>
              {message}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
