import _ from "lodash";
import { RuntimeContext } from "../library/NGFieldExtensions";
import { getTypename, isLayout } from "../library/metadataUtils";
import NGLayoutItem from "../generators/NGLayoutItem";

import { isNil, isString } from "lodash-es";
import { FlexLayout, FreeFormLayout, LayoutItem } from "../../resolvers-types";
import NGLayout from "./NGLayout";

function IsNonVisibleElement(x) {
  const tn = getTypename(x);
  return tn === "ContextMenu";
}

export function renderAsLayoutItemCollection(
  layout: FreeFormLayout | FlexLayout,
  itemMap: any,
  layoutItem: LayoutItem,
  itemWithLayoutStyle: (item: any) => void,
  context: RuntimeContext
) {
  const hiddenItems = layoutItem?.Items?.filter((x) => IsNonVisibleElement(x));
  const jointArray = [...(layout.Items ?? []), ...(hiddenItems ?? [])];

  return jointArray.map((x, i) => {
    const item = isString(x) ? itemMap[x] : x;

    if (isLayout(x)) {
      x.ContextId = (layoutItem as any).ContextId;
      itemWithLayoutStyle(layoutItem);

      return <NGLayout key={i} layoutItem={layoutItem} layout={x} inDesignMode={false} context={context} />;
    } else {
      if (isNil(item)) return null;
      item.ContextId = (layoutItem as any).ContextId;
      itemWithLayoutStyle(item);

      return <NGLayoutItem key={item.Id} config={item} context={context} />;
    }
  });
}

export function renderAsLayoutItem(
  layoutItem: LayoutItem,
  itemWithLayoutStyle: (item: any) => void,
  context: RuntimeContext,
  handlers: any
) {
  if (_.isEmpty(layoutItem.Items)) {
    return <></>;
  }

  return (layoutItem as any).Items?.map((item, i) => {
    if ((layoutItem as any).ContextId) {
      item.ContextId = (layoutItem as any).ContextId;
    }
    itemWithLayoutStyle(item);
    return <NGLayoutItem key={item.Id} config={item} context={context} />;
  });
}
