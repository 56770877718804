import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { INGFeatureSetProps, MenuStyle } from "../../library/NGFieldExtensions";
import NGFeature from "../NGFeature/NGFeature";
import { getTestId, getURLForFeature } from "../../library/utils";
import NGIcon from "../NGIcon/NGIcon";
import { Icon } from "../../../resolvers-types";
import { useNavigate } from "react-router-dom";

function NGFeatureSetHorizontal({ config, context }: INGFeatureSetProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if ((config.Features && config.Features.length) || !config.AllowNavigation) {
      setAnchorEl(event.currentTarget);
    } else {
      navigate(getURLForFeature(config))
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        data-testid={getTestId(config)}
        color="inherit"
        key={"Btn_" + config.Id}
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={
          config.StartIcon && (
            <NGIcon config={config.StartIcon as Icon} context={context} />
          )
        }
        endIcon={config.EndIcon && (
          <NGIcon config={config.EndIcon as Icon} context={context} />
        )}
      >
        {config.Label}
      </Button>
      <Menu
        key={"Mnu_" + config.Id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {config.Features?.map((item) => {
          item.ContextId = config.ContextId;
          item.AllowNavigation = config.AllowNavigation;
          return <NGFeature key={item.Id} config={item} handleClose={handleClose} context={context} />;
        })}
      </Menu>
    </div>
  );
}

function NGFeatureSetVertical({ config, context, handleClose }: INGFeatureSetProps) {
  return (
    <>
      {config.Label}
      {config.Features?.map((feature) => {
        feature.ContextId = config.ContextId;
        return <NGFeature key={feature.Id} config={feature} handleClose={handleClose} context={context} />;
      })}
    </>
  );
}
export default function NGFeatureSet(props: INGFeatureSetProps) {
  switch (props.menuStyle) {
    case MenuStyle.Vertical:
      return <NGFeatureSetVertical {...props} />;
    case MenuStyle.Horizontal:
      return <NGFeatureSetHorizontal {...props} />;
  }
}
