import * as graphql from "../../../resolvers-types";
import { reviewHistoryRepeater } from "../../sampleData/review-history/review-history";
import serviceNotesHistory from "../../sampleData/review-history/service-review-notes-history";
export const userForm: graphql.Form = {
  Id: "userForm",
  UniqueName: "userForm",
  __typename: "Form",
  Items: [
    {
      __typename: "Avatar",
      Name: "Image",
      Id: "Image",
      Source: "Vault",
      Style: {
        borderRadius: "4px",
        width: "2rem",
        height: "2rem",
        background: "linear-gradient(to bottom, #17599a 75%, #033e78);",
        fontSize: "0.9rem",
      },
      Bindings: {
        AlternativeText: "Form.Name",
      },
    },
    {
      __typename: "Label",
      Name: "Id",
      Id: "Id",
      Visible: false,
    },
    {
      __typename: "Label",
      Name: "Name",
      Id: "Name",
      // Style: {
      //   fontSize: "0.8rem",
      // },
    },
    {
      __typename: "Checkbox",
      Name: "IsSelected",
      Id: "IsSelected",
      Style: {
        "&.Mui-checked": {
          color: "#00255d", // Color when checked
        },
      },
    },
  ],
};

export const usersRepeater: graphql.Repeater = {
  Typename: "Repeater",
  UniqueName: "usersRepeater",
  Id: "usersRepeater",
  Bindings: {
    Rows: "State.NGShareUserList",
  },
  Style: {
    maxHeight: "30vh",
    overflow: "auto",
  },
  Name: "Users",
  Content: userForm,
  Layout: {
    Id: "sdqdq",
    __typename: "TableLayout",
    TableStyle: {
      paddingTop: "0.5rem",
    },
    CellStyle: { padding: "4px" },
    ContentItemsProps: [
      {
        ItemId: "Image",
        Style: {
          width: { xs: "15%", md: "7%" },
          maxWidth: "50px",
          // width: "50%",

          // md: { width: "50%" },
          padding: "5px 0px 0px 5px",
        },
      },
      {
        ItemId: "Name",
        Style: {
          width: "77%",
          //backgroundColor: "red",
          //paddingLeft: "4px",
          verticalAlign: "middle",
        },
      },
      {
        ItemId: "IsSelected",
        Style: { width: "8%", padding: "0px", verticalAlign: "middle" },
      },
    ],
  },
};

export const sharePopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "SharePopup",
  HeaderItem: {
    __typename: "Image",
    Id: "1",
    Value: "community-finance-logo.png",
    Height: "auto",
    Width: "40%",
  },

  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",

    Items: [
      {
        Typename: "Form",
        Id: "ShareFormInput",
        UniqueName: "ShareFormInput",
        Items: [
          // {
          //   __typename: "Label",
          //   Id: "0xaedrewr18",
          //   Value: "Select whom to share with",
          // },
          {
            __typename: "InputField",
            Id: "2ewrt",
            Placeholder: "Search",
            Name: "Search",
            Adornment: { Position: "Start", Icon: { IconName: "Search" } },
            Actions: [
              {
                Trigger: "onChange",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "CallService",
                      },
                      Parameters: [
                        { Name: "ServiceName", Value: "ShareUserList" },
                        { Name: "Form", Value: "ShareFormInput" },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          usersRepeater,
          {
            __typename: "Label",
            Id: "0xa18",
            Value: "Text",
            Name: "NoteType",
            Visible: false,
          },

          {
            __typename: "SimpleContainer",
            Id: "0xa1833sd",
            Items: [
              {
                __typename: "Label",
                Id: "0xa1833",
                // Value: "Text",
                DefaultValue: "Message",
                Style: { paddingTop: "4px" },
              },
              {
                __typename: "AudioRecorder",
                Id: "voiceReview",
                DestinationBucket: "reviews",
                DisplayPlayerAfterRecording: true,
                Name: "VoiceNote",
                Bindings: {
                  Visible: "!Form.Text",
                },
              },
            ],
            Layout: {
              __typename: "FlexLayout",
              Id: "0xa1833sdsdcf",
              Direction: "row",
              JustifyContent: "space-between",
              Style: { paddingTop: "2rem" },
              //ItemStyles: { textReview: { width: "90%" } },
            },
          },
          {
            __typename: "InputField",
            Id: "textReview",
            Multiline: true,
            MaxRows: 4,
            //Placeholder: "Message",
            Name: "Text",
            Bindings: {
              Visible: "!Form.VoiceNote",
            },
          },
          {
            __typename: "Button",
            Id: "3",
            Name: "Add",
            Label: "Share",
            Style: {
              marginTop: "20px",
              backgroundColor: "#007dc0",
              color: "white",
              "&:hover": {
                backgroundColor: "#d7e4f0", // Hover state
              },
              // Bindings: { // TODO set enabled property if no users are selected
              //   Visible:
              //     "State.NGShareInfo.Users.filter(function (u) { u.IsSelected }).map(function (u) { u.Name }).length>0",
              // },
            },
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.NGShareInfo": "Form",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "1",
                    },
                    {
                      Id: "1",
                      Instruction: {
                        Name: "Share",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            SharingOptions: "State.NGFeedItem.SharingOptions",
                            Component: "State.NGFeedItem",
                            SelectedUsers:
                              "State.NGShareInfo.Users.filter(function (u) { u.IsSelected }).map(function (u) { u.Id })",
                            NoteType: "Form.Text ? 'Text' : 'Audio'",
                            Text: "Form.Text",
                            File: "Form.VoiceNote",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "2",
                      NextCommandIdOnFailure: "4",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "ClearForm",
                      },
                      Parameters: [{ Name: "Form", Value: "ShareFormInput" }],
                      NextCommandIdOnSuccess: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [
                        { Name: "ModalPopupId", Value: "SharePopup" },
                        { Name: "CommandResult", Value: "Save" },
                      ],
                    },
                    {
                      Id: "4",
                      Instruction: {
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        {
                          Name: "Message",
                          Value: "We were unable to share your message.  Please retry or contact us.",
                        },
                        { Name: "ShowOkButton", Value: true },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          // {
          //   __typename: "Button",
          //   Name: "CloseModal",
          //   Id: "4",
          //   Label: "Close",
          //   Actions: [
          //     {
          //       Trigger: "onClick",
          //       CommandSet: {
          //         FirstCommandId: "1",
          //         ExecuteCommandsInParallel: false,
          //         Commands: [
          //           {
          //             Id: "1",
          //             Instruction: {
          //               Name: "CloseModalPopup",
          //             },
          //             Parameters: [
          //               { Name: "ModalPopupId", Value: "SharePopup" },
          //             ],
          //           },
          //         ],
          //       },
          //     },
          //   ],
          // },
        ],
        Layout: {
          __typename: "LinearLayout",
          Id: "21",
          // SpacingExtraSmall: "1em",
          DirectionExtraSmall: "column",
        },
      } as graphql.Form,
    ],
  } as graphql.SimpleContainer,
};

//InfoPopup
