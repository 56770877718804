import { ITabContainerProps } from "../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import { useComputed, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { getClassName, getStyleObject, getTestId, getsxObject, keyByRec } from "../library/utils";
import { Box, Tab, Tabs } from "@mui/material";
import NGReviewDialog from "../components/NGReviewDialog/NGReviewDialog";
import { containerWithPaper } from "../generators/GeneratorUtils";
import NGLayoutItem from "../generators/NGLayoutItem";
import useResizable from "../hooks/useResizable";

const tag = "NGTabContainer";

export default function NGTabContainer({ config, context }: ITabContainerProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      TabClasses: useSignal(config.TabClasses ?? []),
      Resizable: useSignal(config.Resizable ?? false),
      TabStyle: useSignal(config.TabStyle ?? {}),
      ContentClasses: useSignal(config.ContentClasses ?? []),
      Visible: useSignal(config.Visible ?? true),
      InReviewMode: useSignal(config.InReviewMode || false),
      SelectedTabId: useSignal(
        config.SelectedTabId ?? (config.Items && config.Items.length > 0) ? config.Items[0]?.Id : null
      ),
    },
    context
  );

  const selectedTab = useComputed(() => {
    const index = config.Items?.findIndex((item) => item.Id === local.SelectedTabId.value);
    return index > -1 ? index : 0;
  });

  const handlers = setupHandlers(config, context);

  if (!isNil(config.ReviewDialogOptions)) {
    (config.ReviewDialogOptions as any).ContextId = (config as any).ContextId;
  }

  const { resizable } = useResizable(local.Resizable.value);

  function renderContainer() {
    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
      const { Title, Id } = config.Items[newValue];
      local.SelectedTabId.value = Id;

      if (handlers["onChange"]) {
        handlers["onChange"](e, { Index: newValue, Id, Title });
      }
    };

    return (
      <Box data-testid={getTestId(config)} data-type={config.__typename} className={getClassName(local.Classes)}>
        <Tabs
          value={selectedTab.value}
          aria-label={config.AriaLabel ?? "tabs"}
          className={getClassName(local.TabClasses)}
          sx={getsxObject(local.Style.value)}
          TabIndicatorProps={{ sx: config.TabIndicatorStyles }}
          {...handlers}
          onChange={handleChange}
        >
          {config.Items?.map((tab, key) => {
            //return <NGTabItem key={key} config={tab} context={context} />;
            return (
              <Tab
                key={key}
                disabled={false}
                label={
                  <NGLayoutItem
                    key={key}
                    config={{ __typename: "Label", Id: tab.Id, Value: tab.Title }}
                    context={context}
                  />
                }
                sx={getStyleObject(local.TabStyle.value, { textTransform: "none" })}
                className={getClassName(local.TabClasses.value)}
              />
            );
            //return <NGLayoutItem key={key} config={tab} context={context} />;
          })}
        </Tabs>
        <div className={getClassName(local.ContentClasses)}>
          {config.Items?.map((tab, tabkey) => {
            return tab.Items?.map((item, key) => {
              if (isNil(item)) {
                return <></>;
              }
              // The Box below is necessary to avoid error: "Each child in a list should have a unique "key" prop"
              return (
                <Box key={`${tabkey}-${key}`}>
                  {selectedTab.value === tabkey && <NGLayoutItem config={item} context={context} />}
                </Box>
              );
            });
          })}
        </div>
        {local.InReviewMode.value && (
          <NGReviewDialog config={config.ReviewDialogOptions ?? {}} context={context}></NGReviewDialog>
        )}
      </Box>
    );
  }

  return resizable(containerWithPaper(local, config, renderContainer, context));
}
