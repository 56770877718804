import { assign, cloneDeep, isNil, isString } from "lodash-es";
import { Component, Page, Service } from "../../resolvers-types";
import { ObjectCache } from "./ObjectCache";
import { client } from "./nats-client";
import { Result } from "../models/result";
import { setupServices } from "./dataService";
import { log } from "./logger";
import { serviceSnapshotTest } from "../sampleData/pinnedData";
import { generateGuid, isNullOrEmpty } from "./utils";
import { RuntimeContext } from "./NGFieldExtensions";

const maxCacheSize = 30;
const componentCache = new ObjectCache<Component>(maxCacheSize);
const serviceCache = new ObjectCache<Service>(maxCacheSize);

const tag = "NGFeed";

export async function GetComponent(componentId) {
  //   console.log(
  //     "NGFeed componentCache hit try has",
  //     componentId,
  //     componentCache.has(componentId),
  //     componentCache.cache
  //   );
  if (componentCache.has(componentId)) {
    log.info(tag, "componentCache hit", componentId);
    return componentCache.get(componentId);
  }

  log.info(tag, "callService request", "project.component.get");
  //await delay(5000);
  const resp = (await client.request("project.component.get", {
    Id: componentId,
    Type: "Component",
  })) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "callService project.component.get failed", resp?.reasons);
    return false;
  }

  log.info(tag, "callService project.component.get return", resp?.data.Entity);

  //   console.log(
  //     "NGFeed componentCache set",
  //     componentId,
  //     resp?.data.Entity,
  //     componentCache.has(componentId),
  //     componentCache.get(componentId),
  //     componentCache.cache
  //   );
  componentCache.set(componentId, resp?.data.Entity);
  return resp?.data.Entity;
}

export async function GetService(serviceId) {
  if (serviceCache.has(serviceId)) {
    log.info(tag, "serviceCache hit", serviceId);

    return serviceCache.get(serviceId);
  }

  log.info(tag, "callService request", "project.service.get");
  //await delay(5000);
  const resp = (await client.request("project.service.get", {
    Id: serviceId,
  })) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "callService project.service.get failed", resp?.reasons);
    return false;
  }

  log.info(tag, "callService project.service.get return", resp?.data.Entity);

  serviceCache.set(serviceId, resp?.data.Entity);
  return resp?.data.Entity;
}

export function replaceServiceIdWithFullService(comps: Component[], services: Service[]) {
  comps.forEach((c) => {
    if (isNil(c.Services)) return;

    const s0 = [...c.Services];
    c.Services = [];

    s0.forEach((serviceId) => {
      if (isNil(serviceId)) return;

      // The serviceId is a full service object, so no need to do any replacements
      if (!isString(serviceId)) c.Services?.push(serviceId);
      else {
        const s1 = services.find((s2) => s2.Id == serviceId);
        if (isNil(s1)) return;

        c.Services?.push(cloneDeep(s1));
      }
    });
  });
}

function appendServicePromises(servicePromises, services) {
  if (isNil(services)) return;

  services.forEach((serviceId) => {
    if (!isString(serviceId)) {
      servicePromises.push(
        new Promise((resolve) => {
          resolve(serviceId);
        })
      );
    } else {
      servicePromises.push(GetService(serviceId));
    }
  });
}

function getSlicedPromises(components: Component[], start: number, end: number) {
  const componentPromises: Promise<Component>[] = [];
  const servicePromises: Promise<Service>[] = [];

  components.slice(start, end).forEach(async (component) => {
    if (isNil(component)) return;
    log.info(tag, "start getting component", component.Id);

    if (isNullOrEmpty(component.Services)) {
      component.Services = [];
    }

    // This gets the promises for the components and services
    // and appends them to the promises' arrays, ie componentPromises & servicePromises
    componentPromises.push(GetComponent(component.Id));
    appendServicePromises(servicePromises, component.Services);
  });

  return { componentPromises, servicePromises };
}

function getSlicedPromisesForServices(serviceIds: string[], start: number, end: number) {
  const servicePromises: Promise<Service>[] = [];

  serviceIds.slice(start, end).forEach(async (id) => {
    if (isNil(id)) return;
    servicePromises.push(GetService(id));
  });

  return servicePromises;
}

export async function getAllComponentsFromIds(ids: string[]) {
  const components: Component[] = ids.map((id) => ({ Id: id }) as Component);

  return await getComponentsThenServices(components, 0, components.length);
}

/// Fetches the components and services for the feed from position start to end
/// 1. Gets the promises for fetching the components and the services
/// 2. Waits for the promises to resolve
/// 3. Sets the full service metadata into the component
/// 4. Sets up the services
/// 5. Returns the components with the full metadata
export async function getComponentAndServiceMetadata(
  components: Component[],
  start: number,
  end: number,
  context: RuntimeContext
) {
  let comps: Component[] = [];
  let services: Service[] = [];
  const maxComps = Math.min(end, components.length);

  const { componentPromises, servicePromises } = getSlicedPromises(components, start, maxComps);

  // Components is what we got from the feed.
  // comps is what we retrieve with full metadata
  comps = comps.concat(await Promise.all(componentPromises));
  services = services.concat(await Promise.all(servicePromises));

  log.info(tag, `finished getting components and services for ${start} to ${maxComps}`, comps, services);

  for (let i = 0; i < components.length; i++) {
    const c = components[i];
    const c2 = comps.find((c2) => c2.Id == c.Id);

    components[i] = { ...c2, ...components[i], __typename: "Component" };
  }

  // This takes the component.Service =['serviceId1', 'serviceId2']
  // and replaces it with the full service object in the component
  replaceServiceIdWithFullService(components, services);

  // Apply service snapshots, if any
  components.forEach((c) => {
    if (isNil(c.SharingInfo) || isNil(c.SharingInfo.ServiceSnapshots)) return;

    c.SharingInfo.ServiceSnapshots.forEach((snapshot) => {
      if (isNil(snapshot)) return;
      if (isNil(c.Services)) return;
      const serviceIndex = c.Services?.findIndex((s) => s?.Id == snapshot.Id);

      if (isNil(serviceIndex) || serviceIndex < 0) return;

      c.Services[serviceIndex] = {
        ...c.Services[serviceIndex],
        ...snapshot,
        Id: `${snapshot.Id}_${c.InstanceId}`,
        Name: `${snapshot.Id}_${c.InstanceId}`,
      };

      c.Inputs = {};

      c.Id = c.InstanceId as string;
      // override the properties of the service with those coming from the service snapshot in the share
    });
  });

  // services = components.map((c) => c.Services).flat() as Service[];

  // const cleanup = setupServices({ Services: services }, context);
  const cleanup = () => { };

  return { comps: components, services, cleanup };
}

async function getComponentOnlyMetadata(components: Component[], start: number, end: number) {
  let comps: Component[] = [];
  const maxComps = Math.min(end, components.length);

  const { componentPromises, servicePromises } = getSlicedPromises(components, start, maxComps);

  comps = comps.concat(await Promise.all(componentPromises));

  // console.log(`NGFeed finished getting components only for ${start} to ${maxComps}`, comps);

  return comps;
}

async function getServicesFromIds(serviceIds: string[]) {
  let services: Service[] = [];
  const servicePromises = getSlicedPromisesForServices(serviceIds, 0, serviceIds.length);

  services = services.concat(await Promise.all(servicePromises));
  return services;
}

async function getServicesFromFullComponentMetadata(components: Component[], start: number, end: number) {
  let services: Service[] = [];
  const maxComps = Math.min(end, components.length);

  const { componentPromises, servicePromises } = getSlicedPromises(components, start, maxComps);

  services = services.concat(await Promise.all(servicePromises));

  return services;

  //   // This takes the component.Service =['serviceId1', 'serviceId2']
  //   // and replaces it with the full service object in the component
  //   replaceServiceIdWithFullService(components, services);

  //   setupServicesStrict({ Services: services });
}

async function getComponentsThenServices(components: Component[], start: number, end: number) {
  return getComponentOnlyMetadata(components, start, end).then((comps) => {
    return getServicesFromFullComponentMetadata(comps, start, end).then((services) => {
      return { comps, services };
    });
  });
}

async function getReferencedComponents(referencedComponentIds: string[]) {
  if (isNil(referencedComponentIds)) return [];

  return await getAllComponentsFromIds(referencedComponentIds);
}

function mergeObjects<T>(a1: T[], a2: T[]): T[] {
  // Create a map to store objects by id, giving precedence to a1
  const objectMap: Record<number, T> = {};

  // Add elements from a1 to the map
  for (const obj1 of a1) {
    objectMap[(obj1 as any).Id] = obj1;
  }

  // Add elements from a2 to the map, but only if they are not in a1
  for (const obj2 of a2) {
    if (!((obj2 as any).Id in objectMap)) {
      objectMap[(obj2 as any).Id] = obj2;
    }
  }

  // Extract the values from the map to create the result array
  return Object.values(objectMap);
}

async function setupReferencedComponentsInpage(page: Page) {
  const componentReferenceIds = page.ComponentReferenceIds;

  if (isNil(componentReferenceIds)) return;

  const nc = await getReferencedComponents(componentReferenceIds);

  if (isNil(nc) || isNil((nc as any).comps)) return;

  const comps = (nc as any).comps as Component[];

  // Add or replace the containers in the page
  page.Items = mergeObjects(
    page.Items || [],
    comps.map((c) => {
      return { ...c.ComponentContainer, IsReadOnlyReference: true };
    })
  );

  if (isNil((nc as any).services)) return;

  const services = (nc as any).services as Service[];

  page.Services = mergeObjects(
    page.Services || [],
    services.map((s) => {
      return { ...s, IsReadOnlyReference: true };
    })
  );
}

export async function setupReferencedComponentsAndServicesInpage(page: Page) {
  setupReferencedComponentsInpage(page);

  const serviceReferenceIds = page.ServiceReferenceIds;

  if (isNil(serviceReferenceIds)) return;

  const services = await getServicesFromIds(serviceReferenceIds);

  page.Services = mergeObjects(
    page.Services || [],
    services.map((s) => {
      return { ...s, IsReadOnlyReference: true };
    })
  );

  return "";
}
