import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import theme from "./theme.ts";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { NGRouter } from "./NGRouter.tsx";
import { registerServiceWorker } from "./library/utils.ts";
import "./library/native";
import tracing from "./library/tracing.ts";

// registerServiceWorker();

tracing.createAnnonymousId();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <StrictMode>
  <ThemeProvider theme={theme}>
    <DndProvider backend={HTML5Backend}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <RouterProvider router={NGRouter} />
    </DndProvider>
  </ThemeProvider>
  // </StrictMode>
);
