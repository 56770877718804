import * as graphql from "../../../resolvers-types";

export const propsEditorForm: graphql.Form = {
  Typename: "Form",
  UniqueName: "0xa1733",
  Id: "0xa1733",
  Items: [
    {
      __typename: "Label",
      Id: "L/1",
      DefaultValue: "First Name",
    },
    {
      __typename: "InputField",
      Id: "F/1",
      Name: "FirstName",
      DefaultValue: "Søren",
    },
    {
      __typename: "Label",
      Id: "L/2",
      DefaultValue: "Last Name",
    },
    {
      __typename: "InputField",
      Id: "F/2",
      Name: "LastName",
      DefaultValue: "Kierkegaard",
    },
  ],
  Layout: {
    __typename: "LinearLayout",
    Id: "0xc0001",
    Direction: "column",
    AlignItems: "space-evenly",
    InnerLayouts: [
      {
        __typename: "LinearLayout",
        Id: "0xc0002",
        Direction: "row",
        LayoutItemProps: [
          {
            ItemId: "L/1",
            FlexBasis: "40%",
          },
          {
            ItemId: "F/1",
            FlexBasis: "60%",
            FlexGrow: "1",
          },
        ],
      },
    ],
  },
};
/*
        Layout: {
          __typename: "LinearLayout", // Top
          Id: "0xc0003",
          Spacing: "2",
          Direction: "column",
          InnerLayouts: [
            {
              __typename: "LinearLayout", //Top2a
              Id: "0xc0003/1",
              Spacing: "2",
              Direction: "row",
              ItemAlignment: "space-between",
              InnerLayouts: [
                {
                  __typename: "LinearLayout", //Top3a
                  Id: "0xc0003/1/1",
                  Direction: "row",
                  LayoutItemIds: ["1", "2", "3"],
                },
                {
                  __typename: "LinearLayout", //Top3b
                  Id: "0xc0003/1/2",
                  Direction: "row",
                  LayoutItemIds: ["4"],
                },
              ],
            },
            {
              __typename: "LinearLayout", // Top2b
              Id: "0xc0003/2",
              Spacing: "2",
              LayoutItemIds: ["5", "6", "7", "8"],
            },
          ],
        },
        */
