import * as graphql from "../../../resolvers-types";
import { formInGridStack } from "./gridStackComponent";
import { propsEditorForm } from "./props-editor";

const designModeForm: graphql.Form = {
  Id: "0xa100",
  UniqueName: "InDesignForm",
  __typename: "Form",
  Items: [
    {
      __typename: "Label",
      Sequence: 30,
      Name: null,
      DefaultValue:
        "Toggle this switch to go into design mode for the page.  This is also an example of text wrapping but only after 2 lines:",
      FieldType: null,
      Header: null,
      HeaderRank: null,
      MultiLine: true,
      NumberOfLines: 1,
      Id: "0x3486543",
    },
    {
      __typename: "Switch",
      Sequence: 31,
      Name: "InDesignMode",
      Editable: null,
      FieldType: null,
      Id: "0x3539",
      Bindings: {
        Value: "State.PageInDesignMode",
      },
    },
  ],
};

const compTestPage: graphql.Page = {
  __typename: "Page",
  Id: "0xa5",
  InDesignMode: false,
  Name: "My CRM Dashboard",

  Bindings: {
    InDesignMode: "State.PageInDesignMode",
  },
  State: {},
  Services: [
    {
      Name: "ShareUserList",
      Service: "sharing.users.query",
      Require: "State.Search",
      Fields: [
        {
          Name: "Filter",
          Required: true,
          Value: {},
          Bindings: {
            Value: "{ Name: State.Search }",
          },
        },
      ],
      Bindings: {
        "State.NGShareUserList": "Result.Items",
      },
    },
  ],
  Items: [
    {
      __typename: "Form",
      Id: "UsersList",
      UniqueName: "UsersList",
      Items: [
        {
          __typename: "MultiSelect",
          Name: "Users",
          Id: "/2/2/",
          Label: "Search as you type example",
          LabelExpression: "Name",
          ValueExpression: "Id",
          Bindings: {
            MultiSelectPossibleValues: "State.NGShareUserList",
            SearchFilter: "State.Search",
          },
        } as graphql.MultiSelect,
      ],
    },

    {
      __typename: "SimpleContainer",
      Id: "0xa7322",
      UniqueName: "DesignSwicth",
      Items: [designModeForm],
    },
    {
      __typename: "SimpleContainer",
      Id: "0xa8",
      UniqueName: "ClientList",
      InDesignMode: false,
      Items: [
        {
          __typename: "List",
          GridHeight: "700px",
          Id: "0xd0001",
          UniqueName: "ClientList",
          ListColumns: [
            {
              __typename: "ListColumn",
              Id: "0xd0002",
              HeaderName: "First Name",
              Name: "FirstName",
              MinWidth: 150,
            } as graphql.ListColumn,
            {
              __typename: "ListColumn",
              Id: "0xd0003",
              HeaderName: "Last Name",
              Name: "LastName",
              MinWidth: 150,
            } as graphql.ListColumn,
            {
              __typename: "ListColumn",
              Id: "0xd0004",
              HeaderName: "Company",
              Name: "Company",
              MinWidth: 250,
            } as graphql.ListColumn,
            {
              __typename: "ListColumn",
              Id: "0xd0005",
              HeaderName: "Position",
              Name: "Position",
              MinWidth: 250,
            } as graphql.ListColumn,
            {
              __typename: "ListColumn",
              Id: "0xd0006",
              HeaderName: "Marital Status",
              Name: "MaritalStatus",
              MinWidth: 200,
            } as graphql.ListColumn,
            {
              __typename: "ListColumn",
              Id: "0xd0007",
              HeaderName: "Interests",
              Name: "Interests",
              MinWidth: 300,
            } as graphql.ListColumn,
          ],
        } as graphql.List,
        {
          __typename: "Label",
          Id: "0xa18s",
          Sequence: 1,
          DefaultValue: "Client List",
        },
      ],
      Layout: {
        __typename: "GridStackLayout",
        Id: "0xc00021",
        LayoutItem: {
          __typename: "Page",
          Id: "0xa7322",
          Name: "My CRM Dashboard",
        },
        InnerLayouts: [
          { X: 11, Y: 10, W: 3, H: 18, LayoutItem: { Id: "0xa18s" } },
          { X: 0, Y: 31, W: 24, H: 404, LayoutItem: { Id: "0xd0001" } },
        ],
      },
    },
    {
      __typename: "SimpleContainer",
      Id: "0xa9",
      UniqueName: "ClientDetails",
      Items: [
        {
          Typename: "Form",
          Id: "0xa17",
          Items: [
            {
              __typename: "Image",
              Id: "745432",
              Height: "200px",
              Value:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/S%C3%B8ren_Kierkegaard_%281813-1855%29_-_%28cropped%29.jpg/330px-S%C3%B8ren_Kierkegaard_%281813-1855%29_-_%28cropped%29.jpg",
            },
            {
              __typename: "FileUpload",
              Id: "7",
              LabelIdle: "And here you may upload a file",
            },
            {
              __typename: "Label",
              Id: "0xa18",
              Sequence: 1,
              DefaultValue: "First Name",
            },
            {
              __typename: "InputField",
              Id: "0xa19",
              Name: "FirstName",
              Sequence: 2,
              DefaultValue: "Søren",
            },
            {
              __typename: "Label",
              Id: "0xa20",
              Sequence: 3,
              DefaultValue: "Last Name",
            },
            {
              __typename: "InputField",
              Id: "0xa21",
              Name: "LastName",
              Sequence: 4,
              DefaultValue: "Kierkegaard",
            },
            {
              __typename: "Label",
              Id: "0xa204",
              Sequence: 5,
              DefaultValue: "Full Name",
            },
            {
              __typename: "InputField",
              Id: "0xa214",
              Name: "FullName",
              Sequence: 6,
              Bindings: {
                Value: "Form.FirstName + ' ' + Form.LastName",
              },
            },
            {
              __typename: "Label",
              Id: "0xa22",
              Sequence: 5,
              DefaultValue: "Company",
            },
            {
              __typename: "InputField",
              Id: "0xa23",
              Name: "Company",
              Sequence: 6,
              DefaultValue: "Københavns Universitet",
            },
            {
              __typename: "Label",
              Id: "0xa24",
              Sequence: 7,
              DefaultValue: "Key Ideas",
              HeaderRank: 6,
              Header: true,
            },
            {
              __typename: "Label",
              Id: "0xa25",
              Name: "Position",
              Sequence: 8,
              MultiLine: true,
              NumberOfLines: 4,
              DefaultValue:
                "Subjectivity and Truth: Kierkegaard believed that truth is subjective and is found in the individual's experience and existence. He argued against the objective truths of science and mathematics, stating that the most important truths are personal and subjective.Existential Anxiety and Despair: He highlighted the importance of personal choice and commitment. He believed that anxiety and despair arise from confronting the reality of choice and the responsibility of shaping one's own existence.",
            },
            {
              __typename: "Label",
              Id: "0xa26",
              Sequence: 9,
              DefaultValue: "Marital Status",
            },
            {
              __typename: "RadioGroup",
              Sequence: 10,
              Name: "MaritalStatus",
              DefaultValue: "None of the above",
              Editable: null,
              FieldType: null,
              Id: "0xa27",
              ServerField: null,
              Visible: null,
              Width: "100",
              CanBeDesigned: true,
              MultiSelectPossibleValues: ["Married", "Single", "Divorced", "Widowed", "None of the above"],
            },
            {
              __typename: "Label",
              Sequence: 11,
              Name: null,
              DefaultValue: "What is your favourite topic?",
              FieldType: null,
              Header: null,
              HeaderRank: null,
              Id: "0xa28",
              MultiLine: null,
              NumberOfLines: null,
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
            },
            {
              __typename: "MultiSelect",
              Sequence: 12,
              Name: "Interests",
              DefaultValue: "Modern Philosophy",
              Editable: null,
              FieldType: null,
              Id: "0xa29",
              ServerField: null,
              Visible: null,
              Width: "100",
              CanBeDesigned: true,
              MultiSelectPossibleValues: [
                "18th and 19th Century Philosophy",
                "History of Philosophy",
                "History of Reception",
                "Modern Christian Thinkers and Philosophers of Religion",
                "Modern Church History (19th and 20th Centuries)",
                "Modern Philosophy",
                "Old Testament / Ancient Near East",
              ],
            },
            {
              __typename: "Label",
              Sequence: 70,
              Name: null,
              DefaultValue: "Existing Client?",
              FieldType: null,
              Header: null,
              HeaderRank: null,
              Id: "0xfffd8d73852dd9a",
              MultiLine: null,
              NumberOfLines: null,
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
            },
            {
              __typename: "Checkbox",
              Sequence: 71,
              Name: "ExistingClient",
              Label: "Registered",
              Editable: null,
              FieldType: null,
              Id: "0xfffd8d73852dfda1",
              ServerField: null,
              Visible: null,
              Width: "100",
              CanBeDesigned: true,
              /*
              "MultiSelectPossibleValues": ["Individualism and subjectivity",
                                            "Freedom and responsibility",
                                            "Epistemology and metaphysics",
                                            "Anguish and absurdity"]*/
            },
            {
              __typename: "Label",
              Sequence: 80,
              Name: null,
              DefaultValue: "Year registered",
              FieldType: null,
              Header: null,
              HeaderRank: null,
              Id: "0xfff9d72852dffe2",
              MultiLine: null,
              NumberOfLines: null,
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
            },
            {
              __typename: "InputField",
              Sequence: 81,
              Name: "YearRegistered",
              DefaultValue: "2022",
              Editable: null,
              FieldType: graphql.FieldType.Integer,
              Id: "0xfff1d72852dffdb",
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
              ValidationRules: [
                {
                  ValidationMethod: ">=",
                  Parameters: ["2003"],
                  ErrorMessage: "No clients registered before 2003",
                },
                {
                  ValidationMethod: "<",
                  Parameters: ["2024"],
                  ErrorMessage: "No clients registered after 2023",
                },
              ],
            },

            {
              __typename: "Label",
              Sequence: 40,
              Name: null,
              DefaultValue: "Happiness Score",
              FieldType: null,
              Header: null,
              HeaderRank: null,
              Id: "0xfffd8d72853df9a",
              MultiLine: null,
              NumberOfLines: null,
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
            },
            {
              __typename: "Slider",
              Sequence: 41,
              Name: "HappinessScore",
              DefaultValue: 30,
              Editable: null,
              FieldType: null,
              Id: "0xfffd8d72852dfff9",
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
              Min: 0,
              Max: 100,
              Size: "Large",
            },
            {
              __typename: "Label",
              Sequence: 30,
              Name: null,
              DefaultValue: "Active?",
              FieldType: null,
              Header: null,
              HeaderRank: null,
              Id: "0xfffd8d72852df5a",
              MultiLine: null,
              NumberOfLines: null,
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
            },
            {
              __typename: "Switch",
              Sequence: 31,
              Name: "ActiveClient",
              Editable: null,
              FieldType: null,
              Id: "0xfffd8d72853dfff7",
              ServerField: null,
              Visible: null,
              Width: null,
              CanBeDesigned: true,
              Value: true,
            },
            {
              __typename: "Button",
              Sequence: 200,
              Name: "Submit",
              Label: "Save your changes",
              Id: "0xfffd8d73852dfdb3",
              //"Visible": null,
              //"Width": "100",
              CanBeDesigned: true,
            },
          ],
          Layout: {
            __typename: "LinearLayout",
            Id: "0xc0007",
            DirectionExtraSmall: "column",
          },
          UniqueName: "ClientDetails",
          Service: "Clients",
        } as graphql.Form,
      ],
    },
    // {
    //   __typename: "SimpleContainer",
    //   Id: "0xa100",
    //   UniqueName: "DesignDialog",
    //   Items: [
    //     {
    //       Typename: "Form",
    //       Id: "0xa101",
    //       Items: [
    //         {
    //           __typename: "AIDialog",
    //           Sequence: 1,
    //           Placeholder: "Hello world!",
    //         },
    //       ],
    //     } as graphql.Form,
    //   ],
    // },

    {
      __typename: "SimpleContainer",
      Id: "0xa300",
      UniqueName: "KPI",
      Items: [formInGridStack],
    },
    {
      __typename: "Label",
      Sequence: 30,
      Name: null,
      DefaultValue: "Sample Component page",
      FieldType: null,
      Header: true,
      HeaderRank: 1,
      MultiLine: false,
      Id: "H1Title",
    },
    propsEditorForm,
    {
      __typename: "TabContainer",
      Id: "0xa1733sd",
      Items: [
        {
          __typename: "TabItem",
          Id: "dffgs",
          Title: "Tab 1",
          Name: "Tab 1",
          Items: [
            {
              __typename: "Label",
              Value: "Label in tab1",
              Id: "wfer345",
            },
          ],
        },
        {
          __typename: "TabItem",
          Id: "dffg345s",
          Title: "Tab 2",
          Name: "Tab 2",
          Items: [
            {
              __typename: "Label",
              Value: "Label in tab2",
              Id: "wfer34ewr5",
            },
          ],
        },
      ],
    } as graphql.TabContainer,
    {
      __typename: "Chart",
      Id: "ChartApp1",
      UniqueName: "QualifiedLeads",
      Service: "QualifiedLeads",
      ChartLibrary: "Highcharts",
      ChartMetadata:
        '{"title":{"text":"Total Sales"},"subtitle":{"text":""},"exporting":{},"chart":{"type":"column","polar":false},"yAxis":{"title":{"text":"Sales in $"},"labels":{}},"series":[{"lineWidth":1,"allowPointSelect":false,"crisp":true,"showCheckbox":false,"animation":false,"enableMouseTracking":true,"events":{},"point":{"events":{}},"dataLabels":{"animation":{},"borderWidth":0,"defer":true,"padding":5,"style":{"fontSize":"0.7em","fontWeight":"bold","color":"contrast","textOutline":"1px contrast"},"x":0},"cropThreshold":50,"opacity":1,"pointRange":null,"softThreshold":true,"states":{"normal":{"animation":true},"hover":{"animation":{"duration":150},"lineWidthPlus":1,"marker":{},"halo":false,"brightness":0.1},"select":{"animation":{"duration":0},"color":"#cccccc","borderColor":"#000000"},"inactive":{"animation":{"duration":150},"opacity":0.2}},"stickyTracking":false,"turboThreshold":0,"findNearestPointBy":"x","borderRadius":3,"centerInCategory":false,"groupPadding":0.2,"pointPadding":0.1,"minPointLength":0,"startFromThreshold":true,"tooltip":{"distance":6},"threshold":0,"borderColor":"#ffffff","name":"TotalSales","dataGrouping":{"groupPixelWidth":10,"dateTimeLabelFormats":{"millisecond":["%A, %e %b, %H:%M:%S.%L","%A, %e %b, %H:%M:%S.%L","-%H:%M:%S.%L"],"second":["%A, %e %b, %H:%M:%S","%A, %e %b, %H:%M:%S","-%H:%M:%S"],"minute":["%A, %e %b, %H:%M","%A, %e %b, %H:%M","-%H:%M"],"hour":["%A, %e %b, %H:%M","%A, %e %b, %H:%M","-%H:%M"],"day":["%A, %e %b %Y","%A, %e %b","-%A, %e %b %Y"],"week":["Week from %A, %e %b %Y","%A, %e %b","-%A, %e %b %Y"],"month":["%B %Y","%B","-%B %Y"],"year":["%Y","%Y","-%Y"]}}}],"plotOptions":{"series":{"animation":false}},"xAxis":{"title":{}}}',
    } as graphql.Chart,
  ],
  //ComponentReferenceIds: ["prices-single-stock"],
  Layout: {
    __typename: "GridStackLayout",
    Id: "0xc0001",
    LayoutItem: {
      __typename: "Page",
      Id: "0xa5",
      Name: "My CRM Dashboard",
    },
    InnerLayouts: [
      //
      { X: 0, Y: 10, W: 24, H: 25, LayoutItem: { Id: "H1Title" } },
      { X: 0, Y: 10 + 25, W: 10, H: 40, LayoutItem: { Id: "UsersList" } },
      {
        X: 0,
        Y: 89,
        W: 6,
        H: 33,
        FitToContent: false,
        LayoutItem: { Id: "0xa7322" },
      },
      {
        X: 0,
        Y: 122,
        W: 6,
        H: 704,
        FitToContent: false,
        LayoutItem: { Id: "0xa9" },
      },
      {
        X: 7,
        Y: 136,
        W: 17,
        H: 234,
        FitToContent: false,
        LayoutItem: { Id: "ChartApp1" },
      },
      {
        X: 13,
        Y: 370,
        W: 11,
        H: 73,
        FitToContent: false,
        LayoutItem: { Id: "0xa300" },
      },
      { X: 7, Y: 374, W: 4, H: 63, LayoutItem: { Id: "0xa1733sd" } },
      { X: 7, Y: 443, W: 4, H: 244, LayoutItem: { Id: "0xa1733" } },
      {
        X: 11,
        Y: 443,
        W: 13,
        H: 455,
        FitToContent: false,
        LayoutItem: { Id: "0xa8" },
      },
    ],
  },
};

export default compTestPage;
