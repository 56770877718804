import { Box, FormControl, FormLabel, Stack } from "@mui/material";

import DimensionField from "../NGDimensionEditor";
import MenuButtonEditor from "../NGMenuButtonEditor";
import { SquareFoot, UnfoldLess, UnfoldMore } from "@mui/icons-material";

export default function NGDimensionPositionStylesEditor({ selected, layout }) {
  const showXY = !layout?.IgnoreLayout && layout?.Layout?.__typename === "FreeFormLayout";

  const getWidthSelection = () => {
    const { width, alignSelf } = selected.value;

    if (width === "fit-content") return 1;
    if (width === "100%") return 2;
    if (!!width || !!alignSelf) return 0;
    return null;
  };

  const getHeightSelection = () => {
    const { height } = selected.value;
    if (height === "100%") return 2;
    if (height === "fit-content") return 1;
    if (height) return 0;
    return null;
  };

  const widthMenuSelectedIndex = getWidthSelection();
  const heightMenuSelectedIndex = getHeightSelection();

  const handlePropertyChange = (value: object) => {
    const c = { ...selected.value };
    const o1 = Object.keys(value)[0]
    const o2 = Object.values(value)[0]
    if (!o2) {
      delete c?.[o1]
    } else {
      c[o1] = o2;
    }
    selected.value = c;
  };

  const widthMenuOptions = [
    {
      Text: "Fixed width",
      Label: "Fixed",
      Icon: <SquareFoot fontSize="inherit" className="label-color" />,
      Value: { width: "auto", flex: "none" },
    },
    {
      Text: "Hug contents",
      Label: "Hug",
      Icon: <UnfoldLess fontSize="inherit" className="icon-rotate-90 label-color" />,
      Value: { width: "fit-content", flex: "none" },
    },
    {
      Text: "Fill container",
      Label: "Fill",
      Icon: <UnfoldMore fontSize="inherit" className="icon-rotate-90 label-color" />,
      Value: { width: "100%", flex: "initial" },
    },
  ];

  const heightMenuOptions = [
    {
      Text: "Fixed height",
      Label: "Fixed",
      Icon: <SquareFoot fontSize="inherit" className="label-color" />,
      Value: { height: "auto", flex: "none" },
    },
    {
      Text: "Hug contents",
      Label: "Hug",
      Icon: <UnfoldLess fontSize="inherit" className="label-color" />,
      Value: { height: "fit-content", flex: "none" },
    },
    {
      Text: "Fill container",
      Label: "Fill",
      Icon: <UnfoldMore fontSize="inherit" className="label-color" />,
      Value: {
        height: "100%",
        flex: "initial",
      },
    },
  ];

  const { left: xPosition, top: yPosition, width = "", height = "" } = selected.value;
  return (
    <Stack direction="column">
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "1rem" }}>
        {showXY && (
          <>
            <DimensionField
              label="X"
              field="left"
              value={String(xPosition || "")}
              styleSelected={selected.value}
              onValueChange={(e, value) => handlePropertyChange(value)}
              valueType="text"
            />
            <DimensionField
              label="Y"
              field="top"
              valueType="text"
              value={String(yPosition || "")}
              styleSelected={selected.value}
              onValueChange={(e, value) => handlePropertyChange(value)}
            />
          </>
        )}
      </Box>
      <Stack sx={{ flexDirection: "row", gap: "0.5rem", alignItems: "flex-start" }}>
        <Stack sx={{ flexDirection: "column", alignItems: "flex-start", gap: "0.2rem" }}>
          <FormControl component="fieldset" sx={{ marginLeft: "2rem" }}>
            <FormLabel component="legend" className="control-label">
              Width
            </FormLabel>
          </FormControl>
          <MenuButtonEditor
            name="width-selector"
            options={widthMenuOptions}
            selectedIndex={widthMenuSelectedIndex}
            onChange={handlePropertyChange}
          />
          <DimensionField
            label="W"
            field="width"
            value={String(width || "")}
            valueType="text"
            styleSelected={selected.value}
            styles={{ flex: 1 }}
            sizeField
            onValueChange={(e, value, resetMenu) => {
              handlePropertyChange({ ...value });
            }}
          />
        </Stack>
        <Stack sx={{ flexDirection: "column", alignItems: "flex-start", gap: "0.2rem" }}>
          <FormControl component="fieldset" sx={{ marginLeft: "2rem" }}>
            <FormLabel component="legend" className="control-label">
              Height
            </FormLabel>
          </FormControl>
          <MenuButtonEditor
            name="height-selector"
            options={heightMenuOptions}
            selectedIndex={heightMenuSelectedIndex}
            onChange={handlePropertyChange}
          />
          <DimensionField
            label="H"
            field="height"
            valueType="text"
            value={String(height || "")}
            sizeField
            styles={{ flex: 1 }}
            styleSelected={selected.value}
            onValueChange={(e, value, resetMenu) => {
              handlePropertyChange(value);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
