import { INGLinkProps } from "../../library/NGFieldExtensions";
import { Link } from "@mui/material";
import { useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, getClassName } from "../../library/utils";

const tag = "NGLink";

export default function NGLink({ config, context }: INGLinkProps) {
  const local = setupLocalState(
    config,
    {
      Label: useSignal(config.Label ?? ""),
      URL: useSignal(config.URL ?? ""),
      Title: useSignal(config.Title ?? ""),
      Target: useSignal(config.Target ?? ""),
      Visible: useSignal(config.Visible != false ? true : config.Visible),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <>
      {local.Visible.value && (
        <Link
          data-testid={getTestId(config)}
          data-type={config.__typename}
          target={local.Target.value}
          href={local.URL.value}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes, context)}
          title={local.Title.value}
        >
          {local.Label.value}
        </Link>
      )}
    </>
  );
}
