import {
  Box,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SpaceBarSharpIcon from "@mui/icons-material/SpaceBarSharp";
import NGDimensionPositionStylesEditor from "./NGDimensionPositionStylesEditor";
import ColorPicker from "../NGColorPicker/NGColorPicker";
import TextStylesEditor from "../NGTextStylesEditor/NGTextStylesEditor";
import {
  Add,
  BorderBottom,
  BorderLeft,
  BorderOuter,
  BorderRight,
  BorderTop,
  LineWeight,
  Remove,
  Tune,
} from "@mui/icons-material";
import { useSignal } from "@preact/signals-react";
import DimensionField from "../NGDimensionEditor";
import MenuButtonEditor from "../NGMenuButtonEditor";
import { useRef } from "react";
import { getTestId } from "../../../library/utils";

export default function NGCommonStylesEditor({ selected, layout, config }) {
  const borderSideButton = useSignal<HTMLElement | null>(null);
  const borderSideMenuOpen = Boolean(borderSideButton.value);
  const inputBorderWidthRef = useRef(null);

  const sides = ["borderTop", "borderRight", "borderBottom", "borderLeft", "border"];

  const {
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    border,
    borderColor,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    gap,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
  } = selected.value;

  const sideSelectedIndex = (() => {
    if (borderTop) return 1;
    if (borderRight) return 2;
    if (borderBottom) return 3;
    if (borderLeft) return 4;
    if (borderColor) return 5;

    return 0;
  })();

  const selectedBorder = (() => {
    if (borderTop) return "borderTop";
    if (borderBottom) return "borderBottom";
    if (borderLeft) return "borderLeft";
    if (borderRight) return "borderRight";
    if (border || borderColor) return "border";
    return "";
  })();

  const handleChange = (value: object) => {
    const c = { ...selected.value };
    const o1 = Object.keys(value)[0]
    const o2 = Object.values(value)[0]
    if (!o2) {
      delete c?.[o1]
    } else {
      c[o1] = o2;
    }
    selected.value = c;
  };

  const showCustomBorderSides = borderColor;

  const getBorderSideIcon = () => {
    if (borderTop) return <BorderTop />;
    if (borderRight) return <BorderRight />;
    if (borderBottom) return <BorderBottom />;
    if (borderLeft) return <BorderLeft />;
    if (showCustomBorderSides) return <Tune />;

    return <BorderOuter />;
  };

  const handleBorderSideSelected = (value, index) => {
    /* borderSideSelectedIndex.value = index; */
    const updatedValues = { ...selected.value };

    if (value === "custom") {
      if (selectedBorder !== "border") {
        updatedValues.borderStyle = selected.value[`${selectedBorder}Style`];

        delete updatedValues[selectedBorder];
        delete updatedValues[`${selectedBorder}Width`];
        delete updatedValues[`${selectedBorder}Style`];
      }

      updatedValues.borderColor = selected.value[selectedBorder];
      updatedValues.borderTopWidth = selected.value[`${selectedBorder}Width`];
      updatedValues.borderRightWidth = selected.value[`${selectedBorder}Width`];
      updatedValues.borderBottomWidth = selected.value[`${selectedBorder}Width`];
      updatedValues.borderLeftWidth = selected.value[`${selectedBorder}Width`];
    } else {
      sides.forEach((side) => {
        delete updatedValues[side];
        delete updatedValues[`${side}Width`];
        delete updatedValues[`${side}Style`];
      });
      delete updatedValues.borderColor;

      const widthValue = showCustomBorderSides
        ? inputBorderWidthRef?.current?.value
        : selected.value[`${selectedBorder}Width`] ?? "1px";

      updatedValues[value] = selected.value[selectedBorder] ?? selected.value.borderColor;
      updatedValues[`${value}Style`] = selected.value[`${selectedBorder}Style`];
      updatedValues[`${value}Width`] = widthValue;
    }

    selected.value = updatedValues;
    handleCloseBorderSideMenu();
  };

  const handleCloseBorderSideMenu = () => {
    borderSideButton.value = null;
  };

  const handleOpenBorderSideMenu = (event: React.MouseEvent<HTMLElement>) => {
    borderSideButton.value = event.currentTarget;
  };

  const borderSides = [
    { Icon: <BorderOuter />, Text: "All", Value: "border" },
    { Icon: <BorderTop />, Text: "Top", Value: "borderTop" },
    { Icon: <BorderRight />, Text: "Right", Value: "borderRight" },
    { Icon: <BorderBottom />, Text: "Bottom", Value: "borderBottom" },
    { Icon: <BorderLeft />, Text: "Left", Value: "borderLeft" },
    { Icon: <Tune />, Text: "Custom", Value: "custom" },
  ];

  const handleAddBorder = () => {
    if (selectedBorder) {
      const selectedUpdated = { ...selected.value };
      sides.forEach((side) => {
        delete selectedUpdated[side];
        delete selectedUpdated.borderColor;
        delete selectedUpdated[`${side}Width`];
        delete selectedUpdated[`${side}Style`];
      });

      selected.value = selectedUpdated;
    } else {
      selected.value = { ...selected.value, border: "#ffffff", borderWidth: "1px", borderStyle: "solid" };
    }
  };

  const borderTypeOptions = [
    {
      Text: "Solid",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "2px black solid" }} />,
      Value: { [`${selectedBorder}Style`]: "solid" },
    },
    {
      Text: "Dashed",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "2px black dashed" }} />,
      Value: { [`${selectedBorder}Style`]: "dashed" },
    },
    {
      Text: "Dotted",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "2px black dotted" }} />,
      Value: { [`${selectedBorder}Style`]: "dotted" },
    },
    {
      Text: "Double",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "5px black double" }} />,
      Value: { [`${selectedBorder}Style`]: "double" },
    },
    {
      Text: "Groove",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "5px darkgrey groove" }} />,
      Value: { [`${selectedBorder}Style`]: "groove" },
    },
    {
      Text: "Ridge",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "5px darkgrey ridge" }} />,
      Value: { [`${selectedBorder}Style`]: "ridge" },
    },
    {
      Text: "Inset",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "5px darkgrey inset" }} />,
      Value: { [`${selectedBorder}Style`]: "inset" },
    },
    {
      Text: "Outset",
      Icon: <div className="label-color" style={{ width: "24px", height: "20px", border: "2px darkgrey outset" }} />,
      Value: { [`${selectedBorder}Style`]: "outset" },
    },
  ];

  const borderTypeSelectedIndex =
    borderTypeOptions.findIndex(
      (option) => option.Value[`${selectedBorder}Style`] === selected.value[`${selectedBorder}Style`]
    ) ?? 0;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", marginBottom: "6px" }}>
      <NGDimensionPositionStylesEditor selected={selected} layout={layout} />
      <Stack direction="row" justifyContent="space-between" gap="1rem">
        <Stack direction="column" justifyContent="space-between">
          <DimensionField
            label={<SpaceBarSharpIcon fontSize="small" className="label-color" />}
            field={"gap"}
            value={String(gap || "")}
            valueType={"text"}
            onValueChange={(e) => handleChange({ gap: e.target.value })}
            styles={{ margin: 0 }}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography className="control-label">Border</Typography>
            <IconButton
              style={{
                minWidth: "50px",
                maxWidth: "50px",
                width: "50px",
                height: "40px",
                alignSelf: "end",
                border: "none",
              }}
              title="add-border"
              onClick={handleAddBorder}
              className="menu-button-prop-editor"
            >
              {selectedBorder ? <Remove /> : <Add />}
            </IconButton>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            minWidth: "fit-content",
            overflowX: "auto",
            marginBottom: "10px",
            border: "1px solid #868686",
            borderRadius: "4px",
            background: "#EFEFEF",
            alignSelf: "center",
          }}
          className="layout-editor-container"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography className="container-label" marginLeft="10px">
              Margin
            </Typography>
            <TextField
              size="small"
              data-testid="input-margin-top"
              value={marginTop ?? ""}
              placeholder="-"
              className="layout-box-styles"
              onChange={(e) => handleChange({ marginTop: e.target.value })}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <TextField
              size="small"
              data-testid="input-margin-left"
              value={marginLeft ?? ""}
              placeholder="-"
              className="layout-box-styles"
              onChange={(e) => handleChange({ marginLeft: e.target.value })}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "4px",
                border: "1px solid #86868B",
                background: "#FFF",
                padding: "4px",
                margin: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography className="container-label">Padding</Typography>
                <TextField
                  sx={{ alignSelf: "center" }}
                  data-testid="input-padding-top"
                  value={paddingTop ?? ""}
                  onChange={(e) => handleChange({ paddingTop: e.target.value })}
                  placeholder="-"
                  className="layout-box-styles"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  data-testid="input-padding-left"
                  value={paddingLeft ?? ""}
                  onChange={(e) => handleChange({ paddingLeft: e.target.value })}
                  placeholder="-"
                  className="layout-box-styles"
                />
                <Box
                  sx={{
                    height: "32px",
                    width: "60px",
                    backgroundColor: "#EFEFEF",
                    border: "1px solid rgba(0, 0, 0, 0.5)",
                  }}
                />
                <TextField
                  data-testid="input-padding-right"
                  value={paddingRight ?? ""}
                  onChange={(e) => handleChange({ paddingRight: e.target.value })}
                  placeholder="-"
                  className="layout-box-styles"
                />
              </Box>
              <TextField
                sx={{ alignSelf: "center" }}
                value={paddingBottom ?? ""}
                data-testid="input-padding-bottom"
                onChange={(e) => handleChange({ paddingBottom: e.target.value })}
                placeholder="-"
                className="layout-box-styles"
              />
            </Box>
            <TextField
              value={marginRight ?? ""}
              data-testid="input-margin-right"
              onChange={(e) => handleChange({ marginRight: e.target.value })}
              placeholder="-"
              className="layout-box-styles"
            />
          </Box>
          <TextField
            value={marginBottom ?? ""}
            sx={{ alignSelf: "center" }}
            data-testid="input-margin-bottom"
            onChange={(e) => handleChange({ marginBottom: e.target.value })}
            placeholder="-"
            className="layout-box-styles"
          />
        </Box>
      </Stack>
      {selectedBorder && (
        <>
          <ColorPicker
            selected={selected}
            property={borderColor ? "borderColor" : selectedBorder}
            label="Border Color"
          />
          <Stack direction="row" gap="1rem" alignItems="center" justifyItems="flex-start">
            <TextField
              size="small"
              inputRef={inputBorderWidthRef}
              sx={{ alignSelf: "center", width: "fit-content", margin: 0 }}
              className="input-border-width"
              data-testid="input-border-width"
              value={selected.value[`${selectedBorder}Width`] ?? ""}
              onChange={(e) => handleChange({ [`${selectedBorder}Width`]: e.target.value })}
              disabled={!!showCustomBorderSides}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LineWeight />
                  </InputAdornment>
                ),
              }}
            />
            <MenuButtonEditor
              name="border-type-selector"
              options={borderTypeOptions}
              showIcon={false}
              selectedIndex={borderTypeSelectedIndex}
              width="150px"
              onChange={(value) => handleChange(value)}
            />
            <IconButton
              style={{
                minWidth: "50px",
                maxWidth: "50px",
                width: "50px",
                height: "40px",
                alignSelf: "end",
              }}
              title="border-sides"
              onClick={handleOpenBorderSideMenu}
              className="menu-button-prop-editor"
            >
              {getBorderSideIcon()}
            </IconButton>
          </Stack>
          {showCustomBorderSides && (
            <Stack direction="row" gap="0.4rem">
              <TextField
                size="small"
                sx={{ alignSelf: "center", width: "fit-content", margin: 0 }}
                className="input-border-top-side"
                data-testid="input-border-top-side"
                value={borderTopWidth ?? ""}
                onChange={(e) => handleChange({ borderTopWidth: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BorderTop />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                sx={{ alignSelf: "center", width: "fit-content", margin: 0 }}
                className="input-border-right-side"
                data-testid="input-border-right-side"
                value={borderRightWidth ?? ""}
                onChange={(e) => handleChange({ borderRightWidth: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BorderRight />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                sx={{ alignSelf: "center", width: "fit-content", margin: 0 }}
                className="input-border-bottom-side"
                data-testid="input-border-bottom-side"
                value={borderBottomWidth ?? ""}
                onChange={(e) => handleChange({ borderBottomWidth: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BorderBottom />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                sx={{ alignSelf: "center", width: "fit-content", margin: 0 }}
                className="input-border-left-side"
                data-testid="input-border-left-side"
                value={borderLeftWidth ?? ""}
                onChange={(e) => handleChange({ borderLeftWidth: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BorderLeft />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
        </>
      )}

      <Menu
        id="border-side-menu"
        anchorEl={borderSideButton.value}
        open={borderSideMenuOpen}
        onClose={handleCloseBorderSideMenu}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "10px", marginLeft: "10px" }}
      >
        {borderSides.map((side, index) => (
          <MenuItem
            key={index}
            disabled={index === sideSelectedIndex}
            selected={index === sideSelectedIndex}
            onClick={() => handleBorderSideSelected(side.Value, index)}
          >
            <ListItemIcon>{side.Icon}</ListItemIcon>
            <ListItemText>{side.Text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" columnGap="4%" rowGap="1rem">
        <ColorPicker
          selected={selected}
          property="backgroundColor"
          label="Background Color"
          sx={{ width: "48%" }}
          dataTestId={getTestId(config, "backgroundColor")}
        />
        <ColorPicker
          selected={selected}
          property="color"
          label="Color"
          sx={{ width: "48%" }}
          dataTestId={getTestId(config, "color")}
        />
      </Stack>
      <TextStylesEditor selected={selected} config={config} />
    </Box>
  );
}
