import { batch, useSignal, signal, useSignalEffect } from "@preact/signals-react";
import { Component, Container, Form, LayoutItem, ShareDialog, SimpleContainer } from "../../resolvers-types";
import { INGFeedProps, RuntimeContext } from "../library/NGFieldExtensions";
import { cloneDeep, isNil } from "lodash-es";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import NGSimpleContainer from "./NGSimpleContainer";
import { setupHandlers, setupLocalState, setupServices, updateItemContext } from "../library/dataService";
import { generateUID, getClassName, getTestId, getsxObject, isNullOrEmpty, minItemProps } from "../library/utils";
import { getComponentAndServiceMetadata } from "../library/components";
import { log } from "../library/logger";
import {
  addTextNotePopup,
  historyNotePopup,
  infoPopup,
  reviewServices,
} from "../components/NGReviewDialog/NGReviewDialogPopups";
import NGForm from "./NGForm";
import { shareForm } from "../sampleData/share-form";
import NGShareDialog from "../components/NGShareDialog/NGShareDialog";
import { sharePopup } from "../components/NGShareDialog/NGShareDialogPopups";
import "./NGFeed.css";
import NGLayoutItem from "./NGLayoutItem";
import { setupModalPopup } from "../components/ComponentUtils";
import NGComponent from "./NGComponent";

interface FeedItemProps {
  reviews: any;
  inReviewMode: boolean;
  content: Component;
  addHeader: boolean;
  context: RuntimeContext;
}

const tag = "NGFeed";

type HeaderProps = {
  layoutItem: LayoutItem;
  context: RuntimeContext;
};

const FeedHeader: React.FC<HeaderProps> = React.memo(({ layoutItem, context }) => {
  return <NGLayoutItem config={layoutItem} context={context} />;
});

function setupReviewPopups(ctx) {
  return [
    setupModalPopup(addTextNotePopup, ctx),
    setupModalPopup(historyNotePopup, ctx),
    setupModalPopup(infoPopup, ctx),
  ];
}

const FeedItem: React.FC<FeedItemProps> = React.memo(({ content, addHeader, context, inReviewMode }) => {
  log.info(tag, "FeedHeader:", content, addHeader);

  const key = `FI_${content.Id}_${generateUID()}`;

  let formToShare: Form | null = null;
  if (!isNil(content.SharingInfo)) {
    formToShare = cloneDeep(shareForm);

    if (!isNil(formToShare)) {
      formToShare.Id = `${formToShare.Id}_${content.Id}_${content.InstanceId}`;
      formToShare.UniqueName = formToShare.Id;
      formToShare.Data = content.SharingInfo;
      formToShare.ContextId = content.Id;
    }
    //Data
  }

  const shareDialog: ShareDialog = {};
  if (content.SharingOptions?.CanShare) {
    shareDialog.ContextId = content.Id;
    shareDialog.Data = {
      Item: content,
    };
  }

  if (!isNil(content.ComponentContainer)) (content.ComponentContainer as any).ContextId = content.Id;

  const testContext = {
    Id: content.Id,
    ContextId: content.Id,
  };
  //SharingInfo
  return (
    <>
      {setupModalPopup(sharePopup, context)}
      {addHeader && (
        <Stack direction="row" justifyContent="space-between" spacing={2} key={`${key}_stack`}>
          {content.HideTitle !== true && (content.Name || content.Title) ? (
            <Typography
              className={`FeedComponentHeader ${content.TitleClasses ? getClassName({ value: content.TitleClasses }) : ""
                }`}
              key={`${key}_title`}
              data-testid={getTestId(testContext, "title")}
            >
              {isNullOrEmpty(content.Title) ? content.Name : content.Title}
            </Typography>
          ) : (
            <>&nbsp;</>
          )}

          <Stack direction="row" justifyContent="flex-end" spacing={0} key={`${key}_stack`}>
            {content.HeaderItems?.map((item, key) => {
              (item as any).ContextId = content.Id;
              return <NGLayoutItem config={item} key={key} context={context} />;
            })}

            {content.SharingOptions?.CanShare && (
              <NGShareDialog config={shareDialog} key={`${key}_SO`} context={context} />
            )}
          </Stack>
        </Stack>
      )}
      {content.SharingInfo && formToShare && <NGForm config={formToShare} key={`${key}_SF`} context={context} />}
      {/* <NGSimpleContainer config={content.ComponentContainer as SimpleContainer} key={key} context={context} /> */}
      {inReviewMode && setupReviewPopups(context)}
      <NGComponent config={content} key={key} context={context} />
    </>
  );
});

export default function NGFeed({ config, context }: INGFeedProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Components: useSignal(config.Components ?? []),
      Reviews: useSignal(config.Reviews ?? {}),
      Data: useSignal(config.Data ?? {}),
      InDesignMode: useSignal(config.InDesignMode ?? false),
      InReviewMode: useSignal(config.InReviewMode ?? false),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  function setStyleForContainers(comps: Component[]) {
    if (isNil(config.ComponentsStyle)) return;

    comps.forEach((comp: Component) => {
      if (isNil(comp)) return;

      const c: Container = comp.ComponentContainer as Container;

      if (isNil(c)) return;

      if (isNil(c.Style)) c.Style = {};

      c.Style = { ...config.ComponentsStyle, ...c.Style };
    });
  }

  const fullComponents = useSignal<Component[]>([]); // TODO: bind
  const sliceSize = 50;
  const offset = useSignal(0);

  function fetchComponentsBatch(components: Component[], start: number, end: number, context: RuntimeContext) {
    return getComponentAndServiceMetadata(components, start, end, context).then(({ comps, services, cleanup }) => {
      log.info(tag, "complete", comps, services);
      setStyleForContainers(comps);

      return { comps, cleanup };
    });
  }

  useSignalEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let cleanupFn: () => void = () => { };

    batch(() => {
      // if (local.Components.value.length == 0) return;

      offset.value = 0;
      const fetchComponents = async () => {
        log.info(tag, "useSignalEffect", local.Components.value);

        fetchComponentsBatch(local.Components.value, 0, sliceSize, context).then(({ comps, cleanup }) => {
          offset.value = offset.value + comps.length;
          fullComponents.value = comps;
          cleanupFn = cleanup;
        });
      };
      fetchComponents();
    });

    return () => {
      log.info(tag, "cleanup");
      cleanupFn();
    };
  });

  //addTextNotePopup
  return (
    <Box sx={getsxObject(config.Style)}>
      {config.FeedHeader && <FeedHeader layoutItem={config.FeedHeader} context={context} />}

      {fullComponents.value.map((app, key) => {
        if (isNil(app) || isNil(app.ComponentContainer)) return <></>;

        const currentContext = updateItemContext(context, app);

        if (isNil(app.__typename)) app.__typename = "Component";

        app.ComponentContainer.InReviewMode = local.InReviewMode.value;

        if (app.ComponentContainer.InReviewMode == true) {
          app.Services = (app.Services ?? []).concat(reviewServices);
          app.ComponentContainer.ReviewDialogOptions = cloneDeep(config.ReviewDialogOptions) ?? {};

          //State.Reviews

          app.ComponentContainer.ReviewDialogOptions.Data = {
            ...app.ComponentContainer.ReviewDialogOptions?.Data,
            Item: minItemProps(app, "Component") as any,
          };

          if (!isNil(local.Reviews.value) && !isNil(local.Reviews.value[app.Id])) {
            const c = local.Reviews.value[app.Id];

            if (!isNil(c["Reaction"])) app.ComponentContainer.ReviewDialogOptions.Data.Reaction = c["Reaction"];

            if (!isNil(c["Notes"])) app.ComponentContainer.ReviewDialogOptions.Data.NoteCount = c["Notes"];
          }
        }

        return (
          <FeedItem
            reviews={local.Reviews.value}
            key={key}
            inReviewMode={local.InReviewMode.value}
            content={app}
            addHeader={config.AddHeaders ?? false}
            context={currentContext}
          // context={context}
          />
        );
      })}
    </Box>
  );
}
