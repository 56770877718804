import { SyntheticEvent, useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ReadonlySignal, useComputed, useSignal } from "@preact/signals-react";
import { debounce, isEmpty, isEqual, isNil, isUndefined, max, min, toNumber } from "lodash-es";
import { Box, Stack } from "@mui/material";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import {
  ExcelExportModule,
  LicenseManager,
  ClientSideRowModelModule,
  AdvancedFilterModule,
  FiltersToolPanelModule,
  ColumnsToolPanelModule,
  MenuModule,
  RangeSelectionModule,
  RowGroupingModule,
  SetFilterModule,
  RichSelectModule,
  StatusBarModule,
  SparklinesModule,
  GetRowIdFunc,
  GetRowIdParams,
  RowClickedEvent,
  ColDef,
  CsvExportModule,
  ClipboardModule,
  MasterDetailModule,
  MultiFilterModule,
  SideBarModule,
  CsvExportParams,
  ExcelExportParams,
  GridOptions,
  RowValueChangedEvent,
  ColumnMovedEvent,
  DomLayoutType,
  CellStyle,
  IDateFilterParams,
  ICellRendererParams,
  CheckboxSelectionCallbackParams,
  HeaderCheckboxSelectionCallbackParams,
  IAggFuncParams,
  SelectionChangedEvent,
  ColumnRowGroupChangedEvent,
  ColumnPivotChangedEvent,
} from "ag-grid-charts-enterprise";
import NGButton from "../NGButton/NGButton";
import { formatNumber, formatDate, generateUID, getTestId } from "../../library/utils";
import {
  Button,
  IActionTrigger,
  LayoutItem,
  List,
  ListColumn,
  Maybe,
  SpreadsheetModeOptions,
} from "../../../resolvers-types";
import { INGListProps, RowGroupPanelShowOptions, RuntimeContext } from "../../library/NGFieldExtensions";
import {
  GetFormatFromSite,
  getFormData,
  getScope,
  getState,
  setupHandlers,
  setupLocalState,
} from "../../library/dataService";
import { GridToolbarQuickFilter } from "./QuickFilter";
import { GridToolbarExport } from "./Export";
import NGLayoutItem from "../../generators/NGLayoutItem";

import "ag-grid-charts-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-charts-enterprise/styles/ag-theme-quartz.css"; // Mandatory CSS required by the Data Grid
import { defaultRowHeight, defaultRowsPerPage } from "./defaults";
import { getExprValue } from "../../library/interpreter";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064288}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{JBI_Holdings}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JBI}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{JBI}_need_to_be_licensed___{JBI}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{29_July_2025}____[v3]_[0102]_MTc1Mzc0MzYwMDAwMA==bd659fcc281dec57d192f14cfd09e0c3"
);

interface CustomColDef extends ColDef {
  listCol?: ListColumn;
  autoSize?: boolean;
}

const tag = "NGList";

function checkboxSelection(params: CheckboxSelectionCallbackParams | ICellRendererParams) {
  if (!params.node.group) {
    return !params.node.parent?.groupData;
  }
  const p = params as ICellRendererParams;
  // return params.node.group === true && !!p.getValue?.();
  return false;
}

function headerCheckboxSelection(params: HeaderCheckboxSelectionCallbackParams) {
  return params.api.getRowGroupColumns().length === 0;
}

function getFormatDataType(format, value, dataType) {
  if (isNil(format)) return value;
  if (!dataType) return value;
  if (dataType === "number" && !isNil(format.NumberFormat)) return formatNumber(value, format.NumberFormat);
  if ((dataType === "date" || dataType === "dateString") && !isNil(format.DateFormat))
    return formatDate(value, format.DateFormat);
  return value;
}

const groupColumn = {
  headerName: "Group",
  minWidth: 250,
  field: "name",
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  cellRendererParams: {
    checkbox: checkboxSelection,
  },
};

function getDataTypeFilter(dataType) {
  if (!dataType) return "agTextColumnFilter";
  if (dataType === "number") return "agNumberColumnFilter";
  if (dataType === "date" || dataType === "dateString") return "agDateColumnFilter";
}

export default function NGList({ config, context }: INGListProps) {
  const local = setupLocalState(
    config,
    {
      Columns: useSignal([]),
      ListColumns: useSignal(config.ListColumns ?? []),
      Rows: useSignal(config.Rows ?? []),
      Loading: useSignal(config.Loading ?? false),
      QuickFilterText: useSignal(config.QuickFilterText ?? undefined),
    },
    context
  );
  const location = useLocation();

  const gridRef = useRef<AgGridReact<any>>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const advanceFilterRef = useRef<HTMLDivElement>(null);
  const quickFilterRef = useRef<HTMLInputElement>(null);
  const exportRef = useRef<HTMLButtonElement>(null);

  function getColumns(
    config: List,
    cols: Maybe<ListColumn>[] | undefined | null,
    listCols: Maybe<ListColumn>[] | undefined | null,
    context: RuntimeContext
  ): ColDef[] {
    const MUICols: CustomColDef[] = [];

    cols = [...(cols ?? []), ...(isEmpty(listCols) ? [] : listCols)];
    if (isNil(cols) || cols.length == 0) return MUICols;
    const visibleColumnCount = cols.filter((item) => item?.Visible !== false).length;
    const defaultFlexWidth = visibleColumnCount > 0 ? 1 / visibleColumnCount : 1;

    cols.forEach((col, idx) => {
      if (col == null) return;
      const format = col?.FormatName ? GetFormatFromSite(col?.FormatName ?? "") : null;
      const newCol: CustomColDef = {
        field: col.Name as string,
        valueFormatter: (p) => getFormatDataType(format, p.value, col.DataType),
        filter: col?.DisableFiltering ? false : getDataTypeFilter(col.DataType),
        editable: col.Editable as boolean,
        hide: isUndefined(col.Visible) ? false : !col.Visible,
        headerName: col.HeaderName as string,
        width: col.Width ? toNumber(col.Width) : undefined,
        suppressHeaderMenuButton: !!col?.SuppressHeaderMenu,
        cellStyle: (col?.Style as CellStyle) ?? undefined,
        cellClass: col?.Classes ?? undefined,
        colId: col.Id ?? col.Name ?? generateUID(),
        flex: col.Flex ?? defaultFlexWidth,
        cellDataType: col.DataType ?? undefined,
        rowGroup: col.RowGroup || undefined,
        aggFunc: col.AggFunc,
        defaultAggFunc: col.DataType === "number" ? "sum" : "first",
        pivot: col.Pivot || undefined,
        listCol: col,
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
              return 0;
            }

            // In the example application, dates are stored as mm/dd/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = dateAsString.split("/");
            const year = Number(dateParts[2]);
            const month = Number(dateParts[0]) - 1;
            const day = Number(dateParts[1]);

            const cellDate = new Date(year, month, day);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        } as IDateFilterParams,
      };

      if (!isNil(col.CellLayout)) {
        newCol.cellRenderer = (params: CustomCellRendererProps) => {
          // CellLayout has a Name prop to identify data row item

          const { state, form, parentState } = getState(context);
          const scope = getScope(context, col.CellLayout, state, form, {}, parentState);
          scope.Row = params.data;
          const configClone = { ...col.CellLayout, Bindings: {}, getData: () => params.data };

          for (const [k, v] of Object.entries(col?.CellLayout?.Bindings || {})) {
            let srcValue: any = null;
            if (v == "Form") {
              srcValue = getFormData(scope.Form);
            } else {
              srcValue = getExprValue(v, scope, null);
            }
            configClone[k] = srcValue;
          }

          return <NGLayoutItem config={configClone} context={context} />;
        };
      }

      if (idx === 0 && config.ShowCheckboxSelection) {
        newCol["checkboxSelection"] = checkboxSelection;
        newCol["headerCheckboxSelection"] = headerCheckboxSelection;
      }

      MUICols.push(newCol);
    });
    return MUICols;
  }

  function getRows(rows: any[] | undefined | null) {
    return (
      rows?.map((row) => ({
        ...row,
        Id: row?.Id ?? row?.id ?? generateUID(),
      })) ?? []
    );
  }

  const handlers = setupHandlers(config, context, location);

  function onFilterTextBoxChanged() {
    gridRef.current?.api.setGridOption("quickFilterText", quickFilterRef.current?.value);
  }

  function EditToolbar() {
    const addColumnsToSheetButton: Button = {
      __typename: "Button",
      Id: `${config.Id}_AddColumnsButton`,
      Label: "Add 10 Columns",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (handlerName: string, action: IActionTrigger, e: SyntheticEvent, data: object, formCtx: any) => {
            const newCols = addColumns(config, local.Columns.value.length ?? 0, true, 10);

            local.Columns.value = [
              ...local.Columns.value,
              ...getColumns(config, newCols, local.ListColumns.value, context),
            ];
            console.log("onClick", e, data, formCtx);
          },
        },
      ],
    };

    return (
      <>
        <Stack direction="row" margin="1rem" ref={containerRef}>
          {(config.ShowExport || config.Toolbar?.ShowExport) && <GridToolbarExport apiRef={gridRef} ref={exportRef} />}
          {config.SpreadsheetModeOptions?.ShowAddColumnsButton && (
            <NGButton config={addColumnsToSheetButton} methods={{} as any} context={context} />
          )}
          {(config?.Items || config.Toolbar?.Items)?.map((button) => {
            return <NGLayoutItem key={button.Id} config={button} context={context} />;
          })}
          {config.ShowQuickFilter && (
            <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} onInput={onFilterTextBoxChanged} ref={quickFilterRef} />
          )}
        </Stack>
      </>
    );
  }

  function BottomButtons() {
    if (!config?.SpreadsheetModeOptions?.ShowAddRowsButton) return null;

    const addRowsToSheetButton: Button = {
      __typename: "Button",
      Id: `${config.Id}_AddRowsButton`,
      Label: "Add 5 Rows",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (handlerName: string, action: IActionTrigger, e: SyntheticEvent, data: object, formCtx: any) => {
            const newRows = addBlankRows(config, local.Rows.value, 5);
            local.Rows.value = [...local.Rows.value, ...newRows];
            console.log("onClick", e, data, formCtx);
          },
        },
      ],
    };

    const addRowsButton = <NGButton config={addRowsToSheetButton} methods={{} as any} context={context} />;

    return <Box sx={{ display: "flex", justifyContent: "flex-start" }}>{addRowsButton}</Box>;
  }

  function handleOnRowClicked(params: RowClickedEvent<any>) {
    if (!isNil((handlers as any).onRowClick)) (handlers as any).onRowClick(null, params.data);
  }

  function onSelectionChanged(params: SelectionChangedEvent<any>) {
    if (!isNil((handlers as any).onSelectionChanged))
      (handlers as any).onSelectionChanged(null, params.api.getSelectedRows());
  }

  const onRowValueChanged = useCallback(
    (params: RowValueChangedEvent) => {
      const clonedRows = [...local.Rows.value];
      clonedRows[params.rowIndex ?? 0] = params.data;
      if (!isNil((handlers as any).onRowUpdate)) (handlers as any).onRowUpdate(null, { Rows: clonedRows });
    },
    [handlers, local.Rows.value]
  );

  const onColumnsUpdate = useCallback(
    (event: ColumnRowGroupChangedEvent | ColumnPivotChangedEvent | ColumnMovedEvent) => {
      if (handlers?.["onGridConfigurationChange"]) {
        const columnsDefinitions = event?.api?.getColumnDefs();
        const listColumns =
          columnsDefinitions?.map((col: CustomColDef) => {
            return {
              ...col.listCol,
              RowGroup: !!col.rowGroup,
              Pivot: !!col.pivot,
              AggFunc: col.aggFunc ?? undefined,
            };
          }) ?? [];
        // Check if previous config is not equal to current config, to avoid unnecessary saving
        if (!isEqual(listColumns, local.ListColumns.value)) {
          const newConfig: List = { ...config, ListColumns: listColumns };
          handlers?.["onGridConfigurationChange"](new Event("change"), { Config: newConfig });
        }
      }
    },
    [config, handlers, local.ListColumns.value]
  );

  const cols: ReadonlySignal<CustomColDef[]> = useComputed(() => {
    const columns = getColumns(config, local.Columns.value, local.ListColumns.value, context);
    return columns;
  });

  const rowData: ReadonlySignal<any[]> = useComputed(() => {
    const rows = getRows(local.Rows.value);
    return rows;
  });

  const getRowId = useCallback<GetRowIdFunc>(({ data }: GetRowIdParams) => {
    return data?.Id ?? data?.id;
  }, []);

  const defaultExportParams = useMemo(
    () => ({
      headerRowHeight: 40,
      rowHeight: 30,
      fontSize: 14,
      addImageToCell: () => null,
    }),
    []
  );

  const gridOptions: GridOptions<any> = useMemo(
    () => ({
      onColumnPivotModeChanged(event) {
        event.api.sizeColumnsToFit();
      },
      statusBar: {
        statusPanels: [
          // { statusPanel: "agTotalAndFilteredRowCountComponent", key: "totalAndFilter", align: "left" },
          { statusPanel: "agTotalRowCountComponent" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" },
          { statusPanel: "agAggregationComponent", align: "right" },
        ],
      },
      rowDragManaged: true,
      rowDragMultiRow: true,
      popupParent: gridContainerRef.current,
      pivotPanelShow: "always",
      suppressColumnMoveAnimation: false,
      enableRtl: /[?&]rtl=true/.test(window.location.search),
      // enableCharts: true,
      enableRangeSelection: true,
      enableFillHandle: true,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 50,
      suppressClearOnFillReduction: false,
      aggFuncs: {
        sum: (params: IAggFuncParams) => {
          let sum = 0;
          params.values.forEach((value) => {
            if (typeof value === "number" || params.colDef.cellDataType === "number") sum += toNumber(value);
          });
          return sum ?? undefined;
        },
        max: (params: IAggFuncParams) => {
          return max(params.values) ?? undefined;
        },
        min: (params: IAggFuncParams) => {
          return min(params.values) ?? undefined;
        },
      },
      rowSelection: "multiple",
      quickFilterText: "",
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
      CsvExportParams: "",
      autoGroupColumnDef: groupColumn,
      columnTypes: {
        currencyType: {
          useValueFormatterForExport: false,
        },
      },
      editType: "fullRow",
      getBusinessKeyForNode: (node) => (node.data ? node.data.name : ""),
      initialGroupOrderComparator: ({ nodeA, nodeB }) => {
        if (!nodeA?.key || !nodeB?.key) return 0;

        if (nodeA?.key < nodeB?.key) {
          return -1;
        }
        if (nodeA?.key > nodeB?.key) {
          return 1;
        }

        return 0;
      },
      onGridReady: (event) => {
        if (document.documentElement.clientWidth <= 1024) {
          event.api.closeToolPanel();
        }
      },
      advancedFilterParent: containerRef.current,
      excelStyles: [
        {
          id: "v-align",
          alignment: {
            vertical: "Center",
          },
        },
        {
          id: "alphabet",
          alignment: {
            vertical: "Center",
          },
        },
        {
          id: "good-score",
          alignment: {
            horizontal: "Center",
            vertical: "Center",
          },
          interior: {
            color: "#C6EFCE",
            pattern: "Solid",
          },
          numberFormat: {
            format: "[$$-409]#,##0",
          },
        },
        {
          id: "bad-score",
          alignment: {
            horizontal: "Center",
            vertical: "Center",
          },
          interior: {
            color: "#FFC7CE",
            pattern: "Solid",
          },
          numberFormat: {
            format: "[$$-409]#,##0",
          },
        },
        {
          id: "header",
          font: {
            color: "#44546A",
            size: 16,
          },
          interior: {
            color: "#F2F2F2",
            pattern: "Solid",
          },
          alignment: {
            horizontal: "Center",
            vertical: "Center",
          },
          borders: {
            borderTop: {
              lineStyle: "Continuous",
              weight: 0,
              color: "#8EA9DB",
            },
            borderRight: {
              lineStyle: "Continuous",
              weight: 0,
              color: "#8EA9DB",
            },
            borderBottom: {
              lineStyle: "Continuous",
              weight: 0,
              color: "#8EA9DB",
            },
            borderLeft: {
              lineStyle: "Continuous",
              weight: 0,
              color: "#8EA9DB",
            },
          },
        },
        {
          id: "currency-cell",
          alignment: {
            horizontal: "Center",
            vertical: "Center",
          },
          numberFormat: {
            format: "[$$-409]#,##0",
          },
        },
        {
          id: "boolean-type",
          dataType: "boolean",
          alignment: {
            vertical: "Center",
          },
        },
        {
          id: "country-cell",
          alignment: {
            indent: 4,
          },
        },
      ] as any,
    }),
    [cols.value, onColumnsUpdate, onRowValueChanged]
  );

  const modules = useMemo(
    () => [
      AdvancedFilterModule,
      ClientSideRowModelModule,
      CsvExportModule,
      ClipboardModule,
      ColumnsToolPanelModule,
      ExcelExportModule,
      FiltersToolPanelModule,
      MasterDetailModule,
      MenuModule,
      MultiFilterModule,
      RangeSelectionModule,
      RichSelectModule,
      RowGroupingModule,
      SetFilterModule,
      SideBarModule,
      StatusBarModule,
      SparklinesModule,
    ],
    []
  );

  const onFirstDataRendered = useCallback(() => {
    if (config.EnableSideBar) {
      const colDefs = gridRef.current?.api.getColumnDefs() ?? [];
      for (const def of colDefs as unknown as ColDef[]) {
        if (def?.pivot) {
          gridRef.current?.api.setGridOption("pivotMode", true);
          break;
        }
      }
    }
    gridRef.current?.api.closeToolPanel();
  }, [config.EnableSideBar]);

  return (
    <div data-testid={getTestId(config)} data-type={config.__typename} ref={gridContainerRef} style={{ width: "100%" }}>
      <EditToolbar />
      <div ref={advanceFilterRef} />
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{
          width: "100%",
          height:
            config.GridHeight ??
            (config.GridLayout === "autoHeight" ? "100%" : `${defaultRowHeight * defaultRowsPerPage}px`),
        }}
      >
        <AgGridReact
          domLayout={(config.GridLayout as DomLayoutType) ?? "normal"}
          sideBar={
            config.EnableSideBar
              ? {
                  toolPanels: ["columns", "filters"],
                  position: "right",
                  defaultToolPanel: "columns",
                  hiddenByDefault: false,
                }
              : undefined
          }
          modules={modules}
          getChartToolbarItems={() => ["chartDownload"]}
          defaultColDef={{
            floatingFilter: !!config.ShowFloatingFilter,
            enableRowGroup: true,
            editable: !!config.ReadOnlyEdit,
            filter: "agTextColumnFilter",
            flex: 1,
            enableValue: true,
            enablePivot: true,
            enableCellChangeFlash: true,
            minWidth: 150,
          }}
          pivotMode={!!config.PivotMode}
          ref={gridRef}
          rowData={rowData.value}
          columnDefs={cols.value}
          quickFilterText={local.QuickFilterText.value}
          loading={local.Loading.value}
          getRowId={getRowId}
          enableRangeSelection
          // enableCharts
          rowGroupPanelShow={(config.ShowGroupingPanel as RowGroupPanelShowOptions) ?? undefined}
          rowSelection={"multiple"}
          suppressAggFuncInHeader
          groupDefaultExpanded={0}
          onRowClicked={handleOnRowClicked}
          onSelectionChanged={onSelectionChanged}
          isRowSelectable={() => true}
          rowHeight={config.RowHeight ?? defaultRowHeight}
          paginationPageSize={config.RowsPerPage ?? defaultRowsPerPage}
          paginationAutoPageSize={config.PaginationAutoPageSize ?? false}
          paginationPageSizeSelector={
            config.ShowPageSizeSelector ? [toNumber(config.RowsPerPage) ?? 0, 20, 50, 100] : false
          }
          autoSizeStrategy={{
            type: config?.RowsAutoSizeStrategy ?? "fitGridWidth",
          }}
          pagination={!!config.ShowPagination}
          gridOptions={gridOptions as unknown as GridOptions<any>}
          defaultCsvExportParams={defaultExportParams as CsvExportParams}
          defaultExcelExportParams={defaultExportParams as unknown as ExcelExportParams}
          enableAdvancedFilter={!!config.ShowAdvancedFilter}
          advancedFilterParent={advanceFilterRef.current}
          onFirstDataRendered={onFirstDataRendered}
          readOnlyEdit={!!config.ReadOnlyEdit}
          onRowValueChanged={onRowValueChanged}
          onColumnRowGroupChanged={onColumnsUpdate}
          onColumnMoved={debounce(onColumnsUpdate, 300)}
          onColumnPivotChanged={onColumnsUpdate}
        />
      </div>
      <BottomButtons></BottomButtons>
    </div>
  );
}

function numberToLetters(n: number): string {
  let result = "";
  while (n > 0) {
    n--; // Decrement n first to convert from 1-based index to 0-based.
    const remainder = n % 26;
    const letter = String.fromCharCode(remainder + 65); // Convert to ASCII (65 is "A")
    result = letter + result;
    n = Math.floor(n / 26);
  }
  return result;
}

function addBlankRows(config: List, rows: any, numberOfRows: number): any {
  const newRows: any[] = [];

  const highestId =
    isNil(rows) || rows.length == 0 ? 0 : rows.reduce((max, item) => (item.Id > max ? item.Id : max), rows[0].Id);

  for (let i = 1; i <= numberOfRows; i++) {
    const newRow = {
      Id: (i + highestId).toString(),
    };
    newRows.push(newRow);
  }

  return newRows;
}

function addColumns(config: List, idStart: number, skipAddingId: boolean, numberOfColumns: number): ListColumn[] {
  // First column is always the ID column
  const columns: ListColumn[] = [];
  const options: SpreadsheetModeOptions = config?.DoNotEnforceIdColumn
    ? {
        DoNotEnforceIdColumn: config?.DoNotEnforceIdColumn,
      }
    : {};

  if (options.DoNotEnforceIdColumn !== true && !skipAddingId) {
    const idColumm = {
      __typename: "ListColumn",
      Id: `${idStart++}`,
      HeaderName: "",
      Classes: ["MuiDataGrid-columnHeader"],
      Name: "Id",
      MinWidth: 40,
      IsPrimaryKey: true,
    } as ListColumn;
    columns.push(idColumm);
  }

  for (let i = 0; i < numberOfColumns; i++) {
    const column = {
      __typename: "ListColumn",
      Id: (i + idStart).toString(),
      HeaderName: numberToLetters(i + idStart),
      Name: numberToLetters(i + idStart),
      Editable: true,
    } as ListColumn;

    columns.push(column);
  }

  return columns;
}
