import * as graphql from "../../../resolvers-types";
import { reviewHistoryRepeater } from "../../sampleData/review-history/review-history";
import serviceNotesHistory from "../../sampleData/review-history/service-review-notes-history";

export const reviewServices = [
  {
    Name: "GetReviewByItem",
    Service: "reviews-by-item",
    Trigger: "Action",
    Fields: [
      {
        Name: "Action",
        Required: true,
        Value: "Reaction",
      },
      {
        Name: "Reaction",
        Required: true,
        Value: "",
        Bindings: {
          Value: "Event.Reaction",
        },
      },
      {
        Name: "Item",
        Required: false,
        Value: "",
        Bindings: {
          Value: "Event.Item",
        },
      },
    ],
  },
  {
    Name: "NewReview",
    Service: "reviews.new",
    Trigger: "Action",
    Type: "Publish",
    Fields: [
      {
        Name: "Action",
        Required: true,
        Value: "Reaction",
      },
      {
        Name: "Reaction",
        Required: true,
        Value: "",
        Bindings: {
          Value: "Event.Reaction",
        },
      },
      {
        Name: "Item",
        Required: false,
        Value: "",
        Bindings: {
          Value: "Event.Item",
        },
      },
    ],
  },
  {
    Name: "NewTextNote",
    Service: "reviews.new",
    Trigger: "Action",
    Type: "Publish",
    Fields: [
      {
        Name: "Action",
        Required: true,
        Value: "AddNote",
      },
      {
        Name: "Text",
        Required: true,
        Value: {},
        Bindings: {
          Value: "State.NGReviewText.Text",
        },
      },
      {
        Name: "NoteType",
        Required: true,
        Value: "Text",
      },
      {
        Name: "Item",
        Required: false,
        Value: "",
        Bindings: {
          Value: "Event.Item",
        },
      },
    ],
  },
  {
    Name: "NewVoiceNote",
    Service: "reviews.new",
    Trigger: "Action",
    Type: "Publish",
    Fields: [
      {
        Name: "Action",
        Required: true,
        Value: "AddNote",
      },
      {
        Name: "File",
        Required: true,
        Value: {},
        Bindings: {
          Value: "Event.FileName",
        },
      },
      {
        Name: "NoteType",
        Required: true,
        Value: "Audio",
      },
      {
        Name: "Item",
        Required: false,
        Value: "",
        Bindings: {
          Value: "Event.Item",
        },
      },
    ],
  },
];

export const addTextNotePopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "AddTextNotePopup",
  Title: "Add Asset",
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Items: [
      {
        Typename: "Form",
        Id: "AddTextNoteForm",
        UniqueName: "AddTextNoteForm",
        Items: [
          {
            __typename: "Label",
            Id: "0xa18",
            Value: "Text",
            DefaultValue: 3,
            Name: "NoteType",
          },
          {
            __typename: "InputField",
            Id: "2",
            Multiline: true,
            Rows: 10,
            Label: "Type your thoughts...",
            Name: "Text",
          },
          {
            __typename: "Button",
            Id: "3",
            Name: "Add",
            Label: "Save",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.NGReviewText": "Form",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "2",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "ClearForm",
                      },
                      Parameters: [{ Name: "Form", Value: "AddTextNoteForm" }],
                      NextCommandIdOnSuccess: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [
                        { Name: "ModalPopupId", Value: "AddTextNotePopup" },
                        { Name: "CommandResult", Value: "Save" },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            __typename: "Button",
            Name: "CloseModal",
            Id: "4",
            Label: "Close",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [{ Name: "ModalPopupId", Value: "AddTextNotePopup" }],
                    },
                  ],
                },
              },
            ],
          },
        ],
        Layout: {
          __typename: "LinearLayout",
          Id: "21",
          // SpacingExtraSmall: "1em",
          DirectionExtraSmall: "column",
        },
      } as graphql.Form,
    ],
  } as graphql.SimpleContainer,
};

//InfoPopup

export const infoPopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "InfoPopup",
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Items: [
      {
        __typename: "Form",
        Id: "ComponentDetails",
        Bindings: { Data: "State.NGReviewSelectedItem" },
        Items: [
          {
            __typename: "Label",
            Id: "0xa18",
            DefaultValue: "Component Name",
            Style: { fontWeight: "bold" },
          },
          {
            __typename: "Label",
            Id: "223234",
            Name: "Name",
          },
          {
            __typename: "Label",
            Id: "0xar18",
            DefaultValue: "Serial Number",
            Style: { fontWeight: "bold", marginTop: "1em" },
          },
          {
            __typename: "Label",
            Id: "223rr234",
            Name: "SerialNumber",
          },
          {
            __typename: "Label",
            Id: "0xaewrewr18",
            DefaultValue: "Description",
            Style: { fontWeight: "bold", marginTop: "1em" },
            Bindings: {
              Visible: "FormData.Description",
            },
          },
          {
            __typename: "Label",
            Id: "223rdsfwer234",
            Name: "Description",
          },
        ],
      },
      // {
      //   __typename: "Button",
      //   Name: "CloseModal",
      //   Id: "4",
      //   Label: "Close",
      //   Actions: [
      //     {
      //       Trigger: "onClick",
      //       CommandSet: {
      //         FirstCommandId: "1",
      //         ExecuteCommandsInParallel: false,
      //         Commands: [
      //           {
      //             Id: "1",
      //             Instruction: {
      //               Name: "CloseModalPopup",
      //             },
      //             Parameters: [{ Name: "ModalPopupId", Value: "InfoPopup" }],
      //           },
      //         ],
      //       },
      //     },
      //   ],
      // },
    ],
  } as graphql.SimpleContainer,
};

export const historyNotePopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "HistoryNotePopup",
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Services: [serviceNotesHistory],
    Items: [
      reviewHistoryRepeater,
      {
        __typename: "Button",
        Name: "CloseModal",
        Id: "4",
        Label: "Close",
        Actions: [
          {
            Trigger: "onClick",
            CommandSet: {
              FirstCommandId: "1",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "1",
                  Instruction: {
                    Name: "CloseModalPopup",
                  },
                  Parameters: [{ Name: "ModalPopupId", Value: "HistoryNotePopup" }],
                },
              ],
            },
          },
        ],
      },
      // {
      //   Typename: "Form",
      //   Id: "HistoryNoteForm",
      //   UniqueName: "HistoryNoteForm",
      //   Items: [
      //     {
      //       __typename: "Label",
      //       Id: "0xa18",
      //       Value: "Text",
      //       DefaultValue: 3,
      //       Name: "NoteType",
      //     },
      //     {
      //       __typename: "InputField",
      //       Id: "2",
      //       Multiline: true,
      //       Rows: 10,
      //       Label: "Type your thoughts...",
      //       Name: "Text",
      //     },
      //     {
      //       __typename: "Button",
      //       Id: "3",
      //       Name: "Add",
      //       Label: "Save",
      //       Actions: [
      //         {
      //           Trigger: "onClick",
      //           CommandSet: {
      //             FirstCommandId: "0",
      //             ExecuteCommandsInParallel: false,
      //             Commands: [
      //               {
      //                 Id: "0",
      //                 Instruction: {
      //                   Name: "SetState",
      //                 },
      //                 Parameters: [
      //                   {
      //                     Name: "Bindings",
      //                     Value: {
      //                       "State.NGReviewText": "Form",
      //                     },
      //                   },
      //                 ],
      //                 NextCommandIdOnSuccess: "2",
      //               },
      //               {
      //                 Id: "2",
      //                 Instruction: {
      //                   Name: "ClearForm",
      //                 },
      //                 Parameters: [{ Name: "Form", Value: "AddTextNoteForm" }],
      //                 NextCommandIdOnSuccess: "3",
      //               },
      //               {
      //                 Id: "3",
      //                 Instruction: {
      //                   Name: "CloseModalPopup",
      //                 },
      //                 Parameters: [
      //                   { Name: "ModalPopupId", Value: "AddTextNotePopup" },
      //                 ],
      //               },
      //             ],
      //           },
      //         },
      //       ],
      //     },
      //     {
      //       __typename: "Button",
      //       Name: "CloseModal",
      //       Id: "4",
      //       Label: "Close",
      //       Actions: [
      //         {
      //           Trigger: "onClick",
      //           CommandSet: {
      //             FirstCommandId: "1",
      //             ExecuteCommandsInParallel: false,
      //             Commands: [
      //               {
      //                 Id: "1",
      //                 Instruction: {
      //                   Name: "CloseModalPopup",
      //                 },
      //                 Parameters: [
      //                   { Name: "ModalPopupId", Value: "AddTextNotePopup" },
      //                 ],
      //               },
      //             ],
      //           },
      //         },
      //       ],
      //     },
      //   ],
      //   Layout: {
      //     __typename: "LinearLayout",
      //     Id: "21",
      //     // SpacingExtraSmall: "1em",
      //     DirectionExtraSmall: "column",
      //   },
      // } as graphql.Form,
    ],
  } as graphql.SimpleContainer,
};
