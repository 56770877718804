import { signal, useSignalEffect } from "@preact/signals-react";
import { Button, Switch } from "@mui/material";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import { DragIndicator } from "@mui/icons-material";

const BOTTOM_OFFSET = window.innerWidth * 0.15;
const LEFT_OFFSET = window.innerWidth * 0.15;

const toolsPos = signal({
  left: window.innerWidth - LEFT_OFFSET,
  top: window.innerHeight - BOTTOM_OFFSET,
});

export function Tools({
  compactGrid,
  verticalCompactGrid,
  showBorder,
  saveState,
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "tools",
    item: toolsPos.value,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: "tools",
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
      let { left, top } = toolsPos.value;
      left = Math.round(left + delta.x);
      top = Math.round(top + delta.y);
      toolsPos.value = { left, top };
      console.log("drop", drag);
      return undefined;
    },
  }));

  useSignalEffect(() => {
    drop(document.body);
  });

  return (
    <div className="tools" ref={drag} style={toolsPos.value}>
      <DragIndicator className="drag"></DragIndicator>
      {
        <Button variant="outlined" onClick={saveState}>
          Copy Layout
        </Button>
      }
      {/* <Button variant="outlined" onClick={compactGrid}>
        Compact
      </Button> */}
      <Button variant="outlined" onClick={verticalCompactGrid}>
        Tidy Up
      </Button>
      <Switch
        checked={showBorder.value}
        onChange={(e) => {
          showBorder.value = e.target.checked;
        }}
      />
      Show borders
    </div>
  );
}
