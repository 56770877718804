import { useSignal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { INGLibraryProps } from "../library/NGFieldExtensions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Repeater, TreeList } from "../../resolvers-types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import NGIcon from "../components/NGIcon/NGIcon";
import { useRef } from "react";
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { BorderAllSharp } from "@mui/icons-material";
import { buildInitialDataForTree } from "../library/metadataUtils";

const SearchBar = ({ searchTerm, handleSearchChange }) => {
  return (
    <TextField fullWidth placeholder="Search" value={searchTerm} onChange={handleSearchChange} variant="outlined" />
  );
};

const ComponentCard = ({ config, context }) => {
  //const icon = config.Icon || "DeleteForever";

  const ref = useRef(null);

  useSignalEffect(() => {
    const el = ref.current;
    if (!el) return;

    return draggable({
      element: el,
      getInitialDataForExternal: () => ({
        "application/json": JSON.stringify(config),
      }),
      getInitialData: () => buildInitialDataForTree(config),
    });
  });

  return (
    <div className={config.Icon ? "card-container" : "label-container"} ref={ref}>
      {config.Icon && <div className="icon-container">{<NGIcon config={config.Icon} context={context} />}</div>}
      {!config.Icon && <BorderAllSharp />}
      <Typography
        className={config.Icon ? "title-container" : ""}
        variant="body"
        sx={{
          maxWidth: "100%", // Ensure the title text doesn't exceed its container
          textAlign: "center",
          // overflow: "hidden",
          // whiteSpace: "nowrap",
          // textOverflow: "ellipsis",
        }}
      >
        {config.Name}
      </Typography>
    </div>
  );
};

const componentTree = {
  Typename: "TreeList",
  Id: "0xd00set435301",
  UniqueName: "ComponentTreeList",
  AllowReordering: false,
  AllowAdd: false,
  AllowDelete: false,
  ForceExpanded: false,
  KeyConversion: { Id: "Id", Label: "Name", Children: "Features" },
  TopLevelLabelField: "Category",
  Bindings: {
    TreeData: "State.Components",
  },
  Actions: [
    {
      Trigger: "onNodeSelect",
      CommandSet: {
        Id: "0x1000",
        FirstCommandId: "1",
        ExecuteCommandsInParallel: false,
        Commands: [
          {
            Id: "1",
            Instruction: {
              Name: "SetState",
            },
            Parameters: [
              {
                Name: "Bindings",
                Value: {
                  "State.PropertiesEditorTypeName": "Event.TypeName || Event.__typename",
                  "State.PropertiesEditorData": "Event",
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as TreeList;

export const componentRepeater: Repeater = {
  Layout: {
    __typename: "TableLayout",
    Id: "43897r3429yr",
  },
  Style: {
    width: "100%",
  },
  Typename: "Repeater",
  UniqueName: "43956438965",
  Id: "iehfi3474856",
  Variant: "List",
  Bindings: {
    Rows: "State.Components",
  },

  Content: {
    Id: "172654dwytqfd",
    UniqueName: "172654dwytqfd",
    __typename: "Form",
    Items: [
      {
        __typename: "Label",
        Name: "Id",
        Id: "o43758397",
        Draggable: true,
      },
      {
        __typename: "Label",
        Name: "Name",
        Id: "dksnfkaw9qr83",
      },
    ],
  },
};

export default function NGLibrary({ config, context }: INGLibraryProps) {
  const local = setupLocalState(
    config,
    {
      Components: useSignal(config.Components ?? []),
      Name: useSignal(config.Name),
    },
    context
  );

  const searchTerm = useSignal("");

  const handleSearchChange = (e) => {
    searchTerm.value = e.target.value;
  };

  const filteredComponents = local.Components.value?.filter(
    (component) =>
      component != null &&
      component.__typename == "LibraryItem" &&
      (component.Name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        component.Description?.toLowerCase().includes(searchTerm.value.toLowerCase()))
  );

  const groupedComponents = filteredComponents.reduce((groups: { category: string; items: any[] }[], object: any) => {
    const { Category } = object;
    const existingGroup = groups.find((group) => group.category === Category);

    if (existingGroup) {
      existingGroup.items.push(object);
    } else {
      groups.push({ category: Category, items: [object] });
    }

    return groups;
  }, []);

  const handlers = setupHandlers(config, context);

  return (
    <>
      <div className="library">
        <div className="search-bar">
          <SearchBar searchTerm={searchTerm.value} handleSearchChange={handleSearchChange} />
        </div>
        {groupedComponents.map((group, i) => (
          <Accordion key={i} data-testid={`library-accordion-${group.category}`} defaultExpanded>
            <AccordionSummary
              data-testid={`controls-group-title-${group.category}`}
              aria-controls={`controls-group-title-${group.category}`}
              id={`panel1-header-${group.category}`}
              expandIcon={<NGIcon config={{ IconName: "ExpandMore" }} context={context} />}
              className="library-group-header"
            >
              {group.category}
            </AccordionSummary>
            <AccordionDetails className="library-cards-container">
              {group.items.map((component, i) => (
                <ComponentCard key={(component as any)?.Id ?? i} config={component} context={context} {...handlers} />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
}
