import { Switch, SwitchProps, styled } from "@mui/material";
import { Maybe } from "../../../resolvers-types";

interface CustomSwitchProps extends SwitchProps {
  contextid?: Maybe<string> | undefined;
}

const CustomSwitch = styled((props: CustomSwitchProps) => <Switch {...props} disableRipple />)(
  ({ theme, contextid }) => ({
    width: 40,
    height: 20,
    padding: 0,
    position: contextid !== "NGPropEditor" ? "relative" : "absolute",
    "& .MuiSwitch-switchBase": {
      padding: 0,
      right: 12,
      top: 0,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "white",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#679fff",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 10,
      height: 10,
      backgroundColor: theme.palette.mode === "dark" ? "#001e3c" : "#fff",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      opacity: 1,
      boxSizing: "border-box",
    },
  })
);

export default CustomSwitch;
