import { cloneDeep, isNil } from "lodash-es";
import { GridStackContext } from "./NGGridStackLayout";
import { Tools } from "./NGGridStackTools";
import { DeleteForever } from "@mui/icons-material";
import { getGridStackLayoutFromNode, getNodeFromGridStackLayout } from "./NGGridStackUtils";
import { GridStackLayout } from "../../resolvers-types";
import { createRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Signal, useSignal } from "@preact/signals-react";
import { GridStack } from "gridstack";

export function GridStackManaged(props: {
  children: any;
  innerLayouts: GridStackLayout[];
  ngkey: string;
  inDesignMode?: boolean;
  dragging?: Signal<boolean>;
  onChange: (layouts: GridStackLayout[]) => void;
  onRemove: (layouts: GridStackLayout[]) => void;
  onSelectedComponent: (component: any) => void;
  itemToDelete: any;
  handleOnDraggingChange: (dragging: boolean) => void;
  minRows: number;
}) {
  const inDesignMode = props.inDesignMode;
  const itemToDelete = props.itemToDelete;
  const innerContainers = props.children;
  const innerContainersRef = useRef([]);
  const innerLayouts = props.innerLayouts;
  const refs = useRef({});
  const gridRef = useRef(null as GridStack | null);
  const gridCtx = useSignal({ InDesignMode: inDesignMode });
  const showTools = useSignal(props.inDesignMode);
  const showBorder = useSignal(false);
  const dragging = useSignal(cloneDeep(props.dragging));

  useEffect(() => {
    let grid = gridRef.current;

    if (!grid) {
      const gridOpts = {
        dragOut: true,
        acceptWidgets: true,
        float: true,
        column: 24,
        staticGrid: !showTools.value,
        alwaysShowResizeHandle: true,
        cellHeight: 2,
        margin: 0,
        minRow: props.minRows,
        // sizeToContent: true,
        disableOneColumnMode: true,
        resizable: {
          handles: "n,e,w,s,se,sw,nw,ne",
        },
        // removable: true,
        // handle: ".handle",
      };

      grid = GridStack.init(gridOpts, "#" + props.ngkey);

      gridRef.current = grid; // '.controlled')

      grid.on("removed", function (e, items) {
        // console.log(
        //   "~~gridstack event [removed] event e, items:",
        //   e,
        //   items,
        //   window.location.href
        // );
      });

      grid.on("added", function (e, items) {
        // console.log("~~gridstack event [added] event e, items:", e, items);
      });

      // for the "change" event, update just the innerLayout position
      grid.on("change", function (e, changedItems) {
        // console.log(
        //   `~~gridstack event [change] event e, changedItems:`,
        //   e,
        //   changedItems
        // );
        if (!changedItems) return;

        // update gridstack layouts
        const gsLayouts: GridStackLayout[] = [];
        gridRef.current.engine.nodes.forEach((node) => {
          gsLayouts.push(getGridStackLayoutFromNode(node, node.el.attributes["ngid"].value));
        });

        props.onChange(e, gsLayouts);
      });

      grid.on("dragstart", (e) => {
        // console.log("~~gridstack [dragstart]:", e);
        dragging.value = true;
        props.handleOnDraggingChange(true);
      });
      grid.on("dragstop", (e) => {
        // console.log("~~gridstack [dragstop]:", e);
        dragging.value = false;
        props.handleOnDraggingChange(false);
      });
    }
  }, [props.ngkey]);

  // set up the refs here in the body. This ensures that they are ready before the DOM renders the dynamic children
  // by making sure they're ready, we know we can run makeWidget later without worrying about the ref not being ready
  innerContainers.forEach((container) => {
    if (container && !refs.current[container.key]) {
      refs.current[container.key] = createRef();
    }
  });

  useEffect(() => {
    const grid = gridRef.current;
    if (!grid) return;

    grid.batchUpdate();
    grid.removeAll(false);

    const newItems = innerContainers.filter((item) => !innerContainersRef.current.includes(item));

    newItems.forEach((container) => {
      if (isNil(container)) return;
      const layout = innerLayouts.find((l) => l.LayoutItem?.Id === container.key) || {
        X: 0,
        Y: 0,
        H: 24,
        W: 12,
        LayoutItem: { Id: container.key },
      };

      const node = getNodeFromGridStackLayout(layout, inDesignMode);

      if (refs.current[container.key].current) {
        grid.makeWidget(refs.current[container.key].current, node.node);
      }

      if (itemToDelete != null && refs.current[itemToDelete.Id].current) {
        const refToDelete = refs.current[itemToDelete.Id].current;
        grid.removeWidget(refToDelete, true);
      }
    });

    grid.batchUpdate(false);

    innerContainersRef.current = [...innerContainers];
  }, [innerContainers]);

  // Function to load and restore the grid state
  function updateGrid(savedState: GridStackLayout[]) {
    if (savedState) {
      savedState.forEach((layout: GridStackLayout) => {
        const item = getNodeFromGridStackLayout(layout, inDesignMode);
        gridRef.current.update(refs.current[item.id].current, item.node);
      });
    }
  }

  // Function to save the grid state
  function saveState() {
    const gsLayouts: GridStackLayout[] = [];
    gridRef.current.engine.nodes.forEach((node) => {
      gsLayouts.push(getGridStackLayoutFromNode(node, node.el.attributes["ngid"].value));
    });

    // console.log("saving grid", gsLayouts);
    //document.querySelector('#saved-data').value = JSON.stringify(gsLayouts);
    navigator.clipboard
      .writeText(JSON.stringify(gsLayouts))
      .then(() => {
        alert("Layout state saved to clipboard");
      })
      .catch(() => {
        alert("Error: Couldn't copy text to clipboard");
      });
  }

  function compactGrid() {
    // console.log("~~compact grid hit");
    gridRef.current.compact();
  }

  function verticalCompactGrid() {
    // console.log("~~verticalCompactGrid hit");

    gridRef.current.float(false);
    gridRef.current.float(true);
  }

  const handleRemove = (removedItem) => {
    // console.log("remove was clicked", removedItem);

    // Logic to remove the item from GridStack
    if (gridRef && gridRef.current) {
      const element = gridRef.current.el.querySelector(`[ngid="${removedItem.key}"]`);
      if (element) {
        gridRef.current.removeWidget(element, false); // if true, will not work with react
      }
    }

    // Your existing logic to update gsLayouts
    const gsLayouts = [];
    gridRef.current.engine.nodes.forEach((node) => {
      gsLayouts.push(getGridStackLayoutFromNode(node, node.el.attributes["ngid"].value));
    });

    props.onRemove(removedItem, gsLayouts);
  };

  const selected = useSignal(null);

  return (
    <>
      <GridStackContext.Provider value={gridCtx}>
        <div className={`${showBorder.value ? "debug" : ""} ${dragging.value ? "dragging" : ""}`}>
          {showTools.value && (
            <Tools
              saveState={saveState}
              showBorder={showBorder}
              compactGrid={compactGrid}
              verticalCompactGrid={verticalCompactGrid}
            />
          )}
          <div className={`grid-stack`} id={props.ngkey} onClick={(e) => (selected.value = null)}>
            {innerContainers.map((item: any, i: number) => {
              if (isNil(item)) return <></>;

              return (
                <div
                  ref={refs.current[item.key]}
                  key={item.key}
                  ngid={item.key}
                  className={`grid-stack-item ${selected.value == item.key ? "selected" : ""} ${item.key}`}
                >
                  <div className="grid-stack-item-content">
                    <div
                      className="element"
                      onClickCapture={(e) => {
                        if (inDesignMode) {
                          selected.value = item.key;
                          // console.log("~~item, item.key:", item, item.key);
                          props.onSelectedComponent(item.props.layoutItem); // TODO: make this specific to what is selected, not just controls
                          e.stopPropagation();
                          // setupHandlers.onSelect(item.key);
                        }
                      }}
                    >
                      {item}
                    </div>
                    {inDesignMode && item && item.props && (
                      <div className="handle-container">
                        <div className="handle">
                          {item.props.layoutItem &&
                            (item.props.layoutItem.__typename || item.props.layoutItem.Typename)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </GridStackContext.Provider>
    </>
  );
}
