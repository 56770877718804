import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  List as MUIList,
  ListItem as MUIListItem,
} from "@mui/material";
import { computed, signal, useSignal } from "@preact/signals-react";
import { useEffect, useRef } from "react";
import { setupLocalState, updateItemContext } from "../../library/dataService";
import NGIcon from "../NGIcon/NGIcon";
import { FlexLayout, LayoutItem, Repeater } from "../../../resolvers-types";
import { cloneDeep, isNil } from "lodash-es";
import NGTableLayout from "../../layouts/NGTableLayout";
import NGTableRow from "../../layouts/NGTableRow";
import NGLayoutItem from "../../generators/NGLayoutItem";
import { INGGroupedRepeaterProps, INGRepeaterProps, RuntimeContext } from "../../library/NGFieldExtensions";
import NGFlexLayout from "../../layouts/NGFlexLayout";
import { cloneJsonWithIDs, getTypename } from "../../library/metadataUtils";
import { generateUID, getClassName, getTestId, getsxObject } from "../../library/utils";
import NGSimpleContainer from "../../generators/NGSimpleContainer";

function getItemLEGACY(config, itemConfig, r, i) {
  const item = {
    ...cloneDeep(itemConfig), // deepClone is used to avoid reusing the previous object which results in duplicate ids
    Id: `${config.Id}_${i}`,
    // Repeater: { Config: config, Index: i, Row: r },
  };

  const type = getTypename(itemConfig);
  if (type == "Component") {
    const inputs = config.ContentInputs;
    const state = item.State;
    Object.entries(inputs).forEach(([k, v]) => {
      state[k] = v == "Index" ? i : r;
    });
  } else {
    item.Data = r;
  }

  // AA-TODO: put repeater data onto context
  // Setup form context
  // item.Bindings ||= {};
  // item.Bindings.Data = "Repeater.Row";

  // if (!isNullOrEmpty(config.Bindings?.Rows)) {
  //   item.Bindings = {
  //     Data: `${config.Bindings?.Rows}[${i}]`,
  //   };
  // } else {
  // }

  return item;
}

function getItemNEW(config, itemConfig, r, i, id: string | undefined | null = null, isTemplateRow = false) {
  const item = {
    //...cloneDeep(itemConfig), // deepClone is used to avoid reusing the previous object which results in duplicate ids
    ...(isTemplateRow ? itemConfig : cloneJsonWithIDs(itemConfig, {})),
    Id: id ?? `${config.Id}_${i}`,
    // Repeater: { Config: config, Index: i, Row: r },
  };

  const type = getTypename(itemConfig);
  if (type == "Component") {
    const inputs = config.ContentInputs;
    const state = item.State;
    Object.entries(inputs).forEach(([k, v]) => {
      state[k] = v == "Index" ? i : r;
    });
  } else {
    item.Data = r;
  }

  // AA-TODO: put repeater data onto context
  // Setup form context
  // item.Bindings ||= {};
  // item.Bindings.Data = "Repeater.Row";

  // if (!isNullOrEmpty(config.Bindings?.Rows)) {
  //   item.Bindings = {
  //     Data: `${config.Bindings?.Rows}[${i}]`,
  //   };
  // } else {
  // }

  return item;
}

export function NGGroupedRepeater({ config, context, local }: INGGroupedRepeaterProps) {
  const grouping = config.Grouping;

  const groupedByStatusAndDate = computed(() => {
    return local.Rows.value?.reduce(
      (acc, row) => {
        // Combine Status and Date to create a unique key for each group
        const keyAsObj = {};

        config.Grouping?.Fields?.forEach((f) => {
          keyAsObj[f] = row[f];
        });

        const key = config.Grouping?.Fields.map((f) => row[f]).join("-");

        if (isNil(key)) return acc;

        // If the accumulator doesn't have an array for this key, create it
        if (!acc[key]) {
          acc[key] = {
            keyAsObj: keyAsObj,
            value: [],
          };
        }

        // Push the current row into the appropriate group
        acc[key].value.push(row);

        return acc;
      },
      {} as Record<string, any[]>
    );
  });

  if (isNil(grouping)) return null;

  return (
    <>
      {Object.keys(groupedByStatusAndDate.value).map((key, i) => {
        const o = groupedByStatusAndDate.value[key];
        const rows = signal(o.value);

        const config2: Repeater = {
          ...config,
          Id: `${config.Id}_${key}`,
          Grouping: null,
          Bindings: null,
        };

        const baseTestId = `${getTestId(config)}-${key}`;
        const context2: RuntimeContext = { ...context, Repeater: { Group: key, Rows: rows } };
        const layoutItem = {
          ...config.Grouping?.Title,
          Id: `${config.Id}-${key}`,
        };

        return (
          <Accordion
            key={i}
            disableGutters={grouping.DisableGutters as any}
            defaultExpanded={grouping.Expanded as any}
            data-testid={`${baseTestId}-accordion`}
            className={getClassName(local.Classes)}
          >
            <AccordionSummary
              expandIcon={grouping.ExpandIcon ? <NGIcon config={grouping.ExpandIcon} context={context2} /> : null}
              data-testid={`${baseTestId}-title`}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {config.Grouping?.Title && <NGLayoutItem config={layoutItem} context={context2} />}
            </AccordionSummary>
            <AccordionDetails>
              <NGRepeater config={config2} context={context2} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

export default function NGRepeater({ config, context }: INGRepeaterProps) {
  const tag = "NGRepeater";
  const listEndRef = useRef(null);

  const local = setupLocalState(
    config,
    {
      Rows: useSignal(config.Rows ?? []), // Rows is the data as passed in by the bindings
      Loading: useSignal(config.Loading ?? false),
      AllowReordering: useSignal(config.AllowReordering ?? false),
      AllowAdd: useSignal(config.AllowAdd ?? false),
      AllowDelete: useSignal(config.AllowDelete ?? false),
      ScrollToLast: useSignal(config.ScrollToLast ?? false),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? []),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  useEffect(() => {
    const rows = context?.Repeater?.Rows?.value;
    if (!isNil(rows)) {
      local.Rows.value = rows;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.Repeater?.Rows?.value]);

  useEffect(() => {
    if (local.ScrollToLast.value && listEndRef.current) {
      (listEndRef.current as any).scrollIntoView({ behavior: "smooth" });
    }
  }, [local.ScrollToLast.value, local.Rows.value]);

  function isTable() {
    return !isNil(config.Layout) && !isFlex() && config.Layout?.__typename == "TableLayout";
  }

  function isGrid() {
    return !isTable() && !isFlex() && (isNil(config.Variant) || config.Variant?.toLowerCase() == "grid");
  }

  function isList() {
    return !isTable() && !isFlex() && config.Variant?.toLowerCase() == "list";
  }

  function isFlex() {
    return config.Variant?.toLowerCase() == "flex";
  }

  function isGrouped() {
    return !isNil(config.Grouping);
  }

  const content = config.Content ?? (config.Items ? config.Items[0] : null);

  return (
    <>
      {local.Visible.value && isGrouped() && <NGGroupedRepeater config={config} context={context} local={local} />}
      {local.Visible.value && !isGrouped() && isGrid() && (
        <Box
          sx={getsxObject(config.Style, {
            display: "flex",
            placeContent: "flex-start",
            alignItems: "flex-start",
            flexFlow: "wrap",
          })}
        >
          {local.Rows.value?.map((r, i) => {
            const item = getItemLEGACY(config, content, r, i);
            const currentContext = updateItemContext(context, config, { Name: config.Name }, i);
            currentContext.Repeater = { Row: r, Index: i };
            return <NGLayoutItem key={generateUID()} config={item} context={currentContext} />;
          })}

          {local.ScrollToLast.value && <div ref={listEndRef}></div>}
        </Box>
      )}

      {local.Visible.value && !isGrouped() && isFlex() && (
        //<Box sx={getsxObject(config.Style)} data-testid={getTestId(config)} data-type={config.__typename}>
        <NGFlexLayout config={config} context={context} inDesignMode={false} layoutItem={config}>
          {context.InDesignMode &&
            content &&
            renderFlexItem(config, content, local.Rows.value?.[0] ?? {}, 0, context, true)}
          {local.Rows.value?.slice(context.InDesignMode ? 1 : 0).map((r, i) => {
            return renderFlexItem(config, content, r, context.InDesignMode ? i + 1 : i, context, false);
          })}
          {local.ScrollToLast.value && <div ref={listEndRef}></div>}
        </NGFlexLayout>
        //</></Box>
      )}

      {local.Visible.value && !isGrouped() && isTable() && (
        <NGTableLayout
          key={config.Layout?.Id}
          config={config}
          tableLayout={config.Layout as any}
          // context={updateItemContext(context, config)}
          context={context}
        >
          {local.Rows.value?.map((r, i) => {
            const item = getItemLEGACY(config, config.Content, r, i);
            const details = isNil(config.Details) ? {} : getItemLEGACY(config, config.Details, r, i);

            const currentContext = updateItemContext(context, config, { Name: config.Name }, i);
            currentContext.Repeater = { Row: r, Index: i };

            return <NGTableRow key={generateUID()} item={item} details={details} context={currentContext} />;
          })}
        </NGTableLayout>
      )}
      {local.Visible.value && !isGrouped() && isList() && (
        <Box sx={getsxObject(config.Style)}>
          <MUIList>
            {local.Rows.value?.map((r, i) => {
              const item = getItemLEGACY(config, config.Content, r, i);
              const currentContext = updateItemContext(context, config, { Name: config.Name }, i);
              currentContext.Repeater = { Row: r, Index: i };

              return (
                <MUIListItem key={generateUID()}>
                  <NGLayoutItem config={item} context={currentContext} />
                </MUIListItem>
              );
            })}
          </MUIList>
          {local.ScrollToLast.value && <div ref={listEndRef}></div>}
        </Box>
      )}
    </>
  );
}
// {local.Visible.value && !isGrouped() && isTable() && (
//   <div data-testid={getTestId(config)} data-type={config.__typename}>
//     <NGTableLayout
//       key={config.Layout?.Id}
//       config={config}
//       tableLayout={config.Layout as any}
//       // context={updateItemContext(context, config)}
//       context={context}
//     >
//       {context.InDesignMode &&
//         content &&
//         renderTableRowItem(config, content, local.Rows.value?.[0] ?? {}, 0, context, true)}
//       {local.Rows.value?.slice(context.InDesignMode ? 1 : 0).map((r, i) => {
//         return renderTableRowItem(config, content, r, context.InDesignMode ? i + 1 : i, context, false);
//       })}
//     </NGTableLayout>
//   </div>
// )}
// {local.Visible.value && !isGrouped() && isList() && (
//   <Box sx={getsxObject(config.Style)} data-testid={getTestId(config)} data-type={config.__typename}>
//     <MUIList>
//       {context.InDesignMode &&
//         content &&
//         renderListItem(config, content, local.Rows.value?.[0] ?? {}, 0, context, true)}

//       {local.Rows.value?.slice(context.InDesignMode ? 1 : 0).map((r, i) => {
//         return renderListItem(config, content, r, context.InDesignMode ? i + 1 : i, context, false);
//       })}
//     </MUIList>
//     {local.ScrollToLast.value && <div ref={listEndRef}></div>}
//   </Box>
// )}

function renderFlexItem(
  config: Repeater,
  content: LayoutItem | null,
  r: any,
  i: any,
  context: RuntimeContext,
  isTemplateRow: boolean
) {
  const item = getItemNEW(config, content, r, i, isTemplateRow ? (content as any).Id : null, isTemplateRow);
  const currentContext = updateItemContext(context, config, { Name: config.Name }, i);
  currentContext.Repeater = { Row: r, Index: i };

  return <NGLayoutItem key={generateUID()} config={item} context={currentContext} />;
}

// function renderListItem(
//   config: Repeater,
//   content: LayoutItem | null,
//   r: any,
//   i: any,
//   context: RuntimeContext,
//   isTemplateRow: boolean
// ) {
//   const item = getItemLEGACY(config, content, r, i, isTemplateRow ? (content as any).Id : null, isTemplateRow);
//   const currentContext = updateItemContext(context, config, { Name: config.Name }, i);
//   currentContext.Repeater = { Row: r, Index: i };

//   return (
//     <MUIListItem key={generateUID()}>
//       <NGLayoutItem config={item} context={currentContext} />
//     </MUIListItem>
//   );
// }

// function renderTableRowItem(
//   config: Repeater,
//   content: LayoutItem | null,
//   r: any,
//   i: any,
//   context: RuntimeContext,
//   isTemplateRow: boolean
// ) {
//   const item = getItemLEGACY(config, content, r, i, isTemplateRow ? (content as any).Id : null, isTemplateRow);
//   const details = isNil(config.Details)
//     ? {}
//     : getItemLEGACY(config, config.Details, r, i, isTemplateRow ? config.Details.Id : null, isTemplateRow);

//   const currentContext = updateItemContext(context, config, { Name: config.Name }, i);
//   currentContext.Repeater = { Row: r, Index: i };

//   return <NGTableRow key={generateUID()} item={item} details={details} context={currentContext} />;
// }
