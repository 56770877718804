import { Stack, TextField, Typography } from "@mui/material";

export default function NGStyleInput({ label, value, name, onChange, sx = {}, type = "text" }) {
  return (
    <Stack
      key={name}
      sx={{
        alignItems: "flex-start",
        flexDirection: "column",
        color: "inherit",
        ...sx,
      }}
    >
      <Typography className="control-label">{label}</Typography>
      <TextField
        data-testid={name}
        className="prop-editor-input"
        sx={{
          "& .MuiInputBase-root": {
            height: "40px",
          },
          width: "100%",
        }}
        onChange={onChange}
        type={type}
        value={value ?? ""}
      />
    </Stack>
  );
}
