import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatUnderlinedSharp from "@mui/icons-material/FormatUnderlinedSharp";
import FormatOverlineSharp from "@mui/icons-material/FormatOverlineSharp";
import FormatStrikethroughSharp from "@mui/icons-material/FormatStrikethroughSharp";
import { Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { INGTextEditorProps } from "../../library/NGFieldExtensions";

export default function NGTextEditor({ selected: s }: INGTextEditorProps) {
  const handleChange = (n: { [key: string]: string }) => {
    const c = { ...s.value };
    const v = Object.keys(n)
    const a = v.map((v1) => s.value?.[v1] === n?.[v1]);
    const sa = a.some((i) => !i);

    if (sa) {
      return s.value = { ...s.value, ...n }
    } else {
      v.forEach((v2) => {
        delete c?.[v2]
      })
    }
    s.value = c
  };

  return (
    <Stack direction="row" flex={1} width="100%" justifyContent="space-between">
      <Stack direction="column" justifyContent="space-between" width="48%">
        <Typography className="control-label">Text Align</Typography>
        <RadioGroup
          row
          name="textAlign"
          value={s.value.textAlign ?? ""}
          className="layout-editor-radio-button"
          sx={{ width: "100%" }}
        >
          <Radio
            icon={<FormatAlignLeftIcon />}
            checkedIcon={<FormatAlignLeftIcon />}
            value="left"
            sx={{
              "&.MuiRadio-root.Mui-checked": {
                color: "rgba(50, 50, 50, 1)",
              },
            }}
            onClick={() => handleChange({ textAlign: "left", justifyContent: "flex-start" })}
            className="layout-editor-radio-child"
          />
          <Radio
            icon={<FormatAlignCenterIcon />}
            checkedIcon={<FormatAlignCenterIcon />}
            value="center"
            sx={{
              "&.MuiRadio-root.Mui-checked": {
                color: "rgba(50, 50, 50, 1)",
              },
            }}
            onClick={() => handleChange({ textAlign: "center", justifyContent: "center" })}
            className="layout-editor-radio-child"
          />
          <Radio
            icon={<FormatAlignRightIcon />}
            checkedIcon={<FormatAlignRightIcon />}
            sx={{
              "&.MuiRadio-root.Mui-checked": {
                color: "rgba(50, 50, 50, 1)",
              },
            }}
            value="right"
            onClick={() => handleChange({ textAlign: "right", justifyContent: "flex-end" })}
            className="layout-editor-radio-child"
          />
        </RadioGroup>
      </Stack>
      <Stack direction="column" justifyContent="space-between" width="48%">
        <Typography className="control-label">Text Decoration</Typography>
        <RadioGroup
          row
          name="decoration"
          value={s.value?.textDecoration ?? ""}
          sx={{ width: "100%" }}
          className="layout-editor-radio-button"
        >
          <Radio
            icon={<FormatUnderlinedSharp />}
            checkedIcon={<FormatUnderlinedSharp />}
            value="underline"
            sx={{
              "&.MuiRadio-root.Mui-checked": {
                color: "rgba(50, 50, 50, 1)",
              },
            }}
            onClick={() => handleChange({ textDecoration: "underline" })}
            className="layout-editor-radio-child"
          />
          <Radio
            className="layout-editor-radio-child"
            icon={<FormatStrikethroughSharp />}
            checkedIcon={<FormatStrikethroughSharp />}
            value="line-through"
            sx={{
              "&.MuiRadio-root.Mui-checked": {
                color: "rgba(50, 50, 50, 1)",
              },
            }}
            onClick={() => handleChange({ textDecoration: "line-through" })}
          />
          <Radio
            icon={<FormatOverlineSharp />}
            checkedIcon={<FormatOverlineSharp />}
            value="overline"
            onClick={() => handleChange({ textDecoration: "overline" })}
            sx={{
              "&.MuiRadio-root.Mui-checked": {
                color: "rgba(50, 50, 50, 1)",
              },
            }}
            className="layout-editor-radio-child"
          />
        </RadioGroup>
      </Stack>
    </Stack>
  );
}
