import { useSignal, signal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { log } from "../../library/logger";
import { INGHtmlProps } from "../../library/NGFieldExtensions";
import { getClassName, getTestId, getsxObject } from "../../library/utils";
import { Box } from "@mui/material";

const tag = "NGHtml";

export default function NGHtml({ config, context }: INGHtmlProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const sanitizedHtml = useSignal("");
  useSignalEffect(() => {
    const html = local.Value.value;
    (async () => {
      const purify = await import("dompurify");
      sanitizedHtml.value = purify.default().sanitize(html);
      log.info(tag, "sanitized html", html, sanitizedHtml);
    })();
  });

  return (
    <>
      {local.Visible.value && local.Value.value && (
        <Box
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml.value }}
          {...handlers}
        ></Box>
      )}
    </>
  );
}
