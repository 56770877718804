import { isEmpty, isNil, isNull } from "lodash-es";
import { FieldDefinition, TypeWithProperties } from "../../gqlplugins/generateEditorJSON";
import jsonData from "../../propertiesEditor/editorProperties.json";
import { log } from "../../library/logger";
import { camelCaseToTitleCase, isNullOrEmpty } from "../../library/utils";

type StringDictionary = { [key: string]: string };
export type ChildrenAndState = {
  externalControlType: string;
  children: StringDictionary;
  states: StringDictionary;
};

// This method gets a child and a state as an input and it returns an object with the corresponding class.
// For example, if in a button, child = "text" and state = "focused", it will return
// a class as follows: { "& .MuiButton-text": { "&:focused": {} } }
// Note that child and state are optional, but at least one of them must be provided.
export function getClassForChildAndState(child: string, state: string) {
  const o = {};
  let parent = o;

  if (!isNullOrEmpty(child)) {
    parent = o[`& .${child}`] = {}; // this is a double assignment
  }

  if (!isNullOrEmpty(state)) {
    if (state.toLocaleLowerCase() === "hover") parent[`&:${state}`] = {};
    else parent[`&.${state}`] = {};
  }

  return o;
}

export function doesItSupportConfigurations(typename?: string): boolean {
  if (isNil(typename)) return false;

  const t = `${typename}StyleConfigurations`; //jsonData[];

  const o = jsonData[t];

  return !isNil(o);
}

export function getRootQualifier(typename: string): string | null {
  const tag = "getRootQualifier";
  const t = `${typename}StyleConfigurations`; //jsonData[];

  const o = jsonData[t];

  if (isNil(o)) {
    log.error(tag, `Type ${t} not found in editorProperties.json`);
    return null;
  }

  const externalControlType = o.fields.find((f: FieldDefinition) => f.name === "ExternalControlType")?.directives
    ?.constant?.value;

  return `.${externalControlType}-root`;
}

// This method gets a typename as an input and it returns an object with the list of possible children and states for that type.
export function getChildrenAndState(typename: string): ChildrenAndState {
  const tag = "getChildrenAndState";

  // Look for that class in the json file
  const t = `${typename}StyleConfigurations`; //jsonData[];

  const o = jsonData[t];

  if (isNil(o)) {
    log.error(tag, `Type ${t} not found in editorProperties.json`);
    return { externalControlType: typename, children: {}, states: {} } as ChildrenAndState;
  }

  const externalControlType = o.fields.find((f: FieldDefinition) => f.name === "ExternalControlType")?.directives
    ?.constant?.value;

  const externalChildren = o.fields.find((f: FieldDefinition) => f.name === "ExternalChildren")?.directives?.mappings
    ?.value;

  const slots = o.fields.find((f: FieldDefinition) => f.name === "Slots")?.directives?.recommendedValues?.values;

  const s = o.fields.find((f: FieldDefinition) => f.name === "States")?.directives?.recommendedValues?.values ?? [
    "active",
    "checked",
    "completed",
    "disabled",
    "error",
    "expanded",
    "focusVisible",
    "focused",
    "hover",
    "readOnly",
    "required",
    "selected",
  ];

  const states = s?.reduce((acc: StringDictionary, state: string) => {
    acc[camelCaseToTitleCase(state)] = `Mui-${state}`;
    return acc;
  }, {} as StringDictionary);

  states["Hover"] = "hover";

  const slotsAsChildren = slots?.reduce((acc: StringDictionary, slot: string) => {
    acc[slot] = `${externalControlType}-${slot}`;
    return acc;
  }, {} as StringDictionary);

  const c = { ...externalChildren, ...slotsAsChildren };

  const children = Object.keys(c).reduce((acc: StringDictionary, slot: string) => {
    acc[camelCaseToTitleCase(slot)] = c[slot]; //`${externalControlType}-${slot}`;
    return acc;
  }, {} as StringDictionary);

  return { externalControlType, children, states };
}

export const hasObjectProperies = (obj: any): boolean => {
  return Object.keys(obj).some((key) => typeof obj[key] === "object");
};

export const hasNonObjectProperies = (obj: any): boolean => {
  return Object.keys(obj).some((key) => typeof obj[key] !== "object");
};

export const getNonObjectProperies = (obj: any): any => {
  return Object.keys(obj).filter((key) => typeof obj[key] !== "object");
};

const extractSelectors = (obj: any = {}, path: string[] = []): string[][] => {
  let result: string[][] = [];

  Object.keys(obj).forEach((key) => {
    if (key.startsWith("&")) {
      const newPath = [...path, key];

      if (hasNonObjectProperies(obj[key]) || isEmpty(obj[key])) result.push(newPath);

      if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
        result = result.concat(extractSelectors(obj[key], newPath));
      }
    }
  });

  return result;
};

const findKeyByValue = (obj: any, valueToFind: any): string | null => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === valueToFind) {
      return key;
    }
  }
  return null;
};

function getReadableLabelFromConfiguation(configuration: string, childrenAndStates: ChildrenAndState) {
  // Is it a slot?
  if (configuration.startsWith("& .")) {
    return findKeyByValue(childrenAndStates.children, configuration.substring(3));
  } else if (configuration.startsWith("&.") || configuration.startsWith("&:")) {
    return findKeyByValue(childrenAndStates.states, configuration.substring(2));
  }
}

// This method gets a typename and an existing style as an input and it returns a list of configurations for that style.
// The configurations are returned as an array of objects with a label and a value.
// the value is the path to the configuration in the style object.
export function getListOfConfigurationsFromStyle(typename: string = '', style: any = {}) {
  const paths = extractSelectors(style);
  const childrenAndStates = getChildrenAndState(typename);

  const t = paths.map((path: string[]) => {
    const readablePath = path.map((p) => getReadableLabelFromConfiguation(p, childrenAndStates)).join(" > ");

    return {
      Label: readablePath,
      Value: path,
    };
  });

  return [{ Label: "Default", Value: [] }, ...t];
}
