import type { ConnectionOptions, MsgHdrs, NatsConnection } from "nats.ws";
import { JSONCodec, headers, jwtAuthenticator } from "nats.ws";
import { connect } from "nats.ws";
import type { Result } from "../models/result";
// import { createContext } from "react";
import { signal } from "@preact/signals-react";
import { generateUID, parseCookies } from "./utils";
import { json } from "react-router-dom";
import { LogTag, log } from "./logger";

export enum Status {
  Online,
  Offline,
  Connecting,
}

const serverUrl = import.meta.env.VITE_NATS_SERVER_URL;
const useJwtAuth = import.meta.env.VITE_NATS_AUTH_JWT == "true";

const jc = JSONCodec();
let nc: NatsConnection | null = null;

const msgHeaders = headers();

let connectPromise: Promise<NatsConnection> | null = null;

export const status = signal(Status.Offline);

const tag: LogTag = "nats";

export function ensureConnected(): Promise<NatsConnection | null> {
  if (status.value == Status.Online) return Promise.resolve(nc);
  if (status.value == Status.Connecting && connectPromise) return connectPromise;

  const id = generateUID();
  status.value = Status.Connecting;

  const cookies = parseCookies();

  const opts: ConnectionOptions = { servers: serverUrl };
  if (useJwtAuth) {
    const jwt = cookies["Api.NatsJwt"];
    const seed = cookies["Api.NatsSeed"];
    const token = cookies["Api.Token"];

    if (!jwt || !seed || !token) {
      throw json({ Message: "Unauthorized" }, { status: 401 });
    }

    msgHeaders.set("Token", token);
    opts.authenticator = jwtAuthenticator(jwt, new TextEncoder().encode(seed));
  }

  // TODO: make it real
  // const project = "CMS";
  // msgHeaders.set("Project", project);

  const branch = cookies["Api.Branch"];
  if (branch) {
    msgHeaders.set("Branch", branch);
  }

  log.info(tag, `Connecting to: ${opts.servers}, jwt: ${useJwtAuth}`, id);
  connectPromise = connect(opts).then((conn) => {
    nc = conn;
    status.value = Status.Online;
    log.info(tag, `Connected to: ${nc.getServer()}`, id);

    (async () => {
      for await (const s of nc.status()) {
        switch (s.type) {
          case "reconnect":
            status.value = Status.Online;
            break;
          case "disconnect":
            status.value = Status.Offline;
            break;
          case "reconnecting":
            status.value = Status.Connecting;
            break;
          case "update":
          default:
            // Do nothing
            break;
        }
        log.info(tag, `Status ${s.type}: ${s.data}`, s, status.value);
      }
    })();

    nc.closed().then((e) => {
      log.info(tag, "Connection closed", e);
      status.value = Status.Offline;
    });

    return nc;
  });
  return connectPromise;
}

export async function subscribe(subject: string, action: (message: unknown) => void) {
  const nc = await ensureConnected();
  if (!nc) return;

  const sub = nc.subscribe(subject);

  (async () => {
    for await (const m of sub) {
      const message = jc.decode(m.data);
      log.info(tag, `[${sub.getProcessed()}]: ${m.subject} - ${message}`);
      action(message);
    }
  })();
}

export async function subscribeStream(subject: string, action: (message: unknown) => void) {
  const nc = await ensureConnected();
  if (!nc) return;

  const consumer = await nc.jetstream().consumers.get(subject);
  const cm = await consumer.consume();

  (async () => {
    for await (const m of cm) {
      const message = jc.decode(m.data);
      log.info(tag, `[${cm.getProcessed()}]: ${m.subject} - ${message}`);
      action(message);
    }
  })();
}

export function disconnect() {
  log.info(tag, "Disconnecting");
  nc?.drain();
  nc?.close();
}

export async function publish(subject, data, headers: object = {}) {
  const nc = await ensureConnected();
  if (!nc) return;

  const baseHeaders = getHeaders();

  nc.publish(subject, jc.encode(data), {
    headers: addCustomHeaders(baseHeaders, headers),
  });
}

function getHeaders() {
  return msgHeaders;
}

function addCustomHeaders(baseHeaders: MsgHdrs, headers: object) {
  for (const key in headers) {
    if (Object.prototype.hasOwnProperty.call(headers, key)) {
      baseHeaders.set(key, headers[key]);
    }
  }

  return baseHeaders;
}

export async function request<T>(subject: string, data: object, headers: object = {}): Promise<Result<T>> {
  const nc = await ensureConnected();
  if (!nc) return { success: false, reasons: [{ message: "Not connected" }] };
  let msg;
  const baseHeaders = getHeaders();
  const customHeaders = addCustomHeaders(baseHeaders, headers);
  try {
    msg = await nc.request(subject, jc.encode(data), {
      timeout: 60000,
      headers: customHeaders,
    });
    if (msg === undefined) {
      log.error(tag, `No response from ${subject}`, customHeaders);
      return { success: false, reasons: [{ message: "No response" }] };
    }
    const resp = jc.decode(msg.data) as Result<T>;
    return resp;
  } catch (err: any) {
    log.error(tag, `Error: ${err}`, subject, data, msg, customHeaders);
    return { success: false, reasons: [err.message] };
  }
}

async function getObjectStore(bucket) {
  if (nc == null || status.value != Status.Online) return { success: false, reasons: [{ message: "Not connected" }] };

  const js = nc.jetstream();
  const os = await js.views.os(bucket);

  return { success: true, data: os };
}

export async function objPut(
  bucket: string,
  name: string,
  description: string,
  fileSize: number,
  stream: ReadableStream<Uint8Array>
) {
  const res = await getObjectStore(bucket);
  if (!res.success || res.data == null) return res;
  const os = res.data;

  const status = await os.status();
  const curSize = status.size;
  const maxBytes = status.streamInfo.config.max_bytes;
  log.info(
    tag,
    "~~status: ",
    status,
    "size: ",
    status.size,
    "max:",
    maxBytes,
    "curSize + fileSize: ",
    curSize + fileSize
  );
  if (curSize + fileSize >= maxBytes) {
    return {
      success: false,
      reasons: [{ message: "Max store size exceeded" }],
    };
  }

  try {
    const info = await os.put({ name: name, description }, stream, {
      timeout: 5 * 60 * 1000, // 5 minutes
    });
    return { success: true, data: info, reasons: [] };
  } catch (e) {
    return { success: false, reasons: [{ message: "Could not upload file" }] };
  }
}

export async function objGet(bucket: string, name: string) {
  const res = await getObjectStore(bucket);
  if (!res.success || res.data == null) return res;
  const os = res.data;

  const data = await os.getBlob(name);
  if (data == null) return { success: false, reasons: [{ message: "Failed to get file" }] };
  return { success: true, data: new Blob([data.buffer]) };
}

export async function objDelete(bucket: string, name: string) {
  const res = await getObjectStore(bucket);
  if (!res.success || res.data == null) return res;
  const os = res.data;

  const result = await os.delete(name);
  return { success: result.success, reasons: [] };
}

export const client = {
  connect: ensureConnected,
  request: request,
  publish: publish,
  disconnect: disconnect,
  subscribe: subscribe,
  subscribeStream: subscribeStream,
  objPut: objPut,
  objGet: objGet,
  objDelete: objDelete,
};
