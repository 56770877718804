import * as graphql from "../../../resolvers-types";
import serviceNotesHistory from "./service-review-notes-history";

export const notesForm: graphql.Form = {
  Id: "notesForm",
  UniqueName: "notesForm",
  __typename: "Form",
  Style: {
    // width: "75vh",
    //minWidth: "250px",
  },
  //Bindings: { Data: "State.ReviewsByItem" },

  // ReviewDateTime: "2023-12-18T15:30:00Z",
  // Text: "I found this application extremely user-friendly and efficient.",
  // NoteType: "Audio",
  // Audio: "voice_note_1.mp3",
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "dferwtw34t",
      Layout: {
        __typename: "FlexLayout",
        Direction: "row",
        JustifyContent: "space-between",
      },
      Items: [
        {
          __typename: "Label",
          Id: "TextNoteLabel",
          Bindings: {
            Visible: "FormData.NoteType=='Text'",
          },
          Value: "Text Note",
          Style: {
            fontWeight: "bold",
          },
        },
        {
          __typename: "Label",
          Id: "VoiceNoteLabel",
          Bindings: {
            Visible: "FormData.NoteType=='Audio'",
          },
          Value: "Voice Note",
          Style: {
            fontWeight: "bold",
          },
        },
        {
          __typename: "Label",
          Name: "ReviewDateTime",
          Id: "sd2345dqw",
          DateFormat: { DateStyle: "medium", TimeStyle: "short" },
          Style: { fontSize: "0.8rem" },
        },
      ],
    },
    {
      __typename: "Label",
      Name: "Text",
      Id: "dffwssseq",
      Bindings: {
        Visible: "FormData.NoteType=='Text'",
      },
    },
    {
      //NoteType
      __typename: "AudioPlayer",
      Name: "Audio",
      Id: "3rwed",
      DestinationBucket: "reviews",
      Bindings: {
        Value: "FormData.Audio",
        Text: "FormData.Text",
        Visible: "FormData.NoteType=='Audio'",
      },
      TranscriptStyle: { fontSize: "0.8rem" },
    },
  ],
  Layout: {
    __typename: "FlexLayout",
    Id: "pf_InputField/La",
    Direction: "column",
    Style: {
      borderBottom: "1px solid lightGray",
      // borderStyle: "solid",
      // borderWidth: "1px",
      // borderColor: "lightGray",
      // borderRadius: "8px",
      //padding: "4px",
      paddingTop: "10px",
      width: "100%",
    },
  },
};

export const reviewHistoryRepeater: graphql.Repeater = {
  Typename: "Repeater",
  UniqueName: "reviewHistoryRepeater",
  Id: "reviewHistoryRepeater",
  Variant: "List",
  Bindings: {
    Rows: "State.ReviewHistory.Notes",
  },
  // Style: {
  //   gap: "4px",
  //   display: "grid",
  //   gridTemplateColumns: "repeat(auto-fit, minmax(90px,1fr))",
  //   backgroundColor: "white",
  //   borderRadius: "8px",
  //   padding: "6px",
  // },
  Content: notesForm,
};

const reviewHistory: graphql.Page = {
  __typename: "Page",
  Id: "dashboardReviewByWidget",
  InDesignMode: false,
  Name: "Dashboard Review By Widget",
  State: {},
  Services: [serviceNotesHistory],
  Items: [reviewHistoryRepeater],
  Layout: {
    __typename: "LinearLayout",
    Id: "1",
    LayoutItem: {
      __typename: "Page",
      Id: "dashboardReviewByWidget",
    } as graphql.Page,
  },
};

export default reviewHistory;
