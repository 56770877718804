import { Paper } from "@mui/material";
import { isNil } from "lodash-es";
import { getTestId, getsxObject, isVisible } from "../library/utils";
import { RuntimeContext } from "../library/NGFieldExtensions";

export function containerWithPaper(local: any, config: any, renderControlWithStyle, context: RuntimeContext) {
  return (
    <>
      {isVisible(local.Visible.value, config, context) &&
        (isNil(config.Paper) ? (
          renderControlWithStyle(true)
        ) : (
          <Paper
            data-testid={getTestId(config)}
            data-type={config.__typename}
            elevation={config.Paper.Elevation ?? 3}
            sx={getsxObject(config.Paper.Style)}
            variant={(config.Paper.Variant ?? "elevation") as any}
          >
            {renderControlWithStyle(false)}
          </Paper>
        ))}
    </>
  );
}
