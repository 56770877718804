import React from "react";
import FormatLineSpacingSharpIcon from "@mui/icons-material/FormatLineSpacingSharp";
import ExpandSharpIcon from "@mui/icons-material/ExpandSharp";
import { Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import NGTextInput from "./NGTextInput";
import NGAutocomplete from "./NGAutocomplete";
import { getTestId } from "../../../library/utils";

const fontStyles = [
  {
    label: "Font Family",
    property: "fontFamily",
    options: [
      {
        label: "Inter",
      },
      {
        label: "Roboto",
      },
      {
        label: "Roboto Mono",
      },
      {
        label: "Crimson Text",
      },
    ],
    type: "textFieldMenu",
    styledValue: true,
  },
  {
    label: "Font Weight",
    property: "fontWeight",
    sx: { width: "48%" },
    options: [
      {
        label: "lighter",
      },
      {
        label: "normal",
      },
      {
        label: "bold",
      },
      {
        label: "bolder",
      },
    ],
    type: "textFieldMenu",
    styledValue: true,
    icon: KeyboardArrowDown,
  },
  {
    label: "Font Size",
    property: "fontSize",
    sx: { width: "48%" },
    options: [
      {
        label: "xx-small",
        value: "8px"
      },
      {
        label: "x-small",
        value: "10px"
      },
      {
        label: "smaller",
        value: "12px"
      },
      {
        label: "small",
        value: "14px"
      },
      {
        label: "medium",
        value: "16px"
      },
      {
        label: "large",
        value: "18px"
      },
      {
        label: "larger",
        value: "20px"
      },
      {
        label: "x-large",
        value: "24px"
      },
      {
        label: "xx-large",
        value: "28px"
      },
      {
        label: "xxx-large",
        value: "32px"
      },
    ],
    type: "textFieldMenu",
    icon: KeyboardArrowDown,
  },
  {
    label: "Line Height",
    property: "lineHeight",
    sx: { width: "48%" },
    options: [],
    type: "textField",
    icon: FormatLineSpacingSharpIcon,
  },
  {
    label: "Letter Spacing",
    property: "letterSpacing",
    sx: { width: "48%" },
    options: [],
    type: "textField",
    icon: ExpandSharpIcon,
    iconSx: { transform: "rotate(90deg)" },
  },
];

export type FontProp = (typeof fontStyles)[2];

export interface INGFontComponentProp {
  fontConfig: FontProp;
  listIndex: number;
  value: string;
}

export default function NGTextStylesEditor({ selected, config }) {
  const handlePropertyChange = (property: string, newValue: string | number) => {
    const c = { ...selected.value };
    if (!newValue) delete c[property];
    else c[property] = newValue
    selected.value = c;
  };

  const renderFields = (fontConfig: FontProp, listIndex: number, value: string) => {
    const componentsMap = {
      textFieldMenu: NGAutocomplete,
      textField: NGTextInput,
    };

    return componentsMap[fontConfig.type]({
      fontConfig,
      listIndex,
      value,
      handleChange: handlePropertyChange,
      dataTestId: getTestId(config, fontConfig.property)
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        rowGap: "1rem",
      }}
    >
      {fontStyles.map((prop, listIndex) => renderFields(prop, listIndex, selected.value[prop?.property]))}
    </Box>
  );
}
