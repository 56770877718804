import _ from "lodash";
import { INGFreeFormLayoutProps } from "../library/NGFieldExtensions";
import { getStyleObject, getsxObject, keyByRec, getTestId } from "../library/utils";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { useSignal } from "@preact/signals-react";
import { isNil } from "lodash-es";
import { Box } from "@mui/material";
import { renderAsLayoutItem, renderAsLayoutItemCollection } from "./LayoutHelpers";

export default function NGFreeFormLayout({ config, context, layoutItem }: INGFreeFormLayoutProps) {
  const globalItemMap = keyByRec((layoutItem as any).Items, "Items", "Id", {});

  const handlers = setupHandlers(config, context);

  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  function itemWithLayoutStyle(item: any) {
    if (isNil(config.ItemStyles)) return;

    if (isNil(config.ItemStyles[item.Id])) return;

    if (item.__typename.includes("Container")) {
      item.Layout.Style = getStyleObject(config.ItemStyles[item.Id], item.Layout.Style);
    } else {
      item.Style = getStyleObject(config.ItemStyles[item.Id], item.Style);
    }
  }

  const sxPropsFromLayout = {
    position: config.Position ?? "relative",
    top: config.Top,
    right: config.Right,
    bottom: config.Bottom,
    left: config.Left,
    zIndex: config.ZIndex,
  };

  const result = (
    <Box data-testid={getTestId(config)} sx={getsxObject(local.Style.value, sxPropsFromLayout as any)}>
      {_.isEmpty(config.Items) && renderAsLayoutItem(layoutItem, itemWithLayoutStyle, context, handlers)}
      {!_.isEmpty(config.Items) &&
        renderAsLayoutItemCollection(config, globalItemMap, layoutItem, itemWithLayoutStyle, context)}
    </Box>
  );

  return result;
}
