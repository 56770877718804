import { Button, Dialog as MUIDialog, DialogActions, DialogTitle } from "@mui/material";
import { Signal } from "@preact/signals-react";

interface ActionDialogProps {
  open: Signal<boolean>;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ActionDialog({ open, title, onConfirm, onCancel }: ActionDialogProps) {
  return (
    <>
      <MUIDialog
        data-testid="action-dialog"
        open={open.value}
        onClose={() => {
          open.value = false;
        }}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" data-testid="action-dialog-title">
          {title}
        </DialogTitle>

        <DialogActions>
          <Button onClick={onConfirm} autoFocus data-testid="action-dialog-confirm-button">
            Delete
          </Button>
          <Button onClick={onCancel} autoFocus data-testid="action-dialog-cancel-button">
            Cancel
          </Button>
        </DialogActions>
      </MUIDialog>
    </>
  );
}
