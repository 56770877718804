import { TableCell } from "@mui/material";
import NGLayoutItem from "../generators/NGLayoutItem";
import { INGTableCellProps } from "../library/NGFieldExtensions";
import { getTableItemsProps } from "../library/utils";
import { useContext } from "react";
import { TableContext } from "./NGTableContext";

export default function NGTableCell({
  item,
  context,
}: //   tableLayout,
//   inDesignMode,

INGTableCellProps) {
  //const currentContext = updateItemContext(context, item);
  const tableContext = useContext(TableContext);

  const tableConfig = tableContext.TableConfig;

  const props = getTableItemsProps((item as any).Id, tableConfig.ContentItemsProps as any, {
    sx: { verticalAlign: "top", ...((tableConfig as any).CellStyle ?? {}) },
  });

  return (
    <TableCell {...props}>
      <NGLayoutItem config={item} context={context} />
    </TableCell>
  );
}
