import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material";
import NGLayoutItem from "../generators/NGLayoutItem";
import { INGTableRowProps } from "../library/NGFieldExtensions";
import { updateItemContext } from "../library/dataService";

export default function NGTableCollapsableRow({ item, context }: INGTableRowProps) {
  const currentContext = updateItemContext(context, item);

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              <NGLayoutItem config={item} context={currentContext} />
            </Typography>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
