import { useCallback } from "react";
import { INGActionEditorProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { Autocomplete, IconButton, Stack, TextField, Typography, Checkbox, Button } from "@mui/material";
import { Delete, Add, Code } from "@mui/icons-material";
import ActionNode from "./NGActionNode";
import { batch, useComputed, useSignal } from "@preact/signals-react";
import { defaultTriggers, propagateTriggers, defaultXPosition, actions } from "../../library/actionEditor";
import { generateGuid } from "../../library/utils";
import React from "react";
import ActionDialog from "./ActionDialog";
import NGCodeEditor from "../NGCodeEditor/NGCodeEditor";
import CustomSwitch from "../NGCustomSwitch/NGCustomSwitch";
import CustomEdge from "./NGActionEdge";
import NGUndoRedoToolbar from "../NGUndoRedoToolbar/NGUndoRedoToolbar";
import { setPageCommand } from "../../sampleData/editor-gui/editor-gui";
import debounce from "lodash-es/debounce";

const nodeTypes = {
  default: ActionNode,
};

const edgeTypes = {
  default: CustomEdge,
  buttonedge: CustomEdge,
};

let currentPosition: object | null = null;

const startNode = {
  id: "startNode",
  position: { x: defaultXPosition - 200, y: 0 },
  data: {},
  style: { width: 60, height: 40, border: "1px solid #6495ed" },
};

const defaultNodeStyle = { width: "300px" };

const NGActionEditor = ({ config, context }: INGActionEditorProps) => {
  const local = setupLocalState(config, { Value: useSignal(config.Value ?? { Actions: [] }) }, context);
  const handlers = setupHandlers(config, context);
  const deleteActionsDialogOpen = useSignal(false);
  const viewCode = useSignal(false);

  let triggers = local.Value.value.Actions;

  const getInitialTrigger = () => {
    const initialTrigger = triggers?.[0] ?? { Trigger: defaultTriggers[0], CommandSet: { Commands: [] } };

    if (local.Value.value.Trigger) {
      return triggers.find((t) => t.Trigger === local.Value.value.Trigger) ?? initialTrigger;
    }

    return initialTrigger;
  };

  const selectedTrigger = useSignal(getInitialTrigger());
  const getCurrentTriggerIndex = () => triggers?.findIndex((t) => t.Trigger === selectedTrigger.value.Trigger);
  const actionNodes = useSignal(selectedTrigger.value.CommandSet?.Commands ?? []);

  if (!selectedTrigger.value.CommandSet?.Commands) {
    selectedTrigger.value = { ...selectedTrigger.value, CommandSet: { Commands: [] } };
  }

  const onDeleteActionNode = (id, nodes) => {
    const updatedNodes = actionNodes.value.filter((an) => an.Id !== id);

    updatedNodes.forEach((node) => {
      if (node.NextCommandIdOnFailure === id) delete node.NextCommandIdOnFailure;
      if (node.NextCommandIdOnSuccess === id) delete node.NextCommandIdOnSuccess;
    });

    actionNodes.value = updatedNodes;
    setNodes([...nodes.filter((node) => node.id !== id)]);

    const deleteTrigger = updatedNodes.length === 0;
    saveCurrentTrigger(deleteTrigger);
  };

  const handleSave = debounce(
    (handlerName?, action?, event?, data?, context?) => {
      (handlers as any).onSave({}, { Actions: triggers });
    },
    3000,
    { leading: true }
  );

  const getNodesAndEdges = useComputed(() => {
    let xPosition = defaultXPosition;

    const newNodes: any[] = [];
    const newEdges: Edge<any>[] = [];

    actionNodes.value.forEach((action, index, arr) => {
      newNodes.push({
        id: action.Id,
        data: {
          action: action.Instruction,
          index,
          context,
          onDeleteActionNode,
          currentTriggerIndex: getCurrentTriggerIndex(),
          onSave: handleSave,
        },
        position: { x: action.Editor?.Position.x ?? xPosition, y: action.Editor?.Position.y ?? 20 },
        style: defaultNodeStyle,
      });

      if (!action.Editor?.Position.X) xPosition += 320;

      if (action.NextCommandIdOnSuccess) {
        const nextCommand = arr.find(({ Id }) => Id === action.NextCommandIdOnSuccess);
        if (nextCommand)
          newEdges.push({
            id: `${action.Id}-${nextCommand.Id}`,
            source: action.Id,
            target: nextCommand.Id,
            sourceHandle: "successHandle",
          });
      }

      if (action.NextCommandIdOnFailure) {
        const nextCommand = arr.find(({ Id }) => Id === action.NextCommandIdOnFailure);
        if (nextCommand)
          newEdges.push({
            id: `${action.Id}-${nextCommand.Id}`,
            source: action.Id,
            target: nextCommand.Id,
            sourceHandle: "failureHandle",
          });
      }
    });

    const startAction = actionNodes.value.find((node) => node.Id === selectedTrigger.value.CommandSet?.FirstCommandId);

    if (startAction) {
      newEdges.push({
        id: generateGuid(),
        source: startNode.id,
        target: startAction.Id,
        sourceHandle: "startHandle",
      });
    }

    return { newNodes, newEdges };
  });

  const { newNodes, newEdges } = getNodesAndEdges.value;

  const [nodes, setNodes, onNodesChange] = useNodesState([startNode, ...newNodes]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(newEdges);

  const setActionNodes = (trigger: string | null, refreshActions = true) => {
    if (refreshActions) {
      actionNodes.value =
        (triggers.find((trigger) => trigger.Trigger === trigger) ?? selectedTrigger.value)?.CommandSet.Commands ?? [];
    }

    const { newNodes, newEdges } = getNodesAndEdges.value;

    setNodes([startNode, ...newNodes]);
    setEdges(newEdges);
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const saveCurrentTrigger = (deleteCurrentTrigger = false) => {
    const triggersToUpdate = triggers ?? [];
    const triggerIndexToUpdate = triggersToUpdate.findIndex((t) => t.Trigger === selectedTrigger.value.Trigger);

    if (triggerIndexToUpdate < 0) {
      selectedTrigger.value.CommandSet = {
        Commands: actionNodes.value,
        Id: generateGuid(),
        FirstCommandId: actionNodes.value[0]?.Id,
      };
      triggersToUpdate.push({ ...selectedTrigger.value });
    } else if (deleteCurrentTrigger) {
      triggersToUpdate.splice(triggerIndexToUpdate, 1);
    } else {
      triggersToUpdate[triggerIndexToUpdate] = {
        ...selectedTrigger.value,
        CommandSet: { ...selectedTrigger.value.CommandSet, Commands: [...actionNodes.value] },
      };
    }
    local.Value.value = { ...local.Value.value, Actions: [...triggersToUpdate] };
    handleSave();
  };

  const handleAddNewNode = () => {
    const newNodeId = generateGuid();
    const defaultInitAction = actions[0];
    const triggerIndex = getCurrentTriggerIndex();

    const startPosition = { x: 0, y: 0 };

    setNodes([
      ...nodes,
      {
        id: newNodeId,
        position: { ...startPosition },
        data: {
          action: { Name: defaultInitAction },
          index: actionNodes.value.length,
          context,
          onDeleteActionNode,
          currentTriggerIndex: triggerIndex >= 0 ? triggerIndex : 0,
          onSave: handleSave,
        },
        style: defaultNodeStyle,
      },
    ]);
    actionNodes.value = [
      ...actionNodes.value,
      { Id: newNodeId, Instruction: { Name: defaultInitAction }, Parameters: [], Position: { ...startPosition } },
    ];

    saveCurrentTrigger();
  };

  const handleChangeTrigger = (e: React.SyntheticEvent<Element, Event>, value) => {
    const newValue = value?.label ?? value ?? "";
    const triggerIndex = triggers.findIndex((trigger) => trigger.Trigger === newValue);
    selectedTrigger.value = triggers[triggerIndex] ?? {
      Trigger: newValue,
      CommandSet: { Commands: [] },
    };

    if (triggerIndex === -1) {
      triggers = [...triggers, selectedTrigger.value];
    }

    setActionNodes(newValue);
    saveCurrentTrigger();
  };

  const handleClearAllNodes = () => {
    deleteActionsDialogOpen.value = true;
  };

  const onConfirmClearAllNodes = () => {
    setNodes([startNode]);
    setEdges([]);
    actionNodes.value = [];

    saveCurrentTrigger(true);

    deleteActionsDialogOpen.value = false;
  };

  const handleOnConnectNodes = (e) => {
    const updatedNodes = [...actionNodes.value];
    const sourceNode = updatedNodes.find((an) => an.Id === e.source);

    if (e.sourceHandle === "startHandle") {
      const startEdgeIndex = edges.findIndex((edge) => edge.sourceHandle === "startHandle");
      if (startEdgeIndex >= 0) {
        edges.splice(startEdgeIndex, 1);
        setEdges(edges);
      }
      selectedTrigger.value = {
        ...selectedTrigger.value,
        CommandSet: { ...selectedTrigger.value.CommandSet, FirstCommandId: e.target },
      };
    } else if (e.sourceHandle === "failureHandle") {
      sourceNode.NextCommandIdOnFailure = e.target;
    } else {
      sourceNode.NextCommandIdOnSuccess = e.target;
    }

    actionNodes.value = updatedNodes;

    onConnect(e);
    saveCurrentTrigger();
  };

  const handleChangePropagateToParent = (e, value) => {
    if (value) {
      selectedTrigger.value = {
        ...selectedTrigger.value,
        PropagateToParent: { Trigger: "onViewComplete", PropagateOnFailure: false },
      };
    } else {
      delete selectedTrigger.value.PropagateToParent;
    }

    saveCurrentTrigger();
  };

  const handleChangePropagateOnFailure = (e, value) => {
    selectedTrigger.value = {
      ...selectedTrigger.value,
      PropagateToParent: { ...selectedTrigger.value.PropagateToParent, PropagateOnFailure: value },
    };

    saveCurrentTrigger();
  };

  const handleChangePropagateToParentTrigger = (e, value) => {
    selectedTrigger.value = {
      ...selectedTrigger.value,
      PropagateToParent: { ...selectedTrigger.value.PropagateToParent, Trigger: value.label },
    };

    saveCurrentTrigger();
  };

  const handleCodeEditorChange = (e, newValue) => {
    batch(() => {
      local.Value.value = newValue;
      actionNodes.value =
        newValue.Actions?.find((action) => action.Trigger === selectedTrigger.value.Trigger)?.CommandSet.Commands ?? [];
    });
    setActionNodes(null, false);
    handleSave();
  };

  const handleOnNodesChange = (e) => {
    if (e[0]?.dragging) {
      currentPosition = e[0].position;
    } else if (e[0]?.type === "position" && currentPosition) {
      const actionNode = actionNodes.value.find((node) => node.Id === e[0].id);
      actionNode.Editor = { Position: { ...currentPosition } };
      currentPosition = null;
      saveCurrentTrigger();
    }

    onNodesChange(e);
  };

  const handleOnEdgesChange = (e) => {
    const edgeModified = e[0];
    const edge = edges.find((e) => e.id === edgeModified.id);
    if (edgeModified.type === "remove" && edge) {
      const sourceAction = actionNodes.value.find((an) => an.Id === edge.source);
      if (edge.sourceHandle === "successHandle" && edge.target === sourceAction?.NextCommandIdOnSuccess) {
        delete sourceAction.NextCommandIdOnSuccess;
      }

      if (edge.sourceHandle === "failureHandle" && edge.target === sourceAction?.NextCommandIdOnFailure) {
        delete sourceAction.NextCommandIdOnFailure;
      }
    }

    onEdgesChange(e);
    saveCurrentTrigger();
  };

  return (
    <Stack direction="column" display="flex" width="100%" height="100%" gap="1rem" id="actionEditorContainer">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="2rem">
          <Stack direction="row" alignItems="center" gap="0.5rem">
            <Typography>Trigger:</Typography>
            <Autocomplete
              id="action-type-selector"
              data-testid="action-type-selector"
              size="small"
              disableClearable
              freeSolo
              options={defaultTriggers.map((trigger) => ({ label: trigger }))}
              sx={{ width: 300, ".MuiFormControl-root": { marginTop: 0 } }}
              renderInput={(params) => <TextField {...params} />}
              value={{ label: selectedTrigger.value.Trigger ?? "" }}
              onChange={handleChangeTrigger}
            />
            <IconButton color="inherit" size="small" onClick={handleAddNewNode}>
              <Add />
            </IconButton>
            <IconButton disabled={!nodes.length} color="inherit" size="small" onClick={handleClearAllNodes}>
              <Delete />
            </IconButton>
          </Stack>
          {/* <NGUndoRedoToolbar
            config={{
              Id: "actions-editor-undo-redo-toolbar",
              Bindings: { TrackedObject: "{ SelectedItem: Global.SelectedItem }" },
              Actions: [
                {
                  Trigger: "onChange",
                  CommandSet: {
                    FirstCommandId: "1",
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "SetState",
                        },
                        Parameters: [
                          {
                            Name: "Bindings",
                            Value: {
                              "Global.Page.Items[0]": "Global.SelectedItem",
                              "Global.PropertiesEditorData": "findMeta(Global.Page, Global.PropertiesEditorData.Id)",
                            },
                          },
                        ],
                        NextCommandIdOnSuccess: "2",
                      },
                      setPageCommand(true),
                    ],
                  },
                },
              ],
            }}
            context={context}
          /> */}
          <Stack direction="row" gap="0.5rem" alignItems="center">
            <Typography>Propagate to Parent</Typography>
            <Checkbox
              onChange={handleChangePropagateToParent}
              data-testid="propagateToParentCheckbox"
              checked={!!selectedTrigger.value.PropagateToParent ?? false}
              disabled={!selectedTrigger.value.Trigger}
            />
            <Typography>Trigger</Typography>
            <Autocomplete
              id="action-type-selector"
              data-testid="action-type-selector"
              size="small"
              disableClearable
              freeSolo
              disabled={!selectedTrigger.value.PropagateToParent}
              options={propagateTriggers.map((trigger) => ({ label: trigger }))}
              sx={{ width: 300, ".MuiFormControl-root": { marginTop: 0 } }}
              renderInput={(params) => <TextField {...params} />}
              value={{ label: selectedTrigger.value.PropagateToParent?.Trigger ?? "" }}
              onChange={handleChangePropagateToParentTrigger}
            />
          </Stack>
          <Typography>Propagate on Failure</Typography>
          <Checkbox
            onChange={handleChangePropagateOnFailure}
            data-testid="propagateToOnFailureCheckbox"
            checked={!!selectedTrigger.value.PropagateToParent?.PropagateOnFailure ?? false}
            disabled={!selectedTrigger.value.PropagateToParent}
          />
          <CustomSwitch
            checked={viewCode.value}
            onChange={(e, value) => {
              viewCode.value = value;
            }}
            icon={<Code fontSize="small" />}
            checkedIcon={<Code fontSize="small" />}
            sx={{
              marginBottom: "3px",
            }}
          />
        </Stack>
      </Stack>
      {viewCode.value ? (
        <NGCodeEditor
          config={{
            ...config,
            Height: "100%",
            Width: "100%",
            OnChange: handleCodeEditorChange,
          }}
          context={context}
        />
      ) : (
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          edges={edges}
          onNodesChange={handleOnNodesChange}
          onEdgesChange={handleOnEdgesChange}
          onConnect={handleOnConnectNodes}
          proOptions={{ hideAttribution: true }}
          deleteKeyCode={null}
        >
          <Controls />
          <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        </ReactFlow>
      )}

      <ActionDialog
        open={deleteActionsDialogOpen}
        onCancel={() => {
          deleteActionsDialogOpen.value = false;
        }}
        onConfirm={onConfirmClearAllNodes}
        title="Confirm delete all actions"
      />
    </Stack>
  );
};

export default NGActionEditor;
