import { Box } from "@mui/material";
import "./NGShareDialog.css";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { signal, useSignal } from "@preact/signals-react";
import { INGShareDialogProps } from "../../library/NGFieldExtensions";
import { ShareDialogData } from "../../../resolvers-types";
import { generateUID } from "../../library/utils";
import NGButton from "../NGButton/NGButton";

const tag = "NGShareDialog";

export default function NGShareDialog({ config, context }: INGShareDialogProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Enabled: useSignal(config.Enabled ?? true),
      Data: useSignal(config.Data ?? {}),
    },
    context
  );

  const shareButton = {
    Id: generateUID(),
    ContextId: config.ContextId,
    //Label: "Share",
    StartIcon: { IconName: "Share" },
    Classes: ["grey-rounded-button"],
    Actions: [
      {
        Trigger: "onClick",
        CommandSet: {
          Id: "0fb61b5f-6bb1-4736-9d75-e2c796231be2",
          FirstCommandId: "1",
          ExecuteCommandsInParallel: false,
          Commands: [
            //SharUersList
            {
              Id: "1",
              Instruction: {
                Name: "CallService",
              },
              Parameters: [
                { Name: "ServiceName", Value: "ShareUserList" },
                // { Name: "Form", Value: "AddAssetForm" },
              ],
              NextCommandIdOnSuccess: "2",
              NextCommandIdOnFailure: "3",
            },
            {
              Id: "2",
              Instruction: {
                Name: "SetState",
              },
              Parameters: [
                {
                  Name: "Bindings",
                  Value: {
                    "State.NGFeedItem": "Event.Item",
                  },
                },
              ],
              NextCommandIdOnSuccess: "3",
              NextCommandIdOnFailure: "4",
            },
            {
              Id: "3",
              Instruction: {
                Id: "1",
                Name: "OpenModalPopup",
              },
              Parameters: [
                //{ Name: "Title", Value: "Share with your community" },
                { Name: "ModalPopupId", Value: "SharePopup" },
              ],
              //NextCommand: "CommandResult=='Save'?'3':null",
            },
          ],
        },
      },
    ],
  };

  shareButton.getData = (e: any) => local.Data.value;

  const handlers = setupHandlers(shareButton, context);

  return (
    <>
      {local.Visible.value && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NGButton config={shareButton} context={context} />
        </Box>
      )}
    </>
  );
}
