import { useSignal, signal, useSignalEffect, batch } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { log } from "../../library/logger";
// import { INGFinAppProps } from "../../library/NGFieldExtensions";
import { getClassName, getTestId, getsxObject } from "../../library/utils";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useFinApp } from "../../library/finapp";
// import loadFinAppLibrary from "../../library/finapp";

const tag = "NGFinApp";

export default function NGFinApp({ config, context }) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
      Type: useSignal(config.Type ?? "news"),
      Symbol: useSignal(config.Symbol),
      Exchange: useSignal(config.Exchange),
    },
    context
  );

  const xid = useSignal(null);

  const handlers = setupHandlers(config, context);

  const onSearch = (data) => {
    batch(() => {
      local.Symbol.value = data.symbol;
      local.Exchange.value = data.exchange.code;
    });
  };

  const finctx = useFinApp(onSearch);
  const ComponentTag = `fwc-${local.Type.value}` || "div";

  return (
    <>
      {local.Visible.value && (
        <div
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
        >
          <ComponentTag
            className="fwc-app"
            // xid={xid.value}
            symbol={local.Symbol.value}
            exchange={local.Exchange.value}
          ></ComponentTag>
        </div>
      )}
    </>
  );
}
