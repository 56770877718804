import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { INGDialogProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState, updateItemContext } from "../../library/dataService";
import { batch, useSignal } from "@preact/signals-react";
import { isNil } from "lodash";
import { GetLocalString } from "../../library/localization";
import CloseIcon from "@mui/icons-material/Close";
import { getTestId } from "../../library/utils";

export default function NGDialog({ config, context }: INGDialogProps) {
  const currentContext = updateItemContext(context, config);

  const local = setupLocalState(
    config,
    {
      Open: useSignal(config.Open ?? false),
      ContentText: useSignal(config.ContentText),
      Title: useSignal(config.Title),
      ShowOkButton: useSignal(config.ShowOkButton),
      ShowCancelButton: useSignal(config.ShowCancelButton),
      ShowCloseButton: useSignal(config.ShowCloseButton),
      ButtonClicked: useSignal(config.ButtonClicked ?? "None"),
      OnClose: null,
    },
    currentContext
  );

  const handlers = setupHandlers(config, currentContext);

  const closeModal = () => {
    // console.log("closing modal");
    local.Open.value = false; // TODO: do we always have to modify the whole object??

    // console.log("local3", local.value);
    if (!isNil(local.OnClose)) {
      // console.log("onClose", local.OnClose);
      local.OnClose();
    }
  };

  const onOk = () => {
    batch(() => {
      local.ButtonClicked.value = "Ok";
      closeModal();
    });
  };

  const onClose = () => {
    batch(() => {
      local.ButtonClicked.value = "Close";
      closeModal();
    });
  };

  const onCancel = () => {
    batch(() => {
      local.ButtonClicked.value = "Cancel";
      closeModal();
    });
  };

  return (
    <>
      <MUIDialog
        data-testid={getTestId(config)}
        open={local.Open.value}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          data-testid={getTestId(config, "close")}
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title" data-testid={getTestId(config, "dialogTitle")}>
          {local.Title.value}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" data-testid={getTestId(config, "DialogContentText")}>
            {local.ContentText.value}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {local.ShowOkButton.value && (
            <Button onClick={onOk} autoFocus data-testid={getTestId(config, "Ok")}>
              {GetLocalString("Ok")}
            </Button>
          )}
          {local.ShowCancelButton.value && (
            <Button onClick={onCancel} autoFocus data-testid={getTestId(config, "Cancel")}>
              {GetLocalString("Cancel")}
            </Button>
          )}
        </DialogActions>
      </MUIDialog>
    </>
  );
}
