import { useSignalEffect } from "@preact/signals-react";
import { log } from "./logger";

const tag = "finapp";
let loaded = false;
export function useFinApp(onSearch) {
  useSignalEffect(() => {
    if (loaded) return;

    (async () => {
      try {
        const m = await import("https://fwc-ui.markitqa.com/fwc-load-app-fwc-all-apps.js");
        const finctx = await m.registerFinApps({
          tokenProvider: async (renewToken) => {
            // console.log("fin token", renewToken);
            // if (renewToken) return await requestToken();
            // TODO: Replace with real auth
            return "hw28L2BdmT7pjUP2ipstHP7uKfLv"; // Expires Sept 25th 2025
          },
        });
        log.info(tag, "Fin App Context", finctx);

        finctx.addEventListener("search-selection", (e) => {
          const data = e?.detail?.data;
          if (!data) return;
          if (onSearch) onSearch(data);
        });
        loaded = true;
      } catch (ex) {
        log.error(tag, "Failed to load fin apps", ex);
      }
    })();
  });
}
